import logger from '../utils/logger';

const LOG_TAG = 'updateParkSlot';
export default {
  //
  // returns slotInfo and sensorInfo if successful
  //
  execute(groupId, siteId, slotId, slotInfo, onCompleteCb) {

    const onResponse = response => {
      const data = response.data;
      logger.d(LOG_TAG, 'onResponse', data);
      if (onCompleteCb) {
        onCompleteCb(true, data);
      }
    };
    const onError = error => {
      const data = error.data;
      logger.d(LOG_TAG, 'onError', data);
      if (onCompleteCb) {
        onCompleteCb(false, data);
      }
    };
    if (slotId) {
      logger.d(LOG_TAG, 'execute', 'put', groupId, siteId, slotId, slotInfo);
      window.webapi.Group.putParkSlotInfo(
        groupId, siteId, slotId,
        slotInfo,
        onResponse,
        onError);
    } else {
      logger.d(LOG_TAG, 'execute', 'post', groupId, siteId, slotInfo);
      window.webapi.Group.postParkSiteSlot(
        groupId, siteId,
        slotInfo,
        onResponse,
        onError);
    }
  },
};
