import React, {Component} from 'react';
import AnimPage from '../../../components/anim/animPage';
import AnimComponent from '../../../components/anim/animComponent';
import connect from 'react-redux/es/connect/connect';
import CardSearchUserByEmail from '../../../components/cardSearchUserByEmail';
import {setLoading, setNotification, setUser} from '../../../store/actions';
import logger from '../../../utils/logger';
import Row2Col from '../../../components/row2Col';
import localStorageHelper from '../../../utils/localStorageHelper';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import StateInfo from "../../../store/states";
import dataHelper from '../../../utils/dataHelper';
import RowEmpty from "../../../components/rowEmpty";

const LOG_TAG = 'UserProfile';

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: 0,
      firstName: '',
      lastName: '',
      email: '',
      isProfileChanged: false,

      carPlateSelected: 'select',
      carPlates: [],
      newNumberPlate: '',

      userInfo: null,
    };
  }
  componentDidMount() {
    logger.d(LOG_TAG, 'componentDidMount');
  }
  componentDidUpdate(oldProps) {
    const newProps = this.props;
    if (oldProps.userInfo !== newProps.userInfo) {
      logger.d(LOG_TAG, 'componentDidUpdate', 'userInfo update');
      this.resetState(newProps.userInfo);
    }
  }
  resetState(userInfo) {
    logger.d(LOG_TAG, 'resetState', userInfo);
    const newUserInfo = {
      userId: userInfo ? userInfo.user_id : 0,
      firstName: userInfo ? userInfo.first_name : '',
      lastName: userInfo ? userInfo.last_name : '',
      email: userInfo ? userInfo.email : '',
      isProfileChanged: false,

      carPlateSelected: 'select',
      carPlates: userInfo && userInfo.car_plates ? userInfo.car_plates : [],
      newNumberPlate: '',

      userInfo: userInfo,
    };
    this.setState(newUserInfo)
  };

  render() {
    return (
      <AnimPage>
        <div className="container-fluid p-0 mt-3">
          <div className="row app-main__content">
            {/* left section*/}
            <div className="col-md-6 app-main__content__card">
              {/* user search card*/}
              <CardSearchUserByEmail
                userInfo={this.state.userInfo}
                onResult={this.onSearchUserResult}/>
              {/* user profile card*/}
              {
                this.state.userId !== 0 && (
                  <AnimComponent>
                    <form onSubmit={this.onClickUpdate}>
                      <div className="card mt-3">
                        <div className="card-body">

                          {/* name */}
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="">First Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter first name"
                                  value={this.state.firstName}
                                  onChange={this.onChangeFirstName}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="">Last Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter last name"
                                  value={this.state.lastName}
                                  onChange={this.onChangeLastName}
                                />
                              </div>
                            </div>
                          </div>
                          {/* email */}
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <label htmlFor="">Email Address</label>
                                <input
                                  type="email"
                                  className="form-control"
                                  placeholder="Enter email address"
                                  value={this.state.email}
                                  onChange={this.onChangeEmail}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <button
                                  className={`
                            ${this.validateProfile() ?'btn':'btn__disabled'}
                            btn-default float-right`}>
                                  Update
                                </button>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                    </form>
                  </AnimComponent>
                )
              }
            </div>
            {/* right section*/}
            {/* car plates*/}
            <div className="col-md-6 app-main__content__card">
              {
                this.state.userId !== 0 && (
                  <div className="card">
                    <div className="card-body">
                      {/* num plate */}
                      <div className="row">
                        <div className="col-md-12"><h5>Car Registration</h5></div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-12">
                              <label htmlFor="">Add new car registration</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter car registration"
                                value={this.state.newNumberPlate}
                                maxLength={6}
                                onChange={this.onNewNumberPlate}
                              />
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-md-12">
                              <button className="btn btn-invert app__btn--small float-right" onClick={this.onClickAddNumberPlate}>
                                <FontAwesomeIcon icon="plus"></FontAwesomeIcon>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <label htmlFor="">Existing car registrations</label>
                          <div className="list-group">
                            {
                              this.state.carPlates.length > 0 ?
                                this.state.carPlates.map((result, index) => (
                                  <Row2Col
                                    key={index}
                                    id={index}
                                    index={index+1}
                                    value1={result.toUpperCase()}
                                    value1Style={'text-style-car-plate'}>
                                  </Row2Col>
                                ))
                                :
                                <RowEmpty value={"No Car Registrations found."}></RowEmpty>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </AnimPage>
    );
  }
  onSearchUserResult = value => {
    logger.d(LOG_TAG, 'onSearchResult', value);
    this.props.setUser(value);
    this.resetState(value);
  };
  onChangeFirstName = e => {
    this.setState({
      firstName: e.target.value,
      isProfileChanged: true,
    });
  };
  onChangeLastName = e => {
    this.setState({
      lastName: e.target.value,
      isProfileChanged: true,
    });
  };
  onChangeEmail = e => {
    this.setState({
      email: e.target.value,
      isProfileChanged: true,
    });
  };
  validateProfile() {
    if (this.state.isProfileChanged) {
      if (this.state.firstName && this.state.lastName && this.state.email) {
        return true;
      }
    }
    return false;
  }
  onClickUpdate = e => {
    logger.d(LOG_TAG, 'onClickUpdate', e);
    e.preventDefault();

    window.confirm(`Are you sure you wish to apply these profile changes?`) &&
    this.onUpdateProfile()
  };
  onUpdateProfile = () => {
    const validated = this.validateProfile();
    logger.d(LOG_TAG, 'onUpdateProfile', validated);
    if (validated) {
      const userInfo = {
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        email: this.state.email,
      };
      this.props.setLoading(StateInfo.LoadingInfo.format(true, 'Updating user profile...'));
      window.webapi.User.put(
        this.state.userId,
        userInfo,
        (response) => {
          const data = response.data;
          logger.d(LOG_TAG, 'onUpdateProfile', 'onResponse', data);
          setTimeout(() => {
            this.props.setLoading(StateInfo.LoadingInfo.format(false, ''));
            this.props.setUser(data);
            this.resetState(data);
          }, 800);
        },
        (error) => {
          const data = error.data;
          const notify = dataHelper.formatError('Profile', data);
          setTimeout(() => {
            this.props.setLoading(StateInfo.LoadingInfo.format(false, ''));
            this.props.setNotification(
              StateInfo.NotificationInfo.format('danger', notify.title, notify.message)
            );
          }, 800);
        }
      )
    }
  };
  onNewNumberPlate = e => {
    const value = e.target.value;
    logger.d(LOG_TAG, 'onNewNumberPlate', value);
    this.setState({
      newNumberPlate: value.toUpperCase(),
    });
  };
  onSelectCarPlate = value => {
    const selected = value.target.value;
    logger.d(LOG_TAG, 'onSelectCarPlate', selected);
    this.setState({
      carPlateSelected: selected,
    });
  };
  onClickAddNumberPlate = () => {
    logger.d(LOG_TAG, 'onClickAddNumberPlate', this.state.newNumberPlate);
    const carPlates = [];
    this.state.carPlates.forEach((plate) => {
      carPlates.push(plate.toLowerCase());
    });
    if (this.state.newNumberPlate) {
      carPlates.push(this.state.newNumberPlate.toLowerCase());
      logger.d(LOG_TAG, 'onClickAddNumberPlate', 'putCarPlate', carPlates);
      this.props.setLoading(StateInfo.LoadingInfo.format(true, 'Add new car registration...'));
      window.webapi.User.putCarPlate(
        this.state.userId,
        carPlates,
        (response) => {
          const data = response.data;
          logger.d(LOG_TAG, 'onClickAddNumberPlate', 'onResponse', data);
          setTimeout(() => {
            this.props.setLoading(StateInfo.LoadingInfo.format(false, ''));
            this.props.setUser(data);
            this.resetState(data);

          }, 800);
        },
        (error) => {
          const data = error.data;
          const notify = dataHelper.formatError('Plate', data);
          setTimeout(() => {
            this.props.setLoading(StateInfo.LoadingInfo.format(false, ''));
            this.props.setNotification(
              StateInfo.NotificationInfo.format('danger', notify.title, notify.message)
            );
          }, 800);
        }
      )
    }
  }
}
// listens to state change
export default connect(
  null,
  {
    setUser,
    setLoading,
    setNotification,
  }
)(Profile);
