import React, {Component} from 'react';
import connect from 'react-redux/es/connect/connect';
import AnimComponent from '../../../components/anim/animComponent';
import {Elements, StripeProvider} from 'react-stripe-elements';
import {setLoading, setNotification, setNotifyStartParking} from '../../../store/actions';
import AddCardForm from '../../../components/stripe/addCardForm';
import logger from '../../../utils/logger';
import localStorageHelper from "../../../utils/localStorageHelper";
import RangeSlider from '../../../components/rangeSlider';
import StateInfo from '../../../store/states';
import syncUserParking from '../../../handlers/syncUserParking';
import firebaseService from '../../../handlers/firebaseService';
import dataHelper from '../../../utils/dataHelper';

const LOG_TAG = 'ParkingProfile';

class ParkingProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // strip
      stripe: null,

      // profile
      carPlateSelected: 'select',
      carPlates: [],
      paymentMethodSelected: 'select',
      paymentMethods: [],

      // park info
      siteInfo: null,
      slotInfo: null,
      availableTime: 0,

      durationDomain: [0, 1],
      durationStep: 1,
      parkingDuration: 0,
      defaultParkingDuration: 0,

      authUserInfo: null,
      userInfo: null,
      userParkInfo: null, // active parking session

      parkingCharge: 0,

      // default
      parkingRateOption: 'standard', // standard, fixed
      //
      fixedRatesAvailable: [],
    };
  }
  componentDidMount() {
    logger.d(LOG_TAG, 'componentDidMount');
    // Stripe
    //
    if (window.Stripe) {
      this.setState({stripe: window.Stripe(process.env.REACT_APP_STRIPE_PAYMENT)});
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        // Create Stripe instance once Stripe.js loads
        this.setState({stripe: window.Stripe(process.env.REACT_APP_STRIPE_PAYMENT)});
      });
    }
    this.resetState();
  }
  componentDidUpdate(oldProps) {
    const newProps = this.props;
    if (oldProps.siteInfo !== newProps.siteInfo ||
      oldProps.slotInfo !== newProps.slotInfo ||
      oldProps.availableTime !== newProps.availableTime) {
      this.resetSiteInfo(this.state.userParkInfo);
    }
  }
  resetState() {
    logger.d(LOG_TAG, 'resetState');
    // reset user
    const authUserInfo = localStorageHelper.getAuthUserInfo();
    const userInfo = localStorageHelper.getUserInfo();
    if (userInfo) {
      syncUserParking.execute(
        userInfo,
        (result, value) => {
          logger.d(LOG_TAG, 'resetState', 'syncUserParking', result, value);
          if (result) {
            // demo:
            // value.code = 'earlybird'
            this.resetSiteInfo(value);
            this.resetUserProfile(authUserInfo, userInfo, value);
          } else {
            this.resetSiteInfo(null);
            this.resetUserProfile(authUserInfo, userInfo, null);
          }
        }
      );
    } else {
      this.resetSiteInfo(null);
      this.resetUserProfile(authUserInfo, null, null);
    }
    // reset site info

  };
  resetSiteInfo(parkingInfo) {
    const availableTime = this.props.availableTime;
    const fixedRatesAvailable = this.props.fixedRatesAvailable ? this.props.fixedRatesAvailable : [];
    const siteInfo = this.props.siteInfo;
    const slotInfo = this.props.slotInfo;
    let minDuration = 0;
    let maxDuration = 1;
    let durationStep = 1;
    // const estimated_duration = parkingInfo ? parkingInfo.estimated_duration : 0;
    const initialDuration = siteInfo ? siteInfo.initial_duration ? siteInfo.initial_duration : 0 : 0;
    let existingParkingDuration = 0;
    if (parkingInfo) {
      if (parkingInfo.code) {
        existingParkingDuration = initialDuration;
      } else {
        existingParkingDuration = parkingInfo.estimated_duration;
      }
    }

    if (siteInfo) {
      if (availableTime > siteInfo.max_duration) {
        minDuration = siteInfo.min_duration;
        maxDuration = siteInfo.max_duration;
        durationStep = siteInfo.min_duration;

        maxDuration -= existingParkingDuration;
      } else {
        if (availableTime > siteInfo.min_duration && availableTime < siteInfo.max_duration) {
          minDuration = siteInfo.min_duration;
          maxDuration = availableTime - (availableTime % minDuration);
          durationStep = siteInfo.min_duration;

          maxDuration -= existingParkingDuration;
        }
      }
    }
    logger.d(LOG_TAG, 'resetSiteInfo', parkingInfo, availableTime, minDuration, maxDuration, fixedRatesAvailable);

    const newState = {
      siteInfo: siteInfo,
      slotInfo: slotInfo,
      availableTime: availableTime,
      fixedRatesAvailable: fixedRatesAvailable
    };

    if (maxDuration === minDuration) {
      newState.defaultParkingDuration = minDuration;
      minDuration = 0;
    } else if (maxDuration < minDuration) {
      newState.defaultParkingDuration = 0;
      minDuration = 0;
      maxDuration = 1;
      durationStep = 1;
    }
    newState.parkingDuration = minDuration; // default parking duration is min duration
    newState.durationDomain = [minDuration, maxDuration];
    newState.durationStep = durationStep;

    newState.parkingCharge = dataHelper.calculateParkingCharge(
      existingParkingDuration,
      minDuration,
      minDuration,
      siteInfo ? siteInfo.base_price : 0,
      initialDuration,
      siteInfo ? siteInfo.initial_rate ? siteInfo.initial_rate : 0 : 0,
    )

    this.setState(newState);
  };
  hasNextAvailableFixedRateWindow() {
    return null!=this.getNextAvailableFixedRateWindow()
  }
  getNextAvailableFixedRateWindow() {
    const rates = this.state.fixedRatesAvailable ? this.state.fixedRatesAvailable : []
    logger.d(LOG_TAG, 'getNextAvailableFixedRateWindow', rates.length)
    let result;
    // will always have 1 rate option for the day
    if (rates.length > 0) {
      const rateWindows = rates[0].windows ? rates[0].windows : []
      logger.d(LOG_TAG, 'getNextAvailableFixedRateWindow', 'windows', rateWindows.length)
      if (rateWindows.length > 0) {
        if (this.state.userParkInfo) {
          const matchedWindows = rateWindows.filter((window) => window.code !== this.state.userParkInfo.code);
          logger.d(LOG_TAG, 'getNextAvailableFixedRateWindow', 'userParkInfo', this.state.userParkInfo, matchedWindows)
          if (matchedWindows.length > 0) {
            result = matchedWindows[0]
          }
        } else {
          result = rateWindows[0]
        }
      }
    }
    logger.d(LOG_TAG, 'getNextAvailableFixedRateWindow', 'result', result)
    return result
  }
  resetUserProfile(authUserInfo, userInfo, parkingInfo) {
    logger.d(LOG_TAG, 'resetUserProfile', userInfo, parkingInfo);
    let existingCarPlate = 'select';
    let existingPaymentId = null;
    if (parkingInfo) {
      if (userInfo.car_plates) {
        const index = userInfo.car_plates.findIndex((carPlate) => carPlate === parkingInfo.car_plate);
        if (index !== -1) {
          existingCarPlate = index;
        }
      }
      if (parkingInfo.paymeth_id) {
        existingPaymentId = parkingInfo.paymeth_id;
      }
    }

    const newState = {
      authUserInfo: authUserInfo,
      userInfo: userInfo,
      carPlates: userInfo && userInfo.car_plates ? userInfo.car_plates : [],
      carPlateSelected: existingCarPlate,
      userParkInfo: parkingInfo,
    };
    this.setState(newState);

    // payment
    this.resetUserPaymentMethods(authUserInfo, userInfo, existingPaymentId);
  };
  resetUserPaymentMethods(authUserInfo, userInfo, pId) {
    logger.d(LOG_TAG, 'resetUserPaymentMethods', pId);

    window.webapi.User.getPaymentMethods(
      userInfo.user_id,
      (response) => {
        const data = response.data;
        const paymentMethods = [];
        let paymentMethodSelected = 'select';
        let index = 0;
        data.forEach((method) => {
          paymentMethods.push({
            text: `xxxx-xxxx-xxxx-${method.cc_last4}`,
            value: method.paymeth_id,
          });
          if (pId === method.paymeth_id) {
            paymentMethodSelected = index.toString();
          }
          index += 1;
        });
        if (authUserInfo && (
          authUserInfo.email === 'oni@gmail.com' ||
          authUserInfo.email === 'sam@bettersense.io')
        ) {
          paymentMethods.push({
            text: 'Create new payment method',
            value: 'create'
          });
        }
        this.setState({
          paymentMethods: paymentMethods,
          paymentMethodSelected: paymentMethodSelected,
        });
      },
      (error) => {
        const data = error.response;
        logger.d(LOG_TAG, 'resetUserPaymentMethods', 'onError', data);

      }
    )
  };
  render() {
    return (
      <AnimComponent>
        <div className="card mt-3">
          <div className="card-body">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="">Existing car registrations</label>
                  <select name="" id="" className="form-control"
                          value={this.state.carPlateSelected}
                          disabled={this.state.userParkInfo !== null}
                          onChange={this.onSelectCarPlate}>
                    <option value="select">Select car registration</option>
                    {
                      this.state.carPlates.map((plate, index) => (
                        <option
                          key={index}
                          value={index}>{plate.toUpperCase()}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="">Select Payment Method</label>
                  <select name="" id="" className="form-control"
                          value={this.state.paymentMethodSelected}
                          disabled={this.state.userParkInfo !== null}
                          onChange={this.onSelectPaymentMethod}>
                    <option value="select">Select Payment Method</option>
                    {
                      this.state.paymentMethods.map((method, index) => (
                        <option
                          key={index}
                          value={index}>{method.text}</option>
                      ))
                    }
                  </select>

                </div>
              </div>
            </div>
            {
              this.state.paymentMethodSelected !== 'select' && this.state.paymentMethods[this.state.paymentMethodSelected].value === 'create' ?
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <StripeProvider stripe={this.state.stripe}>
                        <Elements>
                          <AddCardForm onResult={this.onAddCardComplete}/>
                        </Elements>
                      </StripeProvider>
                    </div>
                  </div>
                </div>
                : null
            }
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="">Available Rate Options:</label>
                  <div>
                    <label
                      style={{color: this.state.availableTime === 0 ? 'lightgrey' : 'black'}}
                      htmlFor="rate-standard">
                      <input id="rate-standard" type="radio"
                             value="standard"
                             checked={this.state.parkingRateOption === 'standard'}
                             onChange={this.onChangeRateOption}
                             disabled={this.state.availableTime === 0}/>
                      &nbsp;Standard Rate
                    </label>
                    <label
                      className="ml-2"
                      style={{color: (this.hasNextAvailableFixedRateWindow()) ? 'black' : 'lightgrey'}}
                      htmlFor="rate-fixed">
                      <input
                          id="rate-fixed" type="radio"
                          value="fixed"
                          checked={this.state.parkingRateOption === 'fixed'}
                          onChange={this.onChangeRateOption}
                          disabled={!this.hasNextAvailableFixedRateWindow()}
                            />
                      &nbsp;Fixed Rate
                    </label>
                  </div>
                </div>
              </div>
            </div>
            {
              (this.state.parkingRateOption === 'standard' && this.state.availableTime > 0) && (
                <div className="row">
                  <RangeSlider
                    title="Available parking duration"
                    domain={this.state.durationDomain}
                    step={this.state.durationStep}
                    values={[this.state.defaultParkingDuration]}
                    onUpdate={this.onChangeParkingDuration}
                    unitSuffix="min">
                  </RangeSlider>
                </div>
              )
            }
            {
              (this.state.userParkInfo && this.state.parkingRateOption === 'standard') && (
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="">Active session</label>
                      <div className="form-control clr-warning">
                        <small>
                          {`
                      ${window.moment(dataHelper.formatMillisecFromSec(this.state.userParkInfo.time_start)).format('DD.MMM HH:mm')} ~
                      ${window.moment(dataHelper.formatMillisecFromSec(this.state.userParkInfo.time_end)).format('DD.MMM HH:mm')}
                      `}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
            {
              (this.hasNextAvailableFixedRateWindow() && this.state.parkingRateOption === 'fixed') && (
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="">{this.getNextAvailableFixedRateWindow().description}</label>
                      <div className="form-control clr-warning">
                        <small>
                          {`
                      ${this.getNextAvailableFixedRateWindow().start} (${this.getNextAvailableFixedRateWindow().end}) ~ 
                      ${this.getNextAvailableFixedRateWindow().offer_end}
                      `}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
            <div className="row flex-row justify-content-end pr-4">
              <label><strong>Cost: </strong></label>
              <label style={{marginLeft: '0.33rem', color: 'red'}}>{dataHelper.formatPriceFromValue(this.state.parkingCharge)}</label>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  {
                    <button
                      className={`
                            ${this.validateStartParking() ?'btn':'btn__disabled'}
                            btn-default float-right`}
                            onClick={this.onClickParking}>
                      {
                        this.state.userParkInfo ? 'Extend' : 'Start'
                      }
                    </button>
                  }

                </div>
              </div>
            </div>
          </div>
        </div>
      </AnimComponent>
    );
  }
  onSelectCarPlate = e => {
    const selected = e.target.value;
    logger.d(LOG_TAG, 'onSelectCarPlate', selected);
    this.setState({
      carPlateSelected: selected,
    });
  };
  onSelectPaymentMethod = e => {
    const selected = e.target.value;
    logger.d(LOG_TAG, 'onSelectPaymentMethod', selected);
    this.setState({
      paymentMethodSelected: selected,
    });
  };
  onChangeRateOption = value => {
    logger.d(LOG_TAG, 'onChangeRateOption', value.target.value);
    const newState = {
      parkingRateOption: value.target.value,
    }
    if (newState.parkingRateOption === 'standard') {
    } else {
      const fixedRateWindow = this.getNextAvailableFixedRateWindow()
      newState.parkingCharge = fixedRateWindow ? fixedRateWindow.price : null
    }
    this.setState(newState)
  }
  onChangeParkingDuration = value => {
    const duration = value[0];
    logger.d(LOG_TAG, 'onChangeParkingDuration', duration);
    let minDuration = 0;
    let basePrice = 0;
    let initialDuration = 0;
    let initialRate = 0;

    if (this.state.siteInfo) {
      minDuration = this.state.siteInfo.min_duration;
      basePrice = this.state.siteInfo.base_price;

      initialDuration = this.state.siteInfo.initial_duration ? this.state.siteInfo.initial_duration : 0;
      initialRate = this.state.siteInfo.initial_rate ? this.state.siteInfo.initial_rate : 0;
    }
    let existingParkingDuration = 0;
    if (this.state.userParkInfo) {
      if (this.state.userParkInfo.code) {
        existingParkingDuration = initialDuration;
      } else {
        existingParkingDuration = this.state.userParkInfo.estimated_duration;
      }
    }
    const newState = {
      parkingDuration: duration,
      parkingCharge: dataHelper.calculateParkingCharge(
        existingParkingDuration,
        duration,
        minDuration,
        basePrice,
        initialDuration,
        initialRate,
      )
    };

    this.setState(newState);
  };
  onAddCardComplete = (result, value) => {
    logger.d(LOG_TAG, 'onAddCardComplete', result, value);
    if (result) {
      this.resetUserPaymentMethods(
        this.state.authUserInfo,
        this.state.userInfo,
        value.paymeth_id
      );
    }
  };
  validateStartParking() {
    let validated = false;
    if (
      this.state.siteInfo &&
      this.state.slotInfo &&
      this.state.carPlateSelected !== 'selected' &&
      ( (this.state.parkingRateOption === 'standard' && this.state.parkingDuration > 1) || (this.state.parkingRateOption === 'fixed' && this.hasNextAvailableFixedRateWindow()))
    ) {

      if (this.state.paymentMethodSelected !== 'select')
      {
        const paymentMethod = this.state.paymentMethods[this.state.paymentMethodSelected];
        if (paymentMethod && paymentMethod.value !== 'create') {
          validated = true;
        }
      }
    }
    logger.d(LOG_TAG, 'validateStartParking', validated, this.state.availableTime, this.state.siteInfo, this.state.slotInfo);
    return validated;
  };
  onClickStartParking = () => {
    const validated = this.validateStartParking();
    logger.d(LOG_TAG, 'onClickStartParking', validated);
    if (validated) {
      this.props.setLoading(StateInfo.LoadingInfo.format(true, 'Start parking'));

      let parkingInfo = StateInfo.ParkingInfo(
        {
          user_id: this.state.userInfo.user_id,
          site_id: this.state.siteInfo.site_id,
          slot_id: this.state.slotInfo.slot_id,
          car_plate: this.state.carPlates[this.state.carPlateSelected],
        }
      );
      if (this.state.parkingRateOption === 'fixed') {
        parkingInfo.setCode(this.getNextAvailableFixedRateWindow().code);
      } else {
        parkingInfo.setDuration(this.state.parkingDuration);
      }
      parkingInfo.setPaymentMethod(this.state.paymentMethods[this.state.paymentMethodSelected].value);

      logger.d(LOG_TAG, 'onClickStartParking', parkingInfo);

      window.webapi.User.postStartParking(
        this.state.userInfo.user_id,
        parkingInfo,
        (response) => {
          const data = response.data;
          logger.d(LOG_TAG, 'onClickStartParking', 'postStartParking', 'onResponse', data);
          setTimeout(() => {
            logger.d(LOG_TAG, 'onClickStartParking', 'getLastParkingHistory', 'onResponse', data);
            // demo:
            // data.code = 'earlybird'
            this.setState({
              userParkInfo: data,
            });
            this.props.setLoading(StateInfo.LoadingInfo.format(false, ''));
            // notify parking session started
            this.props.setNotifyStartParking(StateInfo.OnNotifyStartParking.create());
            const authUserInfo = localStorageHelper.getAuthUserInfo();
            firebaseService.startParkingSessionByAdmin(
              authUserInfo.email,
              data.user_id,
              this.state.userInfo.email,
              data.car_plate,
              this.state.parkingDuration,
              this.state.siteInfo.name,
              this.state.slotInfo.number,
              this.state.parkingRateOption === 'fixed' ? parkingInfo.code : null
            );
          }, 800);
        },
        (error) => {
          const notify = dataHelper.formatError('Parking', error.data);
          setTimeout(() => {
            this.props.setLoading(StateInfo.LoadingInfo.format(false, ''));
            this.props.setNotification(
              StateInfo.NotificationInfo.format('danger', notify.title, notify.message)
            );
          }, 800);
        }
      )
    }
  };
  onClickParking = e => {
    e.preventDefault()
    
    if (this.state.userParkInfo !== null) {
      window.confirm(`Are you sure you wish to extend the parking session?`) &&
      this.onClickExtendParking()
    } else {
      window.confirm(`Are you sure you wish to start the parking session?`) &&
      this.onClickStartParking()
    }
  }
  onClickExtendParking = () => {
    const validated = this.validateStartParking();
    logger.d(LOG_TAG, 'onClickExtendParking', validated);
    if (validated) {
      this.props.setLoading(StateInfo.LoadingInfo.format(true, 'Extending parking'));

      let parkingInfo = StateInfo.ParkingInfo(
        {
          user_id: this.state.userInfo.user_id,
          site_id: this.state.siteInfo.site_id,
          slot_id: this.state.slotInfo.slot_id,
          car_plate: this.state.carPlates[this.state.carPlateSelected],
        }
      );
      if (this.state.parkingRateOption === 'fixed') {
        parkingInfo.setCode(this.getNextAvailableFixedRateWindow().code);
      } else {
        parkingInfo.setDuration(this.state.parkingDuration);
      }
      parkingInfo.setPaymentMethod(this.state.paymentMethods[this.state.paymentMethodSelected].value);

      logger.d(LOG_TAG, 'onClickExtendParking', parkingInfo);
      window.webapi.User.putExtendParking(
        this.state.userInfo.user_id,
        this.state.userParkInfo.parking_id,
        parkingInfo,
        (response) => {
          const data = response.data;
          logger.d(LOG_TAG, 'onClickExtendParking', 'postStartParking', 'onResponse', data);
          setTimeout(() => {
            logger.d(LOG_TAG, 'onClickExtendParking', 'getLastParkingHistory', 'onResponse', data);
            this.setState({
              userParkInfo: data,
            });
            this.props.setLoading(StateInfo.LoadingInfo.format(false, ''));
            // notify parking session started
            this.props.setNotifyStartParking(StateInfo.OnNotifyStartParking.create());
            const authUserInfo = localStorageHelper.getAuthUserInfo();
            firebaseService.extendParkingSessionByAdmin(
              authUserInfo.email,
              data.user_id,
              this.state.userInfo.email,
              data.car_plate,
              this.state.parkingDuration,
              this.state.siteInfo.name,
              this.state.slotInfo.number,
              this.state.parkingRateOption === 'fixed' ? parkingInfo.code : null
            );
          }, 800);
        },
        (error) => {
          const notify = dataHelper.formatError('Parking', error.data);
          setTimeout(() => {
            this.props.setLoading(StateInfo.LoadingInfo.format(false, ''));
            this.props.setNotification(
              StateInfo.NotificationInfo.format('danger', notify.title, notify.message)
            );
          }, 800);
        }
      )
    }
  }
}
export default connect(
  null,
  {
    setLoading,
    setNotification,
    setNotifyStartParking
  }
)(ParkingProfile);


