import React, {Component} from 'react';
import logger from '../../utils/logger';
/* helper */

const LOG_TAG = 'AddressAutocomplete';
class AddressAutocomplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      autocomplete: null,
    };
    this.onPlaceChanged = this.onPlaceChanged.bind(this);
  }
  componentDidMount() {
    logger.d(LOG_TAG, 'componentDidMount');
    // Declare Options For Autocomplete
    // Create the autocomplete object, restricting the search predictions to
    // geographical location types.
    this.state.autocomplete = new window.google.maps.places.Autocomplete(
      document.getElementById('autocomplete'),
      {types: ['geocode']});

    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components.
    this.state.autocomplete.setFields(['formatted_address', 'geometry']);
    this.state.autocomplete.setComponentRestrictions({'country': ['nz']});

    // When the user selects an address from the drop-down, populate the
    // address fields in the form.
    this.state.autocomplete.addListener('place_changed', this.onPlaceChanged);
  }
  componentDidUpdate(oldProps) {
    const newProps = this.props;
    if (oldProps.value !== newProps.value) {
      this.setState({
        value: newProps.value
      })
    }
  }
  render() {
    return (
      <input id="autocomplete"
             type="text"
             className="form-control"
             placeholder="Enter address"
             value={this.state.value}
            onChange={this.onChangeValue}/>
      );
  }
  onChangeValue = e => {
    const value = e.target.value;
    // logger.d(LOG_TAG, 'onChangeValue', value);
    this.setState({
      value: value,
    })
  };
  onPlaceChanged = () => {
    // Get the place details from the autocomplete object.
    let place = this.state.autocomplete.getPlace();
    if (this.props.onPlaceChanged) {
      this.props.onPlaceChanged({
        address: place.formatted_address,
        position: {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        }
      });
    }
    // logger.d(LOG_TAG, 'onPlaceChanged', place, this.props.onPlaceChanged);
  }
}
export default AddressAutocomplete;

