export default {
  enabled: false,
  text: '',
  format(enabled, text) {
    return {
      enabled: enabled,
      text: text
    };
  }
};
