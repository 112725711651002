export default {
  isVisible(slotInfo) {
    if (slotInfo.is_available === true) {
      if (slotInfo.is_reserved === false && slotInfo.is_busy === false) {
        return true;
      }
      return false
    } else {
      return false;
    }
  },
  TRAFFIC_LIGHT: {
    GREEN: 'GREEN',
    GREY: 'GREY',
    AMBER: 'AMBER',
    RED: 'RED'
  },
  isVisibleToVisitor(slotInfo) {
    const light = this.trafficLight(slotInfo);
    if (this.TRAFFIC_LIGHT.GREEN === light || this.TRAFFIC_LIGHT.RED === light) {
      return true;
    }
    return false;
  },
  trafficLight(slotInfo) {
    //
    if (slotInfo.sensor_data && slotInfo.sensor_data.device_id !== 0) {
      if (slotInfo.sensor_data.status === true) {
        if (slotInfo.is_reserved === true || slotInfo.is_busy === true) {
          return this.TRAFFIC_LIGHT.GREY;
        }
        return this.TRAFFIC_LIGHT.RED;
      } else {
        if (slotInfo.is_reserved === true || slotInfo.is_busy === true) {
          return this.TRAFFIC_LIGHT.AMBER;
        }
        return this.TRAFFIC_LIGHT.GREEN;
      }
    } else {
      if (slotInfo.is_reserved === true || slotInfo.is_busy === true) {
        return this.TRAFFIC_LIGHT.GREY;
      }
      return this.TRAFFIC_LIGHT.GREEN;
    }
  },
};
