import React, {Component} from 'react';
import Routes from '../../routes';
import BodyHost from '../../components/bodyHost';
import Nav from "react-bootstrap/Nav";
import logger from '../../utils/logger';
import ParkingSites from './parkingAssets/parkingSites';
import SiteSlots from './parkingAssets/siteSlots';

const LOG_TAG = 'ParkingAssets';
class ParkingAssets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuPs: Routes.main[Routes.INDEX_PA].routes[0],
      menuSs: Routes.main[Routes.INDEX_PA].routes[1],
      options: [],
      selectedMenu: '',
      selectedSiteParams: '',
    };
    this.state.options.push({
      id: this.state.menuPs.id,
      name: this.state.menuPs.name,
    });
    this.state.selectedMenu = this.state.menuPs.id;
  }
  componentDidMount() {
  }
  render() {
    return (
      <BodyHost
        navi={
          <div>
            <Nav className="flex-nowrap navi-side-menu-override" onSelect={k => this.onClickNavi(k)}>
              {
                this.state.options.map((option, index) =>
                  <Nav.Item
                    key={index}>
                    <Nav.Link
                      eventKey={index}
                      bsPrefix={'navi-side-menu-link-override'}>
                      <div className={
                        option.id === this.state.selectedMenu
                          ? 'navi-side-menu-link li__side-menu--focus'
                          : 'navi-side-menu-link li__side-menu--no-focus'
                      }>
                        {option.name}
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                )
              }
            </Nav>
            <div className="nav-desktop ml-3 mb-1" style={{position: "fixed", bottom: "0"}}>
              <label style={{fontSize:"0.66rem", color: "grey"}}>{`Ver: ${process.env.REACT_APP_VERSION}`}</label>
            </div>
          </div>


        }
        content={
          this.state.selectedMenu === this.state.menuPs.id ?
            this.state.selectedSiteParams ?
              <SiteSlots
                params={this.state.selectedSiteParams}
                onClickBack={this.onClickBack}></SiteSlots> :
              <ParkingSites onClickSite={this.onClickSite}></ParkingSites> : null
        }>

      </BodyHost>
    );
  }
  onClickNavi = (k) => {
    logger.d(LOG_TAG, 'onClickNavi', k);
    this.setState({
      selectedMenu: this.state.options[k].id
    });
  };
  onClickSite = (params) => {
    logger.d(LOG_TAG, 'onClickSite', params);
    this.setState({
      selectedSiteParams: params
    })
  };
  onClickBack = () => {
    logger.d(LOG_TAG, 'onClickBack');
    this.setState({
      selectedSiteParams: ''
    });
  }
}

export default ParkingAssets;
