import logger from '../../utils/logger';

const LOG_TAG = 'parkSlotInfo';
export default {
  slot_id: 0,
  site_id: 0,
  number: '',
  all_day: false,
  is_busy: false,
  is_available: false,
  is_reserved: false,
  sensor_data: {
    device_id: 0,
    status: false,
  },
  days_available: [
    /*{
     day: 'Monday',
     windows: [
       {
         start: '00:00',
         end: '23:59',
       }
     ]
    }*/
  ],
  // model helper
  create(siteId, number) {
    return {
      site_id: siteId,
      number: number,
      days_available: [],
      setSlotId(slotId) {
        this.slot_id = slotId;
        return this;
      },
      addAllDayWindow(day) {
        const exists = this.days_available.filter((dayInfo) => dayInfo.day === day);
        if (exists.length === 0) {
          this.days_available.push(
            {
              day: day,
              all_day: true,
            }
          )
        }
        return this;
      },
      addWindow(day, start, end) {
        const exists = this.days_available.filter((dayInfo) => dayInfo.day === day);
        logger.d(LOG_TAG, 'addWindow', exists, day);
        if (exists.length > 0) {
          const dayInfo = exists[0];
          if (dayInfo.windows) {
            dayInfo.windows.push({start: start, end: end})
          } else {
            dayInfo.windows = [
              {start: start, end: end}
            ]
          }
        } else {
          this.days_available.push(
            {
              day: day,
              windows: [
                {start: start, end: end}
              ]
            }
          )
        }
        return this;
      }
    };
  },
};

