import React, {Component} from 'react';
import logger from '../../utils/logger';
/* helper */

const LOG_TAG = 'Map';
const DEFAULT_ZOOM = 12;
const DEFAULT_CENTER = {lat: -36.894681, lng: 174.742858};
const CREATE_MARKER_ICON = (imageUrl) => {
  return {
    url: imageUrl,
    origin: new window.google.maps.Point(0, 0),
    scaledSize: new window.google.maps.Size(31, 36),
    anchor: new window.google.maps.Point(16, 36),
    optimized: false,
    zIndex: 1,
  }
};
const CREATE_MARKER_ICON_CLICKED = (imageUrl) => {
  return {
    url: imageUrl,
    origin: new window.google.maps.Point(0, 0),
    scaledSize: new window.google.maps.Size(41, 48),
    anchor: new window.google.maps.Point(20, 48),
    optimized: false,
    zIndex: 2,
  }
};
class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      map: null,
      autocomplete: null,
      center: DEFAULT_CENTER,
      zoom: DEFAULT_ZOOM,
      selectedMarker: -1,
      markerList: [],
      setMarker: null,
    };
    this.onClickMarker = this.onClickMarker.bind(this);
  }
  componentDidMount() {
    const options = {
      zoom: this.state.zoom,
      center: this.state.center,
      streetViewControl: false,
    };
    this.state.map = new window.google.maps.Map(document.getElementById('map'), options);
    if (this.props.onClickMap) {
      this.state.map.addListener('click', (e) => {
        const location = e.latLng;
        this.props.onClickMap({
          lat: location.lat(),
          lng: location.lng()
        })
        // logger.d(LOG_TAG, 'onClickMap', location.lat(), location.lng())
      });
    }
  }
  componentDidUpdate(oldProps) {
    const newProps = this.props;
    if (newProps.panTo && oldProps.panTo !== newProps.panTo) {
      this.state.map.panTo(newProps.panTo);
    }
    if (oldProps.marker !== newProps.marker) {
      this.resetMarkers(this.state.map, [newProps.marker], 0);
    } else {
      if (oldProps.markers !== newProps.markers) {
        this.resetMarkers(this.state.map, newProps.markers);
      }
      if (oldProps.selected !== newProps.selected) {
        if ( this.state.markerList.length > newProps.selected) {
          this.onHandlerSelectMarker(this.state.markerList[newProps.selected])
        }
      }
    }
  }
  resetMarkers(map, markers, defaultIndex) {
    // unbind marker from map and clear list
    this.state.markerList.forEach((marker) => {
      marker.setMap(null);
    });

    // reset and create new markers bind click listener
    this.state.markerList = [];
    this.state.selectedMarker = -1;
    markers.forEach((info, index) => {
      if (info) {
        let marker = new window.google.maps.Marker({
          index: index,
          animate: info.animate,
          position: info.position,
          icon: defaultIndex === 0  ? CREATE_MARKER_ICON_CLICKED(info.icon) : CREATE_MARKER_ICON(info.icon),
          map: map,
        });
        marker.addListener('click', this.onClickMarker(marker));
        // cache it for next reset
        this.state.markerList.push(marker)
      }
    });
    if (defaultIndex === 0 && this.state.markerList.length > 0) {
      const marker = this.state.markerList[0];
      if (marker.animate === true) {
        this.state.map.panTo(marker.position);
        this.state.map.setZoom(16);
      } else {
        this.state.map.setCenter(marker.position);
        this.state.map.setZoom(16);
      }
    }
  };
  render() {
    return (
      <div style={{width: '100%', height: '100%'}} id="map">
      </div>
    );
  }
  onClickMarker = marker => e => {
    this.onHandlerSelectMarker(marker)
  };
  onHandlerSelectMarker(marker) {
    logger.d(LOG_TAG, 'onHandlerSelectMarker', marker.index);
    // reset previous marker
    if (this.state.selectedMarker !== -1) {
      const prevMarker = this.state.markerList[this.state.selectedMarker];
      prevMarker.setIcon(CREATE_MARKER_ICON(prevMarker.icon.url));
    }
    // update new marker
    this.state.selectedMarker = marker.index;
    this.state.map.setZoom(DEFAULT_ZOOM);
    this.state.map.panTo(marker.getPosition());
    marker.setIcon(CREATE_MARKER_ICON_CLICKED(marker.icon.url));
    if (this.props.onClickMarker) {
      this.props.onClickMarker(marker.index);
    }
  }
}
export default Map;

