import React, {Component} from 'react';
import {connect} from 'react-redux';
import {setLoading, setNotification} from '../store/actions';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import updateParkSlot from '../handlers/updateParkSlot';
import updateParkSlotSensor from '../handlers/updateParkSlotSensor';
import logger from '../utils/logger';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import StateInfo from '../store/states';
import localStorageHelper from '../utils/localStorageHelper';
import accessHelper from '../utils/accessHelper';
import dataHelper from '../utils/dataHelper';

const LOG_TAG = 'DlgAddSiteSlot';

class DlgAddSiteSlot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authUserInfo: null,
      siteInfo: {},
      slotInfo: null,

      tsNumber: '',
      tsNumberChanged: false,
      tsDayOfWeek: 'select',
      tsStartSelected: 'select',
      tsEndSelected: 'select',
      tsWindows: [/*
        {
          tsDayOfWeek: 'Monday',
          tsStart: '',
          tsEnd: '',
        }
        */
      ],
      tsWindowsChanged: false,
      isAllDay: false,

      isAddSensor: false,
      isSensorChanged: false,
      deviceModelId: 'select',
      deviceEui: '',
      appKey: '',
      appEui: '',
    };
  }
  componentDidMount() {
    logger.d(LOG_TAG, 'componentDidMount');
    // format window options
    const authUserInfo = localStorageHelper.getAuthUserInfo();
    this.setState({
      authUserInfo: authUserInfo
    });
    this.resetState();
  };
  componentDidUpdate(oldProps) {
    const newProps = this.props;
    if (oldProps.siteInfo !== newProps.siteInfo ||
      oldProps.slotInfo !== newProps.slotInfo
    ) {
      const siteInfo = newProps.siteInfo;
      const slotInfo = newProps.slotInfo;
      const slotWindows = [];
      let sensorInfo = null;
      if (slotInfo) {
        sensorInfo = slotInfo.sensor_data;
        if (slotInfo.days_available) {
          slotInfo.days_available.forEach((dayInfo) => {
            if (dayInfo.all_day) {
              slotWindows.push({
                sortId: dataHelper.getDayOfWeekSid(dayInfo.day),
                tsDayOfWeek: dayInfo.day,
                isAllDay: dayInfo.all_day,
              })
            } else if (dayInfo.windows) {
              dayInfo.windows.forEach((windowInfo) => {
                slotWindows.push({
                  sortId: dataHelper.getDayOfWeekSid(dayInfo.day),
                  tsDayOfWeek: dayInfo.day,
                  tsStart: windowInfo.start,
                  tsEnd: windowInfo.end,
                })
              })
            }
          });
        }
      }
      slotWindows.sort((a, b) => (`${a.sortId}${a.tsStart}` > `${b.sortId}${b.tsStart}`) ? 1 : -1);
      const newState = {
        siteInfo: siteInfo,
        slotInfo: slotInfo,
        tsNumber: slotInfo ? slotInfo.number : '',
        tsNumberChanged: false,
        tsDayOfWeek: 'select',
        tsStartSelected: 'select',
        tsEndSelected: 'select',
        tsWindows: slotWindows,
        tsWindowsChanged: false,
        isAllDay: false,

        isAddSensor: false,
        isSensorChanged: false,
        deviceModelId: (sensorInfo && sensorInfo.model_id) ? sensorInfo.model_id.toString() : 'select',
        deviceEui: sensorInfo ? sensorInfo.device_eui : '',
        appKey: sensorInfo ? sensorInfo.application_key : '',
        appEui: sensorInfo ? sensorInfo.application_eui : '',
      };
      this.setState(newState);
    }
  }
  resetState() {
    logger.d(LOG_TAG, 'resetState', this.props.siteInfo, this.props.slotInfo);
    this.setState({
      siteInfo: this.props.siteInfo,
      slotInfo: null,
      tsNumber: '',
      tsNumberChanged: false,
      tsDayOfWeek: 'select',
      tsStartSelected: 'select',
      tsEndSelected: 'select',
      tsWindows: [],
      tsWindowsChanged: false,
      isAllDay: false,

      isAddSensor: false,
      isSensorChanged: false,
      deviceModelId: 'select',
      deviceEui: '',
      appKey: '',
      appEui: '',
    });
  };
  render() {
    return (
      <Modal
        show={this.props.show}
        backdrop={'static'}
        onHide={this.onClickHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {this.props.sensorOnly === true ? 'Update Sensor' : this.state.slotInfo ? 'Update Space' : 'Create Space'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Slot Details */}
          {
            !this.props.sensorOnly && (
              <div className="row">
                <div className="col-md-12">
                  {/* slot details */}
                  <h5>Space Details</h5>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="">Number</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter space number"
                            value={this.state.tsNumber}
                            onChange={this.onChangeNumber}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
          {/* time slots */}
          {
            !this.props.sensorOnly && (
              <div className="row">
                <div className="col-md-12">
                  <h5>Time Slots</h5>
                  <div className="row">
                    {/* time slots - left */}
                    <div className="col-md-6">
                      <div className="form-group">
                        {/* time input*/}
                        <div className="row">
                          <div className="col-md-12">
                            <label className="form-check-label" htmlFor="">Day of week</label>
                            <select
                              className="form-control"
                              value={this.state.tsDayOfWeek}
                              onChange={this.onSelectDayOfWeek}>
                              <option value="select">Select day</option>
                              {
                                dataHelper.getDaysOfWeek().map((day, index) => (
                                  <option
                                    key={index}
                                    value={index}>{day.text}</option>
                                ))
                              }
                            </select>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6">
                            <label className="form-check-label" htmlFor="">Start</label>
                            <select
                              className="form-control"
                              value={this.state.tsStartSelected}
                              onChange={this.onSelectWindowStart}>
                              <option value="select">Select start</option>
                              {
                                dataHelper.getWindowOptions().map((option, index) => (
                                  <option
                                    key={index}
                                    value={index}>{option.text}</option>
                                ))
                              }
                            </select>
                          </div>
                          <div className="col-md-6">
                            <label className="form-check-label" htmlFor="">End</label>
                            <select
                              className="form-control"
                              value={this.state.tsEndSelected}
                              onChange={this.onSelectWindowEnd}>
                              <option value="select">Select end</option>
                              {
                                dataHelper.getWindowOptions().map((option, index) => (
                                  <option
                                    key={index}
                                    value={index}>{option.text}</option>
                                ))
                              }
                            </select>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-md-12">
                            <div className="float-right">
                              <label>
                                <input
                                  type="checkbox"
                                  value={this.state.isAllDay}
                                  onClick={this.onClickAllDay}/> All day parking
                              </label>
                              <button className="btn-invert btn-default-small ml-2 pt-1" onClick={this.onClickAddTimeSlot}>
                                <FontAwesomeIcon icon="plus"></FontAwesomeIcon> Add
                              </button>
                            </div>

                          </div>
                        </div>

                      </div>
                    </div>
                    {/* time slots - right */}
                    <div className="col-md-6">
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-12">
                            {/* time slots*/}
                            {
                              this.state.tsWindows.map((windowInfo, index) => (
                                <div key={index} className="app-item-underline-border pt-1 pb-1">
                                  <div className="form-check">
                                    <label className="form-check-label" htmlFor={`slot+${index}`}>{windowInfo.tsDayOfWeek}: </label>
                                    <div className="float-right mr-1 pb-2" onClick={this.onClickRemoveTimeSlot.bind(this, index)}><FontAwesomeIcon icon="trash-alt" /></div>
                                    {
                                      windowInfo.isAllDay ?
                                        <label className="form-check-label float-right pt-2 mr-3" htmlFor={`slot+${index}`}>All day parking </label>
                                        :
                                        <label className="form-check-label float-right pt-2 mr-3" htmlFor={`slot+${index}`}>{windowInfo.tsStart} ~ {windowInfo.tsEnd} </label>
                                    }
                                  </div>
                                </div>
                              ))
                            }
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            )
          }
          {/* sensor info */}
          {
            ( this.state.authUserInfo && accessHelper.isSct(this.state.authUserInfo) && (!this.state.slotInfo || this.props.sensorOnly === true)) && (
              <div className="row">
                <div className="col-md-12">
                  <h5>Parking Sensor</h5>
                  <label>
                    <input
                      type="checkbox"
                      value={this.state.isAddSensor}
                      onClick={this.onClickAddSensor}/>{
                    this.state.slotInfo && this.state.slotInfo.sensor_data.device_id !== 0 ? ' Update Sensor' : ' Add Sensor'
                  }
                  </label>
                  <div className="form-group">
                    <div className="row">
                      {
                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="form-check-label" htmlFor="">Model ID</label>
                            <select
                              className="form-control"
                              value={this.state.deviceModelId}
                              onChange={this.onSelectModelId}
                              disabled={!this.state.isAddSensor}>
                              <option value="select">Select Model Id</option>
                              {
                                dataHelper.getModelIdOptions().map((option, index) => (
                                  <option
                                    key={index}
                                    value={option.value}>{option.text}</option>
                                ))
                              }
                            </select>
                          </div>
                        </div>
                      }
                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="">Device EUI</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Device EUI"
                            value={this.state.deviceEui}
                            disabled={!this.state.isAddSensor}
                            onChange={this.onChangeDeviceEui}/>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="">App Key</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter App Key"
                            value={this.state.appKey}
                            disabled={!this.state.isAddSensor}
                            onChange={this.onChangeAppKey}/>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="">App EUI</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter App EUI"
                            value={this.state.appEui}
                            disabled={!this.state.isAddSensor}
                            onChange={this.onChangeAppEui}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              )
          }
        </Modal.Body>
        <Modal.Footer>
          <Button className={`
                      ${this.validateSlotDetails()?'btn':'btn__disabled'}
                      btn-default`}
                  onClick={this.onClickAddSlot}>{
            this.state.slotInfo ? 'Update' : 'Create'
          }</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  onChangeNumber = e => {
    const value = e.target.value;
    logger.d(LOG_TAG, 'onChangeNumber', value);
    this.setState({
      tsNumber: value,
      tsNumberChanged: true,
    })
  };
  onSelectDayOfWeek = e => {
    const value = e.target.value;
    logger.d(LOG_TAG, 'onChangeChargeRate', value);
    this.setState({
      tsDayOfWeek: value
    });
  };
  onSelectWindowStart = e => {
    const value = e.target.value;
    logger.d(LOG_TAG, 'onSelectWindowStart', value);
    if (this.state.tsEndSelected === 'select' || parseInt(this.state.tsEndSelected, 10) <= parseInt(value, 10)) {
      this.setState({
        tsStartSelected: value,
        tsEndSelected: value,
      });
    } else {
      this.setState({
        tsStartSelected: value,
      });
    }
  };
  onSelectWindowEnd = e => {
    const value = e.target.value;
    logger.d(LOG_TAG, 'onSelectWindowEnd', value);
    if (this.state.tsStartSelected === 'select' || parseInt(this.state.tsStartSelected, 10) >= parseInt(value, 10)) {
      this.setState({
        tsStartSelected: value,
        tsEndSelected: value
      });
    } else {
      this.setState({
        tsEndSelected: value
      });
    }
  };
  onClickAddTimeSlot = () => {
    if (this.state.tsDayOfWeek !== 'select') {
      logger.d(LOG_TAG, 'onClickAddTimeSlot', this.state.isAllDay);
      if (this.state.isAllDay) {
        const tsWindows = this.state.tsWindows;
        tsWindows.push({
          sortId: dataHelper.getDayOfWeekSid(dataHelper.getDaysOfWeek()[this.state.tsDayOfWeek].value),
          tsDayOfWeek: dataHelper.getDaysOfWeek()[this.state.tsDayOfWeek].text,
          isAllDay: true,
        });
        tsWindows.sort((a, b) => (`${a.sortId}${a.tsStart}` > `${b.sortId}${b.tsStart}`) ? 1 : -1);
        this.setState({
          tsWindows: tsWindows,
          tsWindowsChanged: true,
        });
      } else if (this.state.tsStartSelected !== 'select' && this.state.tsEndSelected !== 'select') {
        if (this.state.tsStartSelected !== this.state.tsEndSelected) {
          const tsWindows = this.state.tsWindows;
          tsWindows.push({
            sortId: dataHelper.getDayOfWeekSid(dataHelper.getDaysOfWeek()[this.state.tsDayOfWeek].value),
            tsDayOfWeek: dataHelper.getDaysOfWeek()[this.state.tsDayOfWeek].text,
            tsStart: dataHelper.getWindowOptions()[this.state.tsStartSelected].value,
            tsEnd: dataHelper.getWindowOptions()[this.state.tsEndSelected].value
          });
          tsWindows.sort((a, b) => (`${a.sortId}${a.tsStart}` > `${b.sortId}${b.tsStart}`) ? 1 : -1);
          this.setState({
            tsWindows: tsWindows,
            tsWindowsChanged: true,
          });
        }
      }
    }
  };
  onClickRemoveTimeSlot = param => {
    logger.d(LOG_TAG, 'onClickRemoveTimeSlot', param);
    const tsWindows = this.state.tsWindows;
    tsWindows.splice(param, 1);
    this.setState({
      tsWindows: tsWindows,
      tsWindowsChanged: true,
    });
  };
  validateSlotDetails() {
    let validated = false;
    if (
      (this.state.tsNumber && this.state.tsNumberChanged) ||
      (this.state.tsWindowsChanged) ||
      (this.state.isSensorChanged)
    ) {
      validated = true;
    }
    // logger.d(LOG_TAG, 'validateSlotDetails', validated);
    return validated;
  };
  onClickHide = e => {
    logger.d(LOG_TAG, 'onClickHide');
    if (this.props.onHide) {
      this.resetState();
      this.props.onHide();
    }
  };
  onClickAddSensor = e => {
    this.setState({
      isAddSensor: !this.state.isAddSensor
    });
  };
  onSelectModelId = e => {
    const value = e.target.value;
    logger.d(LOG_TAG, 'onSelectModelId', value);
    this.setState({
      deviceModelId: value,
      isSensorChanged: true,
    });
  };
  onChangeDeviceEui = e => {
    const value = e.target.value;
    logger.d(LOG_TAG, 'onChangeDeviceEui', value);
    this.setState({
      deviceEui: value,
      isSensorChanged: true,
    });

  };
  onChangeAppKey = e => {
    const value = e.target.value;
    logger.d(LOG_TAG, 'onChangeAppKey', value);
    this.setState({
      appKey: value,
      isSensorChanged: true,
    });
  };
  onChangeAppEui = e => {
    const value = e.target.value;
    logger.d(LOG_TAG, 'onChangeAppEui', value);
    this.setState({
      appEui: value,
      isSensorChanged: true,
    });
  };
  onClickAllDay = e => {
    this.setState({
      isAllDay: !this.state.isAllDay
    });
  };
  onClickAddSlot = () => {
    let isValidated = this.validateSlotDetails();
    // sensor warning if required
    if (this.state.isAddSensor) {
      if (this.state.deviceModelId == 'select' || !this.state.deviceEui || !this.state.appEui || !this.state.appKey) {
        isValidated = false;
        this.props.setNotification(
          StateInfo.NotificationInfo.format('danger', 'Sensor', 'Please enter valid sensor information')
        );
      }
    }
    logger.d(LOG_TAG, 'onClickAddSlot', isValidated, this.state.siteInfo, this.state.slotInfo);
    if (isValidated) {
      // slot id
      const slotId = this.state.slotInfo ? this.state.slotInfo.slot_id : null;

      // format slot info
      let slotInfo = StateInfo.ParkSlotInfo.create(this.state.siteInfo.site_id, this.state.tsNumber);
      if (slotId) {
        slotInfo.setSlotId(slotId);
      }

      this.state.tsWindows.forEach((ts) => {
        if (ts.isAllDay) {
          slotInfo = slotInfo.addAllDayWindow(ts.tsDayOfWeek);
        } else {
          slotInfo = slotInfo.addWindow(ts.tsDayOfWeek, ts.tsStart, ts.tsEnd);
        }
      });

      // format sensor info
      let sensorInfo = StateInfo.SensorInfo.create(
        parseInt(this.state.deviceModelId),
        this.state.deviceEui,
        this.state.appKey,
        this.state.appEui);


      logger.d(LOG_TAG, 'onClickAddSlot', slotInfo, sensorInfo);
      if (slotId) {
        this.props.setLoading(StateInfo.LoadingInfo.format(true, 'Updating slot'));
      } else {
        this.props.setLoading(StateInfo.LoadingInfo.format(true, 'Creating slot'));
      }
      const groupId =  this.state.siteInfo.group_id;
      const siteId = this.state.siteInfo.site_id;
      if (slotId) {
        if (this.props.sensorOnly === true) {
          if (this.state.isAddSensor && this.state.isSensorChanged) {
            updateParkSlotSensor.execute(
              groupId,
              siteId, slotId,
              sensorInfo,
              (result, value) => {
                this.props.setLoading(StateInfo.LoadingInfo.format(false));
                if (result) {
                  if (this.props.onSave) {
                    this.props.onSave(true);
                  }
                } else {
                  const notify = dataHelper.formatError('Sensor', value);
                  this.props.setNotification(
                    StateInfo.NotificationInfo.format('danger', notify.title, notify.message)
                  );
                }
              });
          }
        } else {
          updateParkSlot.execute(
            groupId, siteId,
            slotId, slotInfo,
            (result, value) => {
              setTimeout(() => {
                if (result) {
                  this.props.setLoading(StateInfo.LoadingInfo.format(false));
                  if (this.props.onSave) {
                    this.props.onSave(true);
                  }
                } else {
                  this.props.setLoading(StateInfo.LoadingInfo.format(false));
                  const notify = dataHelper.formatError('Slot', value);
                  this.props.setNotification(
                    StateInfo.NotificationInfo.format('danger', notify.title, notify.message)
                  );
                }
              }, 1200);
            }
          )
        }
      } else {
        updateParkSlot.execute(
          groupId, siteId,
          slotId, slotInfo,
          (result, value) => {
            setTimeout(() => {
              if (result) {
                if (this.state.isAddSensor && this.state.isSensorChanged) {
                  updateParkSlotSensor.execute(
                    groupId,
                    siteId, value.slot_id,
                    sensorInfo,
                    (result, value) => {
                      this.props.setLoading(StateInfo.LoadingInfo.format(false));
                      if (result) {
                        if (this.props.onSave) {
                          this.props.onSave(true);
                        }
                      } else {
                        const notify = dataHelper.formatError('Sensor', value);
                        this.props.setNotification(
                          StateInfo.NotificationInfo.format('danger', notify.title, notify.message)
                        );
                      }
                    });
                } else {
                  this.props.setLoading(StateInfo.LoadingInfo.format(false));
                  if (this.props.onSave) {
                    this.props.onSave(true);
                  }
                }
              } else {
                this.props.setLoading(StateInfo.LoadingInfo.format(false));
                const notify = dataHelper.formatError('Slot', value);
                this.props.setNotification(
                  StateInfo.NotificationInfo.format('danger', notify.title, notify.message)
                );
              }
            }, 1200);
          }
        )
      }
    }
  };
}
export default connect(
  null,
  {
    setLoading,
    setNotification,
  }
)(DlgAddSiteSlot);


