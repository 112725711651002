import Data from './dataset/index';
import logger from '../../utils/logger';

const LOG_TAG = 'api-mock.auth';
export default {
  postOAuth(provider, payload, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'postOAuth', provider);
    setTimeout(() => {
      const data = Data.getOAuth();
      onResponseCb({
        data: data
      });
    }, 800);
  }
};
