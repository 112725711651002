import React, {Component} from 'react';
import LoadingOverlay from 'react-loading-overlay';
import connect from 'react-redux/es/connect/connect';
import {setLoading} from '../store/actions/index';
import AlertDismissible from './alertDismissable';

class PageHost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mAlertInfo: null,
    };
  }
  componentDidUpdate(oldProps) {
    const newProps = this.props;
    if (oldProps.notificationInfo !== newProps.notificationInfo) {
      this.setState({
        mAlertInfo: newProps.notificationInfo,
      });
    }
  }
  render() {
    return (
      <div>
        <LoadingOverlay
          active={this.props.loadingInfo.enabled}
          spinner
          styles={{
            overlay: base => ({
              ...base,
              position: 'fixed',
              '& svg circle': {
                stroke: 'var(--clr-primary)',
              },
              'font-size': '12px',
              zIndex: 3000
            }),
          }}
          text={this.props.loadingInfo.text}>
          {this.props.children}
        </LoadingOverlay>
        <AlertDismissible alert={this.state.mAlertInfo} />
      </div>
    );
  }
}
// listens to state change
const mapStateToProps = state => {
  const authInfo = state.authInfo;
  const loadingInfo = state.loadingInfo;
  const notificationInfo = state.notificationInfo;
  return {
    authInfo,
    loadingInfo,
    notificationInfo,
  };
};
export default connect(
  mapStateToProps,
  {
    setLoading,
  }
)(PageHost);
