import logger from '../../utils/logger';

const LOG_TAG = 'api-mock.user';
export default {
  get(userId, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'get', userId);
    setTimeout(() => {
      onResponseCb({
        data: {}
      });
    }, 800);
  },
  put(userId, payload, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'put', userId, payload);
    setTimeout(() => {
      onResponseCb({
        data: {}
      });
    }, 800);
  },
  putCarPlate(userId, payload, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'putCarPlate', userId, payload);
    setTimeout(() => {
      onResponseCb({
        data: []
      });
    }, 800);
  },
  postStartParking(userId, payload, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'postStartParking', userId, payload);
    setTimeout(() => {
      onResponseCb({
        data: []
      });
    }, 800);
  },
  putExtendParking(userId, parkId, payload, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'putExtendParking', userId, parkId, payload);
    setTimeout(() => {
      onResponseCb({
        data: {}
      });
    }, 800);
  },
  getParkingHistory(userId, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'getParkingHistory', userId);
    setTimeout(() => {
      onResponseCb({
        data: []
      });
    }, 800);
  },
  getLastParkingHistory(userId, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'getLastParkingHistory', userId);
    setTimeout(() => {
      onResponseCb({
        data: []
      });
    }, 800);
  },
  postPaymentMethod(userId, payload, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'postPaymentMethod', userId, payload);
    setTimeout(() => {
      onResponseCb({
        data: {}
      });
    }, 800);
  },
  getPaymentMethods(userId, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'getPaymentMethods', userId);
    setTimeout(() => {
      onResponseCb({
        data: []
      });
    }, 800);
  },
  delPaymentMethod(userId, pId, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'delPaymentMethod', userId, pId);
    setTimeout(() => {
      onResponseCb({
        data: {}
      });
    }, 800);
  }
};
