import React, {Component} from 'react';
import logger from '../../utils/logger';
import {connect} from 'react-redux';
import {setLoading, setNotification} from '../../store/actions';
import StateInfo from "../../store/states";
import syncParkSlots from '../../handlers/syncAdminParkSlots';
import dataHelper from "../../utils/dataHelper";
import slotHelper from '../../utils/slotHelper';

const LOG_TAG = 'VisitorParking';
const MIN_VISITOR_PARKING = 30;
const MAX_VISITOR_PARKING = 60 * 2;
class VisitorParking extends Component {
  constructor(props) {
    super(props);
    // init and sync
    this.state = {
      siteInfo: null,

      name: '',
      carPlate: '',

      enableParkSlot: false,
      parkSlots: [],
      parkSlotSelected: 'select',
      availableTime: 0,
      parkSlotTimeAvailable: null,

      parkingDurations: [],
      parkingDurationSelected: 'select',
      minParking: MIN_VISITOR_PARKING,
      maxParking: MAX_VISITOR_PARKING,
    };
  }
  componentDidMount() {
    this.resetState();
  }
  componentDidUpdate(oldProps) {
    const newProps = this.props;
    if (oldProps.siteInfo !== newProps.siteInfo) {
      this.resetState();
    }
  }
  resetState() {
    logger.d(LOG_TAG, 'resetState');
    const siteInfo = this.props.siteInfo;
    if (siteInfo) {
      this.setState({
        siteInfo: siteInfo,
        name: '',
        carPlate: '',
        enableParkSlot: false,
        parkingDurations: [],
        parkingDurationSelected: 'select',
        minParking: siteInfo.min_duration,
        maxParking: siteInfo.max_duration,
      });
      this.resetParkSlots(siteInfo.group_id, siteInfo.site_id)
    }
  }
  resetParkSlots(groupId, siteId) {
    if (groupId && siteId) {
      syncParkSlots.execute(
        groupId,
        siteId,
        (result, value) => {
          const slots = [];
          if (result) {
            value.forEach((slot) => {
              if (slotHelper.isVisibleToVisitor(slot) === true) {
                slots.push(slot);
              }
            });
            logger.d(LOG_TAG, 'resetParkSlots', result, value.length, slots.length);
          }
          this.setState({
            parkSlots: slots,
            parkSlotSelected: 'select',
            availableTime: 0,
            parkSlotTimeAvailable: null,
          });
        }
      )
    }
  }
  render() {
    return (
      <div className="container mt-3">
        {/* SignIn section */}
        <section>
          <section className="row justify-content-center mt-5 p-2">
            <img
              className="app__img-120"
              src={this.state.siteInfo ? this.state.siteInfo.logo_url : ''}
              alt="logo"
            />
          </section>
          <section className="row justify-content-center p-2">
            <h4>
              {this.state.siteInfo ?
                this.state.siteInfo.name
                :
                'Hello World 2-2'}
            </h4>
          </section>
          <section className="row justify-content-center p-1">
            <h4>
              {process.env.REACT_APP_IS_PRODUCTION === 'true'
                ? 'Visitor Parking Sign-in'
                : 'Hello World 2-3'}
            </h4>
          </section>

          {/* user inputs */}
          <div className="p-3" />
          <section>
            <div className="m-auto" style={{ width: '100%', maxWidth: '18rem' }}>
              <div className="form-group">
                <label htmlFor="">Full Name</label>
                <input type="text" className="form-control"
                       value={this.state.name}
                       onChange={this.onChangeName}/>
              </div>
              <div className="form-group">
                <label htmlFor="">Car Registration</label>
                <input type="text" className="form-control"
                       value={this.state.carPlate}
                       maxLength={6}
                       onChange={this.onChangeCarPlate}/>
              </div>
              <div className="form-group">
                <label htmlFor="">Park Space</label>
                <select
                  className="form-control"
                  value={this.state.parkSlotSelected}
                  onChange={this.onSelectParkSlot}
                  disabled={!this.state.enableParkSlot}>
                  <option value="select">Select Space</option>
                  {
                    this.state.parkSlots.map((slot, index) => (
                      <option
                        key={index}
                        value={index}>{slot.number}</option>
                    ))
                  }
                </select>
                {
                  this.state.parkSlotTimeAvailable ?
                    <div>
                      {
                        this.state.parkSlotTimeAvailable.err_message ?
                          <small className="clr-warning">{this.state.parkSlotTimeAvailable.err_message}</small>
                          :
                          <small>{this.state.parkSlotTimeAvailable.message}</small>
                      }
                    </div>
                    : <small className="clr-secondary">Availability: Select a space</small>
                }
              </div>
              <div className="form-group">
                <label htmlFor="">Duration (minutes)</label>
                <select
                  className="form-control"
                  value={this.state.parkingDurationSelected}
                  onChange={this.onSelectParkingDuration}
                  disabled={this.state.parkingDurations.length === 0 ? true : false}>
                  <option value="select">Select Duration</option>
                  {
                    this.state.parkingDurations.map((duration, index) => (
                      <option
                        key={index}
                        value={index}>{duration.text}</option>
                    ))
                  }
                </select>
              </div>
              <div className="form-group">
                <button
                  className={`${this.validate() ?'btn':'btn__disabled'} btn-sign-in`}
                  onClick={this.onClickNext}>
                  OK
                </button>
              </div>
            </div>
          </section>
        </section>
      </div>
    );
  }

  onChangeName = e => {
    const value = e.target.value;
    logger.d(LOG_TAG, 'onChangeName', value);
    const newState = {
      name: value,
    }
    const enableParkSlot = value.length > 0 && this.state.carPlate.length > 0;
    if (this.state.enableParkSlot !== enableParkSlot) {
      newState.enableParkSlot = enableParkSlot;
      if (enableParkSlot) {
        this.resetParkSlots(this.state.siteInfo.site_id)
      }
    }
    this.setState(newState)
  };
  onChangeCarPlate = e => {
    const value = e.target.value;
    logger.d(LOG_TAG, 'onChangeCarPlate', value);
    const newState = {
      carPlate: value.toUpperCase(),
    }
    const enableParkSlot = value.length > 0 && this.state.name.length > 0;
    if (this.state.enableParkSlot !== enableParkSlot) {
      newState.enableParkSlot = enableParkSlot;
      if (enableParkSlot) {
        this.resetParkSlots(this.state.siteInfo.site_id)
      }
    }
    this.setState(newState)
  };
  onSelectParkSlot = e => {
    const selected = e.target.value;
    logger.d(LOG_TAG, 'onSelectParkSlot', selected);

    this.setState({
      parkSlotSelected: selected,
      parkSlotTimeAvailable: null,
      availableTime: 0,
      parkingDurations: [],
      parkingDurationSelected: 'select',
    });
    if (selected !== 'select') {
      const parkSlot = this.state.parkSlots[selected];
      window.webapi.Query.queryAvailableTime(
        parkSlot.slot_id,
        (response) => {
          const data = response.data;
          let availableTime = '';
          const time = window.moment.duration(data.available_time, "minutes").format("h:mm");
          logger.d(LOG_TAG, 'onSelectParkSlot', 'onResponse', time);

          const timeSplit = time.split(':');
          if (timeSplit.length === 1) {
            availableTime = `${timeSplit[0]} minutes`
          } else {
            availableTime = `${timeSplit[0]} hour(s) and  ${timeSplit[1]} minute(s)`
          }
          const parkingDurations = [];
          let timeCountdown = data.available_time;
          let durationIndex = 0;
          const minParking = this.state.minParking;
          const maxParking = this.state.maxParking;
          while ( (timeCountdown - minParking) > 0 && maxParking > durationIndex) {
            timeCountdown -= minParking;
            durationIndex += minParking;
            parkingDurations.push({
              text: durationIndex,
              value: durationIndex,
            })
          }
          this.setState({
            availableTime: data.available_time,
            parkSlotTimeAvailable: {
              message: `Availability: ${availableTime}`,
            },
            parkingDurations: parkingDurations
          });
        },
        (error) => {
          const data = error.data;
          logger.d(LOG_TAG, 'onSelectParkSlot', 'onError', data);
          this.setState({
            availableTime: 0,
            parkSlotTimeAvailable: {
              err_message: data.err_message
            },
          });
        }
      );
    }
  };
  onSelectParkingDuration = e => {
    const selected = e.target.value;
    logger.d(LOG_TAG, 'onSelectParkSlot', selected);

    this.setState({
      parkingDurationSelected: selected,
    });
  };
  validate() {
    if (
      this.state.name &&
      this.state.carPlate &&
      this.state.parkSlotSelected !== 'select' &&
      this.state.parkingDurationSelected !== 'select') {
      return true;
    }
    return false;
  };
  onClickNext = () => {
    logger.d(LOG_TAG, 'onClickNext');
    const validated = this.validate();
    if (validated) {
      this.props.setLoading(StateInfo.LoadingInfo.format(true, 'Creating new reservation'));

      const now = window.moment.now();
      const date = window.moment(now).format('YYYY-MM-DD');
      const timeStart = window.moment(now).format('HH:mm');
      const timeEnd = window.moment(now).add(this.state.parkingDurations[this.state.parkingDurationSelected].value, 'minutes').format('HH:mm');

      const reservation = StateInfo.ReservationInfo
      .create(this.state.name, this.state.carPlate.toLowerCase())
      .setDuration(
        date, timeStart,
        date, timeEnd)
      .enableImmediateReservation();
      logger.d(LOG_TAG, 'onClickNext', date, timeStart, timeEnd, reservation);
      window.webapi.Group.postSlotReservation(
        this.state.siteInfo.group_id,
        this.state.siteInfo.site_id,
        this.state.parkSlots[this.state.parkSlotSelected].slot_id,
        reservation,
        (response) => {
          const data = response.data;
          logger.d(LOG_TAG, 'onClickNext', 'onResponse', data);
          setTimeout(() => {
            this.props.setLoading(StateInfo.LoadingInfo.format(false));
            this.props.setNotification(
              StateInfo.NotificationInfo.format('success', null, 'Reservation success')
            );
            this.resetState();
          }, 1600);
        },
        (error) => {
          const data = error.data;
          logger.d(LOG_TAG, 'onClickNext', 'onError', data);
          setTimeout(() => {
            const notify = dataHelper.formatError('Reservation', data);
            this.props.setLoading(StateInfo.LoadingInfo.format(false));
            this.props.setNotification(
              StateInfo.NotificationInfo.format('danger', notify.title, notify.message)
            );
          }, 800);
        }
      )
    }
  };
}

// connect and map actions
export default connect(
  null,
  {
    setLoading,
    setNotification,
  }
)(VisitorParking);