import React, {Component} from 'react';
import connect from 'react-redux/es/connect/connect';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faCrown } from '@fortawesome/free-solid-svg-icons'
import AnimComponent from '../../../components/anim/animComponent';
import syncUserParking from '../../../handlers/syncUserParking';
import {setLoading, setNotification, setNotifyStartParking} from '../../../store/actions';
import logger from '../../../utils/logger';
import dataHelper from "../../../utils/dataHelper";

const LOG_TAG = 'SessionProfile';

class SessionProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: null,
      lastSessionInfo: null, // active parking session
      vipSites: null,
      userParkInfo: null,
      lastProfile: null,
    };
  }
  componentDidMount() {
    logger.d(LOG_TAG, 'componentDidMount');
    this.resetState();
  }
  componentDidUpdate(oldProps) {
    logger.d(LOG_TAG, 'componentDidUpdate');
    const newProps = this.props;
    if (oldProps.userInfo !== newProps.userInfo ||
      oldProps.vipSites !== newProps.vipSites ||
      oldProps.lastSessionInfo !== newProps.lastSessionInfo) {
      this.resetState();
    }
  }
  resetState() {
    const userInfo = this.props.userInfo;
    const lastSessionInfo = this.props.lastSessionInfo;
    const vipSites = this.props.vipSites;
    const lastProfile = this.props.lastProfile;

    logger.d(LOG_TAG, 'resetState', userInfo, lastSessionInfo, vipSites);
    if (userInfo) {
      syncUserParking.execute(
        userInfo,
        (result, value) => {
          logger.d(LOG_TAG, 'resetState', 'syncUserParking', result, value, lastSessionInfo);
          const newState = {
            userInfo: userInfo,
            lastSessionInfo: lastSessionInfo,
            vipSites: vipSites,
            userParkInfo: (result && value.car_plate === lastSessionInfo.car_plate) ? value : null,
            lastProfile: lastProfile,
          };
          this.setState(newState);
        }
      );
    }

  };
  render() {
    return (
      <AnimComponent>
        {
          (this.state.userInfo && this.state.lastSessionInfo) ? (
            <form>
              <div className="card mt-3">
                <div className="card-body">
                  {/*Parking session*/}
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="">
                          <strong>Last Parking Session:</strong>
                          {
                            (this.state.userParkInfo) && (
                              <strong className="clr-warning"> (Active session)</strong>
                            )
                          }
                        </label>
                        <div className="row">
                          <div className="col-md-12 mb-1 p-1">
                            <div className="form-control">
                              <label htmlFor=""><strong>Site: </strong></label>
                              <small className="float-right mt-1">
                                {
                                  (this.state.vipSites &&
                                    this.state.vipSites.find((site) => site.name === this.state.lastSessionInfo.site_name)
                                  ) && <FontAwesomeIcon icon={faCrown} color="red"></FontAwesomeIcon>
                                }
                                {
                                ` ${this.state.lastSessionInfo.site_name} (${this.state.lastSessionInfo.slot_number})`
                                }
                              </small>
                            </div>
                          </div>
                        </div>
                        {
                          (this.state.userParkInfo) ? (
                            <div className="row">
                              <div className="col-md-6 mb-1 p-1">
                                <div className="form-control">
                                  <label htmlFor=""><strong>Started at: </strong></label>
                                  <small className="float-right  mt-1 clr-warning">{` ${dataHelper.formatTimestampFromSec(this.state.userParkInfo.time_start)}`}</small>
                                </div>
                              </div>
                              <div className="col-md-6 mb-1 p-1">
                                <div className="form-control">
                                  <label htmlFor=""><strong>End at: </strong></label>
                                  <small className="float-right  mt-1 clr-warning">{` ${dataHelper.formatTimestampFromSec(this.state.userParkInfo.time_end)}`}</small>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="row">
                              <div className="col-md-6 mb-1 p-1">
                                <div className="form-control">
                                  <label htmlFor=""><strong>Started at: </strong></label>
                                  <small className="float-right mt-1">{` ${dataHelper.formatTimestampFromString(this.state.lastSessionInfo.start_parking_at)}`}</small>
                                </div>
                              </div>
                              <div className="col-md-6 mb-1 p-1">
                                <div className="form-control">
                                  <label htmlFor=""><strong>Duration: </strong></label>
                                  <small className="float-right mt-1">{` ${dataHelper.formatParkingDurationFromMin(this.state.lastSessionInfo.duration)}`}</small>
                                </div>
                              </div>
                            </div>
                          )
                        }
                        {
                          (!this.state.userParkInfo && this.state.lastSessionInfo.extend_at) && (
                            <div className="row">
                              <div className="col-md-6 mb-1 p-1">
                                <div className="form-control">
                                  <label htmlFor=""><strong>Extended at: </strong></label>
                                  <small className="float-right mt-1">{` ${dataHelper.formatTimestampFromString(this.state.lastSessionInfo.extend_at)}`}</small>
                                </div>
                              </div>
                              <div className="col-md-6 mb-1 p-1">
                                <div className="form-control">
                                  <label htmlFor=""><strong>Extended by: </strong></label>
                                  <small className="float-right mt-1">{` ${dataHelper.formatParkingDurationFromMin(this.state.lastSessionInfo.extend_duration)}`}</small>
                                </div>
                              </div>
                            </div>
                          )
                        }
                        {
                          (!this.state.userParkInfo && this.state.lastSessionInfo.stop_parking_at) && (
                            <div className="row">
                              <div className="col-md-6 mb-1 p-1">
                                <div className="form-control">
                                  <label htmlFor=""><strong>Stopped at: </strong></label>
                                  <small className="float-right mt-1">{` ${dataHelper.formatTimestampFromString(this.state.lastSessionInfo.stop_parking_at)}`}</small>
                                </div>
                              </div>
                            </div>
                          )
                        }
                        {
                          (this.state.lastSessionInfo.admin) && (
                            <div className="row">
                              <div className="col-md-6 mb-1 p-1">
                                <div className="form-control">
                                  <label htmlFor=""><strong>Session By: </strong></label>
                                  <small className="float-right mt-1">{` ${this.state.lastSessionInfo.admin}`}</small>
                                </div>
                              </div>
                            </div>
                          )
                        }
                      </div>
                    </div>
                  </div>
                  {/* user profile*/}
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor=""><strong>{`${this.state.userInfo.name} (${this.state.userInfo.user_id})`}</strong></label>
                        <div className="row">
                          <div className="col-md-6 mb-1 p-1">
                            <div className="form-control">
                              <label htmlFor=""><strong>E-mail: </strong></label>
                              <small className="float-right mt-1">{` ${this.state.userInfo.email}`}</small>
                            </div>
                          </div>
                          <div className="col-md-6 mb-1 p-1">
                            <div className="form-control">
                              <label htmlFor=""><strong>Phone number: </strong></label>
                              <small className="float-right mt-1">{` ${this.state.userInfo.phone}`}</small>
                            </div>
                          </div>
                          {
                            this.state.lastProfile &&
                            <div className="col-md-6 mb-1 p-1">
                              <div className="form-control">
                                <label htmlFor=""><strong>App Version: </strong></label>
                                <small className="float-right mt-1">{` ${this.state.lastProfile.app_version}`}</small>
                              </div>
                            </div>
                          }

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          ) : <div></div>
        }
      </AnimComponent>
    );
  }
}
export default connect(
  null,
  {
    setLoading,
    setNotification,
    setNotifyStartParking
  }
)(SessionProfile);


