export default {
  name: '',
  path: '',
  subPath: '',
  format(name, path, subPath) {
    return {
      name: name,
      path: path,
      subPath: subPath
    };
  }
};
