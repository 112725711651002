export default {
  parking_id: 0,
  full_name: '',
  car_plate: '',
  owner_start: '', // reservation
  owner_end: '', // reservation
  time_start: 0, // user parking
  time_end: 0, // user parking
  actual_time_end: 0, // user park end time
  is_immediate_reservation: false,
/*
"full_name":"Test Reservation - Sam Chan",
"owner_start": "2019-05-14T11:00:00+12:00",
"owner_end":"2019-05-14T12:00:00+12:00",
"car_plate":"KAS849"
 */
  create(fullName, carPlate) {
    return {
      full_name: fullName,
      car_plate: carPlate,
      setDuration(startDate, startTime, endDate, endTime) {
        this.owner_start = window.moment(`${startDate}T${startTime}:00`).format();
        this.owner_end = window.moment(`${endDate}T${endTime}:00`).format();
        return this;
      },
      setTime(start, end) {
        this.time_start = start;
        this.time_end = end;
        return this;
      },
      enableImmediateReservation() {
        this.is_immediate_reservation = true;
        return this;
      }
    };
  },
};
