import Binder from './index';
import logger from '../../utils/logger';
import localStorageHelper from '../../utils/localStorageHelper';

const LOG_TAG = 'binder.admin';
export default {
  postUser(payload, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'postUser', payload);

    if (localStorageHelper.getIsMock()) {
      Binder.WebApiMock().Admin.postUser(payload, onResponseCb, onErrorCb);
    } else {
      Binder.WebApi().Admin.postUser(payload, onResponseCb, onErrorCb);
    }
  },
  getUser(userId, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'getUser', userId);
    if (localStorageHelper.getIsMock()) {
      Binder.WebApiMock().Admin.getUser(userId, onResponseCb, onErrorCb);
    } else {
      Binder.WebApi().Admin.getUser(userId, onResponseCb, onErrorCb);
    }
  },
  putUser(userId, payload, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'putUser', userId);
    if (localStorageHelper.getIsMock()) {
      Binder.WebApiMock().Admin.putUser(userId, payload, onResponseCb, onErrorCb);
    } else {
      Binder.WebApi().Admin.putUser(userId, payload, onResponseCb, onErrorCb);
    }
  },
  delUser(userId, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'delUser', userId);
    if (localStorageHelper.getIsMock()) {
      Binder.WebApiMock().Admin.delUser(userId, onResponseCb, onErrorCb);
    } else {
      Binder.WebApi().Admin.delUser(userId, onResponseCb, onErrorCb);
    }
  },
  getLocalUsers(onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'getLocalUsers');
    if (localStorageHelper.getIsMock()) {
      Binder.WebApiMock().Admin.getLocalUsers(onResponseCb, onErrorCb);
    } else {
      Binder.WebApi().Admin.getLocalUsers(onResponseCb, onErrorCb);
    }
  },
  postGroup(payload, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'postGroup', payload);
    if (localStorageHelper.getIsMock()) {
      Binder.WebApiMock().Admin.postGroup(payload, onResponseCb, onErrorCb);
    } else {
      Binder.WebApi().Admin.postGroup(payload, onResponseCb, onErrorCb);
    }
  },
  /*
  getGroup(groupId, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'getGroup', groupId);
    if (localStorageHelper.getIsMock()) {
      Binder.WebApiMock().Admin.getGroup(groupId, onResponseCb, onErrorCb);
    } else {
      Binder.WebApi().Admin.getGroup(groupId, onResponseCb, onErrorCb);
    }
  },
  */
  putGroup(groupId, payload, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'putGroup', groupId, payload);
    if (localStorageHelper.getIsMock()) {
      Binder.WebApiMock().Admin.putGroup(groupId, payload, onResponseCb, onErrorCb);
    } else {
      Binder.WebApi().Admin.putGroup(groupId, payload, onResponseCb, onErrorCb);
    }
  },
  postGroupSite(groupId, payload, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'postGroupSite', groupId, payload);
    if (localStorageHelper.getIsMock()) {
      Binder.WebApiMock().Admin.postGroupSite(groupId, payload, onResponseCb, onErrorCb);
    } else {
      Binder.WebApi().Admin.postGroupSite(groupId, payload, onResponseCb, onErrorCb);
    }
  },
  delGroupSite(groupId, siteId, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'delGroupSite', groupId, groupId, siteId);
    if (localStorageHelper.getIsMock()) {
      Binder.WebApiMock().Admin.delGroupSite(groupId, siteId, onResponseCb, onErrorCb);
    } else {
      Binder.WebApi().Admin.delGroupSite(groupId, siteId, onResponseCb, onErrorCb);
    }
  },
  searchEmail(email, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'searchEmail', email);
    if (localStorageHelper.getIsMock()) {
      Binder.WebApiMock().Admin.searchEmail(email, onResponseCb, onErrorCb);
    } else {
      Binder.WebApi().Admin.searchEmail(email, onResponseCb, onErrorCb);
    }
  },
  searchCarPlate(carPlate, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'searchCarPlate', carPlate);
    if (localStorageHelper.getIsMock()) {
      Binder.WebApiMock().Admin.searchCarPlate(carPlate, onResponseCb, onErrorCb);
    } else {
      Binder.WebApi().Admin.searchCarPlate(carPlate, onResponseCb, onErrorCb);
    }
  }
};
