export default {
  parking_id: 0,
  site_id: 0,
  slot_id: 0,
  time_start: 0,
  time_end: 0,
  car_plate: '',
  address: '',
  // model helper
};

