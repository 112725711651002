import React, {Component} from 'react';
import {connect} from 'react-redux';
import {setLoading, setNotification} from '../store/actions';
import Modal from 'react-bootstrap/Modal';
import logger from '../utils/logger';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import StateInfo from '../store/states';
import dataHelper from "../utils/dataHelper";
import updateParkSiteInfo from '../handlers/updateParkSiteInfo';

const LOG_TAG = 'DlgAddVIP';
class DlgAddVIP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      siteInfo: null,

      carPlate: '',
    };
  }
  componentDidMount() {
    logger.d(LOG_TAG, 'componentDidMount');
    // format window options
    this.resetState();
  };
  componentDidUpdate(oldProps) {
    const newProps = this.props;
    if (oldProps.siteInfo !== newProps.siteInfo) {
      logger.d(LOG_TAG, 'componentDidUpdate', newProps.siteInfo);
      this.resetState();
    }
  }
  resetState() {
    this.setState({
      siteInfo: this.props.siteInfo,

      name: '',
      carPlate: '',
    });
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        backdrop={'static'}
        onHide={this.onClickHide}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add {this.state.siteInfo && (
              <span>{this.state.siteInfo.name}</span>
          )} VIP
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="">Car Registration</label>
                <input type="text" className="form-control"
                       value={this.state.carPlate}
                       maxLength={6}
                       onChange={this.onChangeCarPlate}/>
              </div>
            </div>
            <div className="col-md-12">
              <button className={`${this.validate()?'btn-invert':'btn__disabled'}
                            btn-default-small float-right`}
                      onClick={this.onClickAddVIP}>
                <FontAwesomeIcon icon="plus"></FontAwesomeIcon> Add
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
  onClickHide = e => {
    logger.d(LOG_TAG, 'onClickHide');
    if (this.props.onHide) {
      this.resetState();
      this.props.onHide();
    }
  };
  onChangeCarPlate = e => {
    const value = e.target.value;
    logger.d(LOG_TAG, 'onChangeCarPlate', value);
    this.setState({
      carPlate: value.toUpperCase()
    });
  };
  validate() {
    if (this.state.carPlate) {
      return true;
    }
    return false;
  };
  onClickAddVIP = () => {
    const validated = this.validate();
    logger.d(LOG_TAG, 'onClickAddVIP', validated, this.state.siteInfo);
    if (validated) {
      this.props.setLoading(StateInfo.LoadingInfo.format(true, 'Add new VIP'));
      const siteInfo = this.state.siteInfo;
      const vipPlates = [];
      if (siteInfo.vip_plates) {
        siteInfo.vip_plates.forEach((plate) => {
          vipPlates.push(plate.toLowerCase());
        });
      }
      vipPlates.push(this.state.carPlate.toLowerCase());
      siteInfo.vip_plates = vipPlates;
      updateParkSiteInfo.execute(
        siteInfo.group_id,
        siteInfo.site_id,
        siteInfo,
        (result, values) => {
          this.onHandleVIP(result, values);
        }
      );
    }
  };
  onHandleVIP(result, value) {
    logger.d(LOG_TAG, 'onHandleVIP', result, value);
    this.props.setLoading(StateInfo.LoadingInfo.format(false, ''));
    if (result) {
      if (this.props.onHide) {
        this.props.onHide(true, value);
      }
    } else {
      const notify = dataHelper.formatError('Add VIP', value);
      this.props.setNotification(
        StateInfo.NotificationInfo.format('danger', notify.title, notify.message)
      );
    }
  }
}
export default connect(
  null,
  {
    setLoading,
    setNotification,
  }
)(DlgAddVIP);


