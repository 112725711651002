import dataHelper from '../../utils/dataHelper';
export default function (copy) {
  let object;
  if (copy) {
    object = JSON.parse(JSON.stringify(copy));
  } else {
    object = {
      day: '', //Monday,
      windows: [],
    };
  }
  object.setDay = function setDay(day) {
    object.day = day;
    return object;
  }
  object.addWindow = function addWindow(window) {
    if (!object.windows) {
      object.windows = [];
    }
    object.windows.push(window);
    return object;
  };
  
  return object;
};

