import React from 'react';
import imgDashboardLogo from '../assets/images/logo.png';
import {slide as Menu} from 'react-burger-menu'
import {connect} from 'react-redux';
import {setAuth, setAuthUser, setAuthUserGroups, setUser} from '../store/actions';
import {withRouter} from 'react-router-dom';
import logger from "../utils/logger";
import Routes from '../routes';

const LOG_TAG = 'MobileMenu';
class MobileMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }
  render () {
    return (
      <div className="nav-mobile">
        <Menu right onStateChange={this.onMenuStateChange} isOpen={this.state.isOpen}>
          <div className="pl-4 pt-4 mb-5">
            <img
              style={{height: '3.2rem'}}
              src={
                imgDashboardLogo
              }
              alt="logo"/>
          </div>
          {
            this.props.naviOptions.options.map((option, index) => (
              <div
                className="bm-item-ext"
                key={index}>
                <div
                  style={{height: '3.98rem'}}
                  className="app-item-underline-border pt-3 pl-2">
                  <div
                    onClick={this.onClickItem.bind(this, option)}>
                    {option.name}
                  </div>
                </div>
              </div>

            ))
          }
          <div className="bm-item-ext">
            <div
              style={{height: '3.98rem'}}
              className="app-item-underline-border pt-3 pl-2" onClick={this.onClickLogout.bind(this, 'logout')}>Logout</div>
          </div>
          {/* version */}
          <div
            style={{height: '3.98rem'}}
            className="text-center pt-3 pl-2">
            <label style={{color: 'grey'}}>{`Ver: ${process.env.REACT_APP_VERSION}`}</label>
          </div>
        </Menu>
      </div>

    );
  }
  onClickItem = param => {
    logger.d(LOG_TAG, 'onClickItem', param);
    const onNaviHandler = this.props.naviOptions.handler;
    onNaviHandler(param.id);
    this.setState({
      isOpen: false,
    });
  };
  onMenuStateChange = value => {
    logger.d(LOG_TAG, 'onMenuStateChange', value.isOpen);
    this.setState({
      isOpen: value.isOpen,
    });
  };
  onClickLogout = param => {
    logger.d(LOG_TAG, 'onclickLogout', param);
    this.props.setAuth(null);
    this.props.setAuthUser(null);
    this.props.setAuthUserGroups(null);
    this.props.setUser(null);
    this.props.history.replace(Routes.login.path);
  };
}
// listens to state change
const mapStateToProps = state => {
  const tabInfo = state.tabInfo;
  return {
    tabInfo,
  };
};
// connect and map actions
export default withRouter(connect(
  mapStateToProps,
  {
    setAuth,
    setAuthUser,
    setAuthUserGroups,
    setUser,
  }
)(MobileMenu));

