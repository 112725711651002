export default {
  model_id: 0,
  device_eui: '',
  application_eui: '',
  application_key: '',
  // model helper
  create(deviceModelId, deviceEui, appKey, appEui) {
    return {
      model_id: deviceModelId,
      device_eui: deviceEui,
      application_key: appKey,
      application_eui: appEui,
    };
  },
  createLegacy(deviceEui, appKey, appEui) {
    return {
      device_eui: deviceEui,
      application_key: appKey,
      application_eui: appEui,
    };
  },
};

