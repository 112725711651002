import Auth from './auth';
import Admin from './admin';
import User from './user';
import Group from './group';
import Query from './query';

/* APIs go through binder first for redirect
 * */
export default {
  LOG_TAG: 'api-mock',
  Auth,
  Admin,
  User,
  Group,
  Query,
};
