import React, {Component} from 'react';
import AnimPage from '../../../components/anim/animPage';
import connect from 'react-redux/es/connect/connect';
import {setLoading, setNotification} from '../../../store/actions';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import DlgAddUser from '../../../dialog/dlgAddUser';
import Row4Col from '../../../components/row4Col';
import RowEmpty from '../../../components/rowEmpty';
import syncUser from '../../../handlers/syncUser';
import logger from '../../../utils/logger';
import dataHelper from '../../../utils/dataHelper';
import accessHelper from '../../../utils/accessHelper';
import localStorageHelper from "../../../utils/localStorageHelper";
import StateInfo from "../../../store/states";

const LOG_TAG = 'Users';
class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authUserInfo: null,
      users: [],
      enableAddUser: false,
      userInfoSelected: null,
    };
  }
  componentDidMount() {
    logger.d(LOG_TAG, 'componentDidMount');
    this.refreshUsers();
  }
  refreshUsers() {
    logger.d(LOG_TAG, 'refreshUsers');
    const authUserInfo = localStorageHelper.getAuthUserInfo();
    let users = [];
    window.webapi.Admin.getLocalUsers(
      (response) => {
        const data = response.data;
        if (data && data.length > 0) {
          const validUsers = data.filter((userInfo) => userInfo.user_id !== authUserInfo.user_id);
          users = validUsers;
        }
        logger.d(LOG_TAG, 'refreshUsers', 'onResponse', users.length);
        this.setState({
          authUserInfo: authUserInfo,
          users: users
        });
      },
      (error) => {
        const data = error.data;
        logger.d(LOG_TAG, 'refreshUsers', 'onError', data);
      }
    );
  }
  render() {
    return (
      <AnimPage>
        <div className="container-fluid p-0 mt-3">
          <div className="row app-main__content">
            <div className="col-md-12 app-main__content__card">
              {/* add user button */}
              <div className="row mt-3">
                <div className="col-md-12">
                  <div className="form-group">
                    <button className="btn btn-invert btn-default float-right" onClick={this.onClickAddUser}>
                      <FontAwesomeIcon icon="plus"></FontAwesomeIcon> New User
                    </button>
                  </div>
                </div>
              </div>
              <div className="card mt-3">
                <div className="card-body">
                  <div className="list-group">
                    {
                      this.state.users.length > 0 ?
                        this.state.users.map((user, index) => (
                          // You can render a <Route> in as many places
                          // as you want in your app. It will render along
                          // with any other <Route>s that also match the URL.
                          // So, a sidebar or breadcrumbs or anything else
                          // that requires you to render multiple things
                          // in multiple places at the same URL is nothing
                          // more than multiple <Route>s.
                          <Row4Col
                            key={index}
                            id={index}
                            index={index+1}
                            value1={`${user.first_name} ${user.last_name}`}
                            value2={user.email}
                            value3={`Mobile: ${user.phone}`}
                            value4={`Groups: ${user.groups.length}`}
                            onClick={this.onClickItem}
                            /*action1={<FontAwesomeIcon icon="pencil-alt" />}
                            onClickAction1={this.onClickItemAction1}*/
                            action1={
                              accessHelper.isSct(user) ? null : <FontAwesomeIcon icon="trash-alt" />
                            }
                            onClickAction1={
                              accessHelper.isSct(user) ? null : this.onClickItemDelete
                            }
                          >
                          </Row4Col>
                        ))
                        :
                        <RowEmpty value={"No users found."}></RowEmpty>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <DlgAddUser
            show={this.state.enableAddUser}
            userInfo={this.state.userInfoSelected}
            onHide={this.onHideAddUser}
            onSave={this.onSaveAddUser}/>
        </div>
      </AnimPage>
    );
  }
  onClickAddUser = () => {
    this.setState({
      enableAddUser: true,
      userInfoSelected: null,
    });
  };
  onHideAddUser = (reqUpdate = false) => {
    logger.d(LOG_TAG, 'onHideAddUser', reqUpdate);
    this.setState({
      enableAddUser: false,
      userInfoSelected: null,
    });
    if (reqUpdate) {
      syncUser.execute(
        this.state.authUserInfo.user_id,
        (userInfo, groups) => {
          this.refreshUsers();
        }
      )
    }
  };
  onSaveAddUser = param => {
    logger.d(LOG_TAG, 'onSaveAddUser', param);
    this.setState({
      enableAddUser: false,
      userInfoSelected: null,
    });
  };
  onClickItem = param => e => {
    logger.d(LOG_TAG, 'onClickItem', param);
    e.stopPropagation();
    this.setState({
      enableAddUser: true,
      userInfoSelected: this.state.users[param],
    });
  };
  onClickItemDelete = param => e => {
    logger.d(LOG_TAG, 'onClickItemDelete', param);
    e.stopPropagation();

    const selected = this.state.users[param];
    window.confirm(`Are you sure you wish to delete ${selected.email}?`) &&
    this.deleteUser(selected)
    //this.props.history.push(Routes.main[Routes.INDEX_PA].routes[1].path);
  };
  deleteUser = value => {
    logger.d(LOG_TAG, 'deleteItem', value);

    this.props.setLoading(StateInfo.LoadingInfo.format(true, `Deleting ${value.email}`));
    window.webapi.Admin.delUser(
      value.user_id,
      (response) => {
        setTimeout(() => {
          this.props.setLoading(StateInfo.LoadingInfo.format(false, ''));
          syncUser.execute(
            this.state.authUserInfo.user_id,
            (userInfo, groups) => {
              this.refreshUsers();
            }
          );
        }, 800);
      },
      (error) => {
        setTimeout(() => {
          const notify = dataHelper.formatError('User', error.data);
          this.props.setLoading(StateInfo.LoadingInfo.format(false, ''));
          this.props.setNotification(
            StateInfo.NotificationInfo.format('danger', notify.title, notify.message)
          );
        }, 800);
      }
    )
  };
}
export default connect(
  null,
  {
    setLoading,
    setNotification,
  }
)(Users);

