import logger from '../../utils/logger';

const LOG_TAG = 'api-mock.group';
export default {
  get(groupId, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'get', groupId);
    setTimeout(() => {
      onResponseCb({
        data: {}
      });
    }, 800);
  },
  getParkSites(groupId, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'getParkSites', groupId);
    setTimeout(() => {
      onResponseCb({
        data: []
      });
    }, 800);
  },
  getParkSiteInfo(groupId, siteId, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'getParkSiteInfo', groupId, siteId);
    setTimeout(() => {
      onResponseCb({
        data: {}
      });
    }, 800);
  },
  putParkSiteInfo(groupId, siteId, payload, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'putParkSiteInfo', groupId, siteId, payload);
    setTimeout(() => {
      onResponseCb({
        data: {}
      });
    }, 800);
  },
  postParkSiteSlot(groupId, siteId, payload, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'postParkSiteSlot', groupId, siteId);
    setTimeout(() => {
      onResponseCb({
        data: {}
      });
    }, 800);
  },
  getParkSlotInfo(groupId, siteId, slotId, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'getParkSlotInfo', groupId, siteId, slotId);
    setTimeout(() => {
      onResponseCb({
        data: {}
      });
    }, 800);
  },
  putParkSlotInfo(groupId, siteId, slotId, payload, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'putParkSlotInfo', groupId, siteId);
    setTimeout(() => {
      onResponseCb({
        data: {}
      });
    }, 800);
  },
  delParkSlotInfo(groupId, siteId, slotId, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'delParkSlotInfo', groupId, siteId, slotId);
    setTimeout(() => {
      onResponseCb({
        data: {}
      });
    }, 800);
  },
  putParkSlotSensor(groupId, siteId, slotId, payload, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'putParkSlotSensor', groupId, siteId, slotId);
    setTimeout(() => {
      onResponseCb({
        data: {}
      });
    }, 800);
  },
  getSlotReservations(groupId, siteId, slotId, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'getSlotReservations', groupId, siteId, slotId);
    setTimeout(() => {
      onResponseCb({
        data: []
      });
    }, 800);
  },
  postSlotReservation(groupId, siteId, slotId, payload, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'postSlotReservation', groupId, siteId, slotId);
    setTimeout(() => {
      onResponseCb({
        data: {}
      });
    }, 800);
  },
  delSlotReservation(groupId, siteId, slotId, reserveId, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'delSlotReservation', groupId, siteId, slotId, reserveId);
    setTimeout(() => {
      onResponseCb({
        data: {}
      });
    }, 800);
  }
};
