export default {
  type: null,
  headline: null,
  message: null,
  format(type, headline, message) {
    const result = {
      type: type
    };
    if (headline) {
      result.headline = headline;
    }
    if (message) {
      result.message = message;
    }
    return result;
  }
};
