import React, {Component} from 'react';
import imgLogo from '../assets/images/logo.png';
import {connect} from 'react-redux';
import {setAuth, setAuthUser, setAuthUserGroups, setLoading, setNotification} from '../store/actions';
import StateInfo from '../store/states';
import Routes from '../routes';
import logger from '../utils/logger';
import accessHelper from '../utils/accessHelper';
import localStorageHelper from '../utils/localStorageHelper';
import syncUser from '../handlers/syncUser';
import queryString from 'query-string';

const LOG_TAG = 'Login';
class Login extends Component {
  constructor(props) {
    super(props);
    // init and sync
    this.state = {
      username: '',
      password: '',
    };
    logger.d(LOG_TAG, 'constructor', this.props.authInfo);
  }
  componentDidMount() {
    const parsed = queryString.parse(this.props.location.search);
    if (accessHelper.resolveUrlTargetExpired(parsed)) {
      setTimeout(() => {
        this.props.setNotification(
          StateInfo.NotificationInfo.format('danger', null, 'Your session has expired, please sign-in again.')
        );
      }, 330);
    }
    const isVisitor = localStorageHelper.getIsVisitor();
    if (isVisitor) {
      localStorageHelper.setIsVisitor(null);
    }
  }
  render() {
    return (
      <div>
        <div className="container mt-3">
          {/*<section className="row justify-content-end">
          <div className="mr-4">Terms & Condition</div>
          <div className="mr-4">Help</div>
        </section>*/}

          <section className="row justify-content-center mt-5 p-2">
            <img
              className="app__img-120"
              src={process.env.REACT_APP_IS_PRODUCTION === 'true' ? imgLogo : ''}
              alt="logo"
            />
          </section>
          <section className="row justify-content-center p-3">
            <h4>
              {process.env.REACT_APP_IS_PRODUCTION === 'true'
                ? 'Welcome to KIWI PARKING'
                : 'Hello World'}
            </h4>
          </section>

          {/* user inputs */}
          <div className="p-3" />
          <form onSubmit={this.onClickSignIn}>
            <div className="m-auto" style={{ width: '100%', maxWidth: '18rem' }}>
              <div className="form-group">
                <label htmlFor="">Email address</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  value={this.state.username}
                  onChange={this.onChangeUsername}/>
              </div>
              <div className="form-group">
                <label htmlFor="">Password</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  value={this.state.password}
                  onChange={this.onChangePassword}/>

                {/*<input type="password" className="input-css" />*/}
              </div>

              {/*<div className="form-group">
              <label>
                <input type="checkbox" value="" /> Remember Me
              </label>
            </div>*/}

              <div className="form-group">
                <button className="btn btn-sign-in">
                  Sign In
                </button>
              </div>
            </div>
          </form>
        </div>
        <label className="mt-1 mr-1" style={{ position: 'fixed', bottom: '0', right: '0' }}>{`Ver: ${process.env.REACT_APP_VERSION}`}</label>
      </div>
    );
  }
  onChangeUsername = value => {
    this.setState({
      username: value.target.value,
    });
  };
  onChangePassword = value => {
    this.setState({
      password: value.target.value,
    });
  };
  validate() {
    if (this.state.username && this.state.password) {
      return true;
    } else {
      return false;
    }
  };
  onClickSignIn = e => {
    e.preventDefault();
    const isValid = this.validate();
    logger.d(LOG_TAG, 'onClickSignIn', isValid);
    if (!isValid) {
      this.props.setNotification(
        StateInfo.NotificationInfo.format('danger', null, 'Invalid username or password')
      );
    }
    else {
      this.props.setLoading(StateInfo.LoadingInfo.format(true, 'Please wait...'));
      let provider = 'local';
      if (StateInfo.AuthInfo.isMockIdentity(this.state.username)) {
        provider = 'mock';
      }
      window.webapi.Auth.postOAuth(
        provider, this.state,
        (response) => {
          this.onSignInSuccess(response.data);
        },
        (error) => {
          this.onSignInError();
        });
    }
  };
  onSignInSuccess(value) {
    logger.d(LOG_TAG, 'onSignInSuccess', value);
    this.props.setAuth(value);

    const userId = value.managed_users[0].user_id;
    logger.d(LOG_TAG, 'onSignInSuccess', 'syncUser', userId);
    syncUser.execute(userId, (userInfo, groups) => {
      if (userInfo) {
        this.onUserSuccess(userInfo, groups);
      } else {
        this.onUserError();
      }
    });
  };
  onSignInError(value) {
    setTimeout(() => {
      this.props.setLoading(StateInfo.LoadingInfo.format(false, ''));
      this.props.setNotification(
        StateInfo.NotificationInfo.format('danger', null, 'Unknown username or password')
      );
    }, 1200);
  };
  onUserSuccess(userInfo, groups) {
    logger.d(LOG_TAG, 'onUserSuccess', userInfo, groups);
    this.props.setAuthUser(userInfo);
    this.props.setAuthUserGroups(groups);
    setTimeout(() => {
      this.props.setLoading(StateInfo.LoadingInfo.format(false, ''));
      if (accessHelper.isSct(userInfo)) {
        this.props.history.replace(Routes.mainRoot.path+'?t=um');
      } else {
        this.props.history.replace(Routes.mainRoot.path+'?t=pa');
      }
    }, 1200);
  };
  onUserError(value) {
    setTimeout(() => {
      this.props.setLoading(StateInfo.LoadingInfo.format(false, ''));
      this.props.setNotification(
          StateInfo.NotificationInfo.format('danger', null, 'Error retrieving user info')
      );
    }, 1200);
  };
}

// connect and map actions
export default connect(
  null,
  {
    setAuth,
    setAuthUser,
    setAuthUserGroups,
    setLoading,
    setNotification,
  }
)(Login);
