import ApiHelper from '../apiHelper';
import logger from '../../utils/logger';

const TARGET_API = 'api/user';
const API_PARK_SITES = 'park-sites';
const API_CAR_PLATE = 'car_plate';
const API_PARK = 'park';
const API_EXTEND = 'extend';
const API_PAYMENT_METHOD = 'payment_method';
const API_PAYMENT_METHODS = 'payment_methods';

const LOG_TAG = 'api.user';
export default {
  getParkSiteList(uId, onResponseCb, onErrorCb) {
    const url = ApiHelper.createUrl(TARGET_API, API_PARK_SITES, uId);
    logger.d(LOG_TAG, 'getParkSiteList', url);

    ApiHelper.apiGet(url, onResponseCb, onErrorCb);
  },
  get(userId, onResponseCb, onErrorCb) {
    const url = ApiHelper.createUrl(TARGET_API, userId);
    logger.d(LOG_TAG, 'get', url);

    ApiHelper.apiGet(url, onResponseCb, onErrorCb);
  },
  put(userId, payload, onResponseCb, onErrorCb) {
    const url = ApiHelper.createUrl(TARGET_API, userId);
    logger.d(LOG_TAG, 'put', url);

    ApiHelper.apiPut(url, payload, onResponseCb, onErrorCb);
  },
  putCarPlate(userId, payload, onResponseCb, onErrorCb) {
    const url = ApiHelper.createUrl(TARGET_API, userId, API_CAR_PLATE);
    logger.d(LOG_TAG, 'putCarPlate', url);

    ApiHelper.apiPut(url, payload, onResponseCb, onErrorCb);
  },
  postStartParking(userId, payload, onResponseCb, onErrorCb) {
    const url = ApiHelper.createUrl(TARGET_API, userId, API_PARK);
    logger.d(LOG_TAG, 'postStartParking', url);

    ApiHelper.apiPost(url, payload, onResponseCb, onErrorCb);
  },
  putExtendParking(userId, parkId, payload, onResponseCb, onErrorCb) {
    const url = ApiHelper.createUrl(TARGET_API, userId, API_PARK, parkId, API_EXTEND);
    logger.d(LOG_TAG, 'putExtendParking', url);

    ApiHelper.apiPut(url, payload, onResponseCb, onErrorCb);
  },
  getParkingHistory(userId, onResponseCb, onErrorCb) {
    const url = ApiHelper.createUrl(TARGET_API, userId, API_PARK);
    logger.d(LOG_TAG, 'getParkingHistory', url);

    ApiHelper.apiGet(url, onResponseCb, onErrorCb);
  },
  getLastParkingHistory(userId, onResponseCb, onErrorCb) {
    const url = ApiHelper.createUrl(TARGET_API, userId, API_PARK+'?current=true');
    logger.d(LOG_TAG, 'getLastParkingHistory', url);

    ApiHelper.apiGet(url, onResponseCb, onErrorCb);
  },
  postPaymentMethod(userId, payload, onResponseCb, onErrorCb) {
    const url = ApiHelper.createUrl(TARGET_API, userId, API_PAYMENT_METHOD);
    logger.d(LOG_TAG, 'postPaymentMethod', url);

    ApiHelper.apiPost(url, payload, onResponseCb, onErrorCb);
  },
  getPaymentMethods(userId, onResponseCb, onErrorCb) {
    const url = ApiHelper.createUrl(TARGET_API, userId, API_PAYMENT_METHODS);
    logger.d(LOG_TAG, 'getPaymentMethods', url);

    ApiHelper.apiGet(url, onResponseCb, onErrorCb);
  },
  delPaymentMethod(userId, pId, onResponseCb, onErrorCb) {
    const url = ApiHelper.createUrl(TARGET_API, userId, API_PAYMENT_METHOD, pId);
    logger.d(LOG_TAG, 'delPaymentMethod', url);

    ApiHelper.apiDelete(url, onResponseCb, onErrorCb);
  }
};
