import Login from './pages/login';
import Visitor from './pages/visitor';
import Main from './pages/main';
import TermsPrivacy from './pages/termsPrivacy';
import NoMatch from './pages/noMatch';
import Redirect from './pages/redirect';
import UserManagement from './pages/main/userManagement';
import UMBookParking from './pages/main/userManagement/bookParking';
import UMParkingStatus from './pages/main/userManagement/parkingStatus';
import UMVipPlates from './pages/main/userManagement/vipPlates';
import UMProfile from './pages/main/userManagement/profile';
import ParkingAssets from './pages/main/parkingAssets';
import PAParkingSites from './pages/main/parkingAssets/parkingSites';
import PASiteSlots from './pages/main/parkingAssets/siteSlots';


import AccountUsers from './pages/main/accountUsers';
import AUAccount from './pages/main/accountUsers/account';
import AUUsers from './pages/main/accountUsers/users';

const Routes = {
  root: {
    path: '/',
    component: Redirect
  },
  termsPrivacy: {
    path: '/termsPrivacy',
    component: TermsPrivacy
  },
  login: {
    path: '/login',
    component: Login
  },
  visitor: {
    path: '/visitor',
    component: Visitor
  },
  noMatch: {
    path: '/noMatch',
    component: NoMatch
  },
  mainRoot: {
    path: '/main',
    component: Main
  },
  INDEX_UM: 0,
  INDEX_PA: 1,
  INDEX_AU: 2,
  main: [
    {
      id: 'um',
      name: 'User Management',
      path: '/main/userManagement',
      component: UserManagement,
      routes: [
        {
          id: 'ps',
          name: 'Parking Status',
          path: '/main/userManagement/parkingStatus',
          component: UMParkingStatus
        },
        {
          id: 'bp',
          name: 'Book Parking',
          path: '/main/userManagement/bookParking',
          component: UMBookParking
        },
        {
          id: 'pf',
          name: 'Profile',
          path: '/main/userManagement/profile',
          component: UMProfile
        },
        {
          id: 'vp',
          name: 'VIP Plates',
          path: '/main/userManagement/vipPlates',
          component: UMVipPlates
        },
      ]
    },
    {
      id: 'pa',
      name: 'Parking Assets',
      path: '/main/parkingAssets',
      component: ParkingAssets,
      routes: [
        {
          id: 'ps',
          name: 'Parking Sites',
          path: '/main/parkingAssets/parkingSites',
          component: PAParkingSites
        },
        {
          id: 'ss',
          name: 'All Slots',
          root: '/main/parkingAssets/siteSlots/',
          path: '/main/parkingAssets/siteSlots/:siteInfo',
          component: PASiteSlots
        },
      ]
    },
    {
      id: 'au',
      name: 'Account & Users',
      path: '/main/accountUsers',
      component: AccountUsers,
      routes: [
        {
          id: 'act',
          name: 'Account',
          path: '/main/accountUsers/account',
          component: AUAccount
        },
        {
          id: 'usr',
          name: 'Users',
          path: '/main/accountUsers/user',
          component: AUUsers
        },
      ]
    },
  ],
};

export default Routes;
