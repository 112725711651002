import logger from '../../utils/logger';
import dataHelper from "../../utils/dataHelper";

const LOG_TAG = 'AuthInfo';
export default {
  jwt: {
    token: null,
    expires: 0
  },
  identities: [],
  isMock: false,
  isValid(value) {
    if (value) {
      if (value.jwt.token) {
        const nowSeconds = dataHelper.formatSecFromMillisec(window.moment().valueOf());
        const isExpired = value.jwt.expires < nowSeconds;
        logger.d(LOG_TAG, 'isValid', isExpired, value.jwt.expires, nowSeconds);
        return !isExpired;
      }
    }
    return false;
  },
  isValidIdentity(value) {
    if (value) {
      if (value.identities.length > 0) {
        return true;
      }
    }
    return false;
  },
  format(token, expires, isMock) {
    return {
      jwt: {
        token: token,
        expires: expires
      },
      isMock: isMock
    };
  },
  formatEmpty() {
    return {
      jwt: {
        token: null,
        expires: 0
      },
      isMock: false
    };
  },
  // 'token-mock', 9999999999, true, true
  formatMock() {
    const mockIdentity = this.mockIdentity();
    return {
      jwt: {
        token: 'token-mock',
        expires: 9999999999
      },
      isMock: true,
      identities: [
        {
          user_id: mockIdentity.user_id,
          first_name: mockIdentity.first_name,
          last_name: mockIdentity.last_name,
          email: mockIdentity.email,
          phone: mockIdentity.phone,
        }
      ]
    };
  },
  isMockIdentity(value) {
    const mockIdentity = this.mockIdentity();
    if (mockIdentity.email === value) {
      return true;
    }
    return false;
  },
  mockIdentity() {
    return {
      user_id: 111111,
      first_name: 'mock',
      last_name: 'user',
      email: 'mock@gmail.com',
      phone: '123456789',
      password: 'mock123',
    };
  },
};
