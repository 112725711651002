import logger from '../utils/logger';

const LOG_TAG = 'updateParkSlotSensor';

export default {
  //
  // returns slotInfo and sensorInfo if successful
  //
  execute(groupId, siteId, slotId, sensorInfo, onCompleteCb) {
    window.webapi.Group.putParkSlotSensor(
      groupId,
      siteId,
      slotId,
      sensorInfo,
      (response) => {
        const data = response.data;
        logger.d(LOG_TAG, 'onResponse', data);
        if (onCompleteCb) {
          onCompleteCb(true, data);
        }
      },
      (error) => {
        const data = error.data;
        logger.d(LOG_TAG, 'onError', data);
        if (onCompleteCb) {
          onCompleteCb(false, data);
        }
      }
    )
  },
};
