import React, {Component} from 'react';
import connect from 'react-redux/es/connect/connect';
import searchCarPlate from '../handlers/searchCarPlate';
import logger from '../utils/logger';

const LOG_TAG = 'CardSearchVipPlate';
class CardSearchVipPlate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchFor: '',
      searchError: '',
      hasValidSearch: false,
    };
  }
  componentDidMount() {
  }
  render() {
    return (
      <div className="card">
        <div className="card-body">
          <div className="row">
            <form onSubmit={this.onClickSearch}
                   className="col-md-12">
              <div className="form-group">
                <label htmlFor="">Search by car registration</label>
                <div className="d-flex">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Car Registration"
                    value={this.state.searchFor}
                    maxLength={6}
                    onChange={this.onChangeSearchFor}
                    disabled={this.state.hasValidSearch ? true : false}/>
                  <button
                    className="btn ml-2">
                    { this.state.hasValidSearch ? 'Reset ' : 'Search'}
                  </button>
                </div>
                {
                  this.state.searchError ?
                    <small className="form-text clr-warning">{this.state.searchError}
                    </small>
                    : null
                }
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
  resetSearch(searchFor, error) {
    this.setState({
      searchFor: searchFor,
      searchError: error,
      hasValidSearch: false,
    });
  }
  onChangeSearchFor = value => {
    this.setState({
      searchFor: value.target.value.toUpperCase(),
      searchError: '',
    });
  };
  onClickSearch = e => {
    e.preventDefault();
    if (this.state.hasValidSearch) {
      // reset
      this.resetSearch('', '')
      this.props.onResult(null);
    } else {
      const searchFor = this.state.searchFor;
      logger.d(LOG_TAG, 'onClickSearch', searchFor);
      if (searchFor) {
        this.setState({
          hasValidSearch: true,
        });
        searchCarPlate.searchViaSites(
          searchFor,
          (result, values) => {
            logger.d(LOG_TAG, 'result', result, values);
            if (result && values.length > 0) {
              const vipSites = [];
              values.forEach((site, index) => {
                vipSites.push({
                  name: site.name
                })
              });
              this.props.onResult(vipSites);
            } else {
              this.resetSearch(
                searchFor,
                'No results found')
            }
          }
        );
      } else {
        this.resetSearch(
          searchFor,
          'Invalid car registration')
      }
    }
  };
}

// listens to state change
const mapStateToProps = state => {
  const userInfo = state.userInfo;
  return {
    userInfo,
  };
};
export default connect(
  mapStateToProps
)(CardSearchVipPlate);
