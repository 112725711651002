import React, { Component } from 'react';
/* helper */

class BodyHost extends Component {
  componentDidMount() {}
  render() {
    return (
      <div className="container-fluid">
        <div className="row app-main__body">
          <div className="col-md-2 pl-0 app-main__body-navi navi-side-menu-link-max-width">
            {this.props.navi}
          </div>
          <div className="col-md-10 app-main__body-content">
            {this.props.desc ? this.props.desc : null}
            {this.props.content}
          </div>
        </div>
      </div>
    );
  }
}

export default BodyHost;
