import React, {Component} from 'react';
import Routes from '../../routes';
import BodyHost from '../../components/bodyHost';
import logger from '../../utils/logger';
import Nav from 'react-bootstrap/Nav';
import ParkingStatus from './userManagement/parkingStatus';
import BookParking from './userManagement/bookParking';
import Profile from './userManagement/profile';
import VipPlates from './userManagement/vipPlates';
import localStorageHelper from '../../utils/localStorageHelper';
import accessHelper from '../../utils/accessHelper';
/* helper */

const LOG_TAG = 'UserManagement';
class UserManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuPs: Routes.main[Routes.INDEX_UM].routes[0],
      menuBp: Routes.main[Routes.INDEX_UM].routes[1],
      menuPf: Routes.main[Routes.INDEX_UM].routes[2],
      menuVp: Routes.main[Routes.INDEX_UM].routes[3],
      options: [],
      selectedMenu: '',
    };
    /*const authUserInfo = localStorageHelper.getAuthUserInfo();
    if (accessHelper.isInternalTester(authUserInfo)) {
      this.state.options.push({
        id: this.state.menuPs.id,
        name: this.state.menuPs.name,
      });
    }*/
    this.state.options.push({
      id: this.state.menuPs.id,
      name: this.state.menuPs.name,
    });
    this.state.options.push({
      id: this.state.menuBp.id,
      name: this.state.menuBp.name,
    });
    this.state.options.push({
      id: this.state.menuPf.id,
      name: this.state.menuPf.name,
    });
    this.state.options.push({
      id: this.state.menuVp.id,
      name: this.state.menuVp.name,
    });
    this.state.selectedMenu = this.state.options[0].id;
  }
  componentDidMount() {
  }
  componentDidUpdate(oldProps) {
  }
  render() {
    return (
      <BodyHost
        navi={
          <div>
            <Nav className="flex-wrap" onSelect={this.onClickNavi}>
              {
                this.state.options.map((option, index) =>
                  <Nav.Item
                    key={index}>
                    <Nav.Link
                      eventKey={index}
                      bsPrefix={'navi-side-menu-link-override'}>
                      <div className={
                        option.id === this.state.selectedMenu
                          ? 'navi-side-menu-link li__side-menu--focus'
                          : 'navi-side-menu-link li__side-menu--no-focus'
                      }>
                        {option.name}
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                )
              }
            </Nav>
            <div className="nav-desktop ml-3 mb-1" style={{position: "fixed", bottom: "0"}}>
              <label style={{fontSize:"0.66rem", color: "grey"}}>{`Ver: ${process.env.REACT_APP_VERSION}`}</label>
            </div>
          </div>
        }
        content={
          this.state.selectedMenu === this.state.menuPs.id ?
            <ParkingStatus></ParkingStatus> :
            this.state.selectedMenu === this.state.menuBp.id ?
              <BookParking></BookParking> :
              this.state.selectedMenu === this.state.menuPf.id ?
                <Profile></Profile> :
                this.state.selectedMenu === this.state.menuVp.id ?
                  <VipPlates></VipPlates> : null
        }>
      </BodyHost>
    );
  }
  onClickNavi = (k) => {
    logger.d(LOG_TAG, 'onClickNavi', k);
    this.setState({
      selectedMenu: this.state.options[k].id
    });
  };
}
export default UserManagement;

