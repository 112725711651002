export default {
  user_id: '',
  car_plate: '',
  duration: '',
  site_name: '',
  slot_number: '',
  start_parking_at: '',
  extend_at: '',
  extend_duration: '',
  stop_parking_at: '',
  timestamp: 0,
  isValid(value) {
    if (value.user_id) {
      return true;
    }
    return false;
  },
};
