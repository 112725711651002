import React, {Component} from 'react';
import connect from 'react-redux/es/connect/connect';
import AnimPage from '../../../components/anim/animPage';
import {setUser} from '../../../store/actions';
import CardSearchUserByEmail from '../../../components/cardSearchUserByEmail';
import logger from '../../../utils/logger';
import localStorageHelper from "../../../utils/localStorageHelper";
import ParkingProfile from './parkingProfile';
import SiteProfile from './siteProfile';

const LOG_TAG = 'bookParking';

class BookParking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // profile
      userInfoSearched: null,

      // park info
      parkSiteSelected: null,
      parkSlotSelected: null,
      parkSlotTimeAvailable: 0,
      fixedRatesAvailable: [],

    };
  }
  componentDidMount() {
    logger.d(LOG_TAG, 'componentDidMount');
  }
  componentDidUpdate(oldProps) {
    const newProps = this.props;
    if (oldProps.userInfo !== newProps.userInfo) {
      logger.d(LOG_TAG, 'componentDidUpdate');
      this.resetState(newProps.userInfo);
    }
  }
  resetState(userInfo) {
    logger.d(LOG_TAG, 'resetState', userInfo);
    this.setState({
      userInfoSearched: userInfo
    });
  };
  render() {
    return (
      <AnimPage>
        <div className="container-fluid p-0 mt-3">
          <div className="row app-main__content">
            <div className="col-md-6 app-main__content__card">
              <CardSearchUserByEmail
                userInfo={this.state.userInfoSearched}
                onResult={this.onSearchUserResult}/>
              {
                this.state.userInfoSearched && (
                  <ParkingProfile
                    siteInfo={this.state.parkSiteSelected}
                    slotInfo={this.state.parkSlotSelected}
                    availableTime={this.state.parkSlotTimeAvailable}
                    fixedRatesAvailable={this.state.fixedRatesAvailable}>
                  </ParkingProfile>
                )
              }
            </div>
            <div className="col-md-6 app-main__content__card">
              <SiteProfile
                userInfo={this.state.userInfoSearched}
                onSelectParkSite={this.onSelectParkSite}
                onSelectParkSlot={this.onSelectParkSlot}>
              </SiteProfile>
            </div>

          </div>
        </div>
      </AnimPage>
    );
  }
  onSearchUserResult = value => {
    logger.d(LOG_TAG, 'onSearchResult', value);
    this.props.setUser(value);
  };
  onSelectParkSite = siteInfo => {
    logger.d(LOG_TAG, 'onSelectParkSite', siteInfo);
    this.setState({
      parkSiteSelected: siteInfo,
    });
  };
  onSelectParkSlot = (slotInfo, availableTime, fixedRatesAvailable) => {
    logger.d(LOG_TAG, 'onSelectParkSlot', slotInfo, availableTime, fixedRatesAvailable);
    this.setState({
      parkSlotSelected: slotInfo,
      parkSlotTimeAvailable: availableTime,
      fixedRatesAvailable: fixedRatesAvailable ? fixedRatesAvailable : [],
    });
  };
}

// listens to state change
const mapStateToProps = state => {
  const userInfo = state.userInfo;
  return {
    userInfo,
  };
};
export default connect(
  mapStateToProps,
  {
    setUser,
  }
)(BookParking);

