import dataHelper from '../utils/dataHelper';
import logger from '../utils/logger';

import React, {Component} from 'react';
import {Handles, Rail, Slider, Tracks} from 'react-compound-slider'

const LOG_TAG = 'RangeSlider';
const sliderStyle = {  // Give the slider some width
  position: 'relative',
  width: '100%',
};
const railStyle = {
  position: 'absolute',
  width: '100%',
  height: '0.3rem',
  borderRadius: 5,
  backgroundColor: '#f4f4f4',
};
const tickStyle = {
  marginTop: '1rem',
  fontSize: 11,
};
export function Handle({ // your handle component
                         handle: { id, value, percent },
                         getHandleProps,
                         divisor,
                         disabled
                       }) {
  // logger.d(LOG_TAG, 'Handle', value, divisor);
  let display = '-';
  if (!isNaN(value)) {
    display = value / divisor;
    if (divisor > 1) {
      display = display.toFixed(2);
    }
    // display = dataHelper.formatParkingDurationFromMin(display, true);
  }
  // logger.d(LOG_TAG, 'Handle', value, display, disabled);
  return (
    <div
      style={{
        left: `${percent}%`,
        position: 'absolute',
        marginLeft: '-0.5rem',
        marginTop: '-0.42rem',
        zIndex: 2,
        width: '1.1rem',
        height: '1.1rem',
        border: 0,
        textAlign: 'center',
        cursor: 'pointer',
        borderRadius: '50%',
        backgroundColor: `${disabled ? 'var(--clr-secondary)' : 'var(--clr-primary)'}`,
        color: '#333',
        boxShadow: '1px 1px 1px 1px #f1f1f1',
      }}
      {...getHandleProps(id)}>
      <div style={{ fontSize: 10, marginTop: '-1.2rem', minWidth: '5rem', textAlign: 'left'}}>
        {display}
      </div>
    </div>
  )
}
function Track({ source, target, getTrackProps }) { // your own track component
  return (
    <div
      style={{
        position: 'absolute',
        height: '0.3rem',
        zIndex: 1,
        backgroundColor: 'var(--clr-primary)',
        borderRadius: 5,
        cursor: 'pointer',
        left: `${source.percent}%`,
        width: `${target.percent - source.percent}%`,
      }}
      {...getTrackProps()} // this will set up events if you want it to be clickable (optional)
    />
  )
}
class RangeSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      domain: [0, 1],
      step: 1,
      values: [0],
      unitPrefix: '',
      unitSuffix: '',
      domainLabelLeft: '',
      domainLabelRight: '',
      divisor: 1,
      disabled: false,
    };
  }
  componentDidMount() {
    this.setState({
      title: this.props.title,
      domain: this.props.domain,
      step: this.props.step,
      values: this.props.values,
      unitPrefix: this.props.unitPrefix ? this.props.unitPrefix : '',
      unitSuffix: this.props.unitSuffix ? this.props.unitSuffix : '',
      domainLabelLeft: this.props.domainLabelLeft ? this.props.domainLabelLeft : '',
      domainLabelRight: this.props.domainLabelRight ? this.props.domainLabelRight : '',
      divisor: this.props.divisor ? parseInt(this.props.divisor) : 1,
      disabled: this.props.disabled ? true : false,
    });
  }
  componentDidUpdate(oldProps) {
    const newProps = this.props;
    // logger.d(LOG_TAG, 'componentDidUpdate', newProps.disabled);
    const oldValues = oldProps.values ? oldProps.values : [];
    const newValues = newProps.values ? newProps.values : [];
    let hasValuesChanged = false;
    if (oldValues.length !== newValues.length) {
      // logger.d(LOG_TAG, 'componentDidUpdate1', oldValues.length, newValues.length);
      this.setState({
        values: newProps.values
      });
    } else if (oldValues.length > 0 && !isNaN(oldValues[0]) && !isNaN(newValues[0]) ) {
      if (oldValues[0] !== newValues[0]) {
        this.setState({
          values: newProps.values
        });
      }
    }
    if (oldProps.domain !== newProps.domain) {
      this.setState({
        domain: newProps.domain
      });
    }
    if (oldProps.step !== newProps.step) {
      this.setState({
        step: newProps.step
      });
    }
    if (oldProps.disabled !== newProps.disabled) {
      this.setState({
        disabled: newProps.disabled
      })
    }
  };
  render() {
    // logger.d(LOG_TAG, 'render', this.state.divisor);
    return (
      <div className="col-md-12">
        <div className="form-group">
          <label htmlFor="">{this.props.title}</label>
          <div className="pl-2 pr-2" style={{marginTop: '1.6rem'}}>
            <Slider
              rootStyle={sliderStyle}
              domain={this.state.domain}
              step={this.state.step}
              values={this.state.values}
              onUpdate={this.props.onUpdate}
              onChange={this.props.onChange}>
              <Rail>
                {({ getRailProps }) => (  // adding the rail props sets up events on the rail
                  <div style={railStyle} {...getRailProps()} />
                )}
              </Rail>
              <Handles>
                {({ handles, getHandleProps }) => (
                  <div className="slider-handles">
                    {handles.map(handle => (
                      <Handle
                        key={handle.id}
                        handle={handle}
                        getHandleProps={getHandleProps}
                        divisor={this.state.divisor}
                        disabled={this.state.disabled}
                      />
                    ))}
                  </div>
                )}
              </Handles>
              <Tracks right={false}>
                {({ tracks, getTrackProps }) => (
                  <div className="slider-tracks">
                    {tracks.map(({ id, source, target }) => (
                      <Track
                        key={id}
                        source={source}
                        target={target}
                        getTrackProps={getTrackProps}
                      />
                    ))}
                  </div>
                )}
              </Tracks>
            </Slider>
            <div className="row flex-row app-pos-flex--space-between pl-2 pr-2">
              <div style={tickStyle}>{
                this.state.unitPrefix +
                this.state.domainLabelLeft ? this.state.domainLabelLeft : this.state.domain[0] +
                this.state.unitSuffix}
              </div>
              <div style={tickStyle}>{
                this.state.unitPrefix +
                this.state.domainLabelRight ? this.state.domainLabelRight : this.state.domain[1] +
                this.state.unitSuffix}
              </div>
            </div>
          </div>

        </div>
      </div>


    );
  };
}


export default RangeSlider;
