import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {createStore} from 'redux';
import Axios from 'axios';
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format';
import {Provider as BusProvider} from 'react-bus'
import RootReducer from './store/reducers';
import WebApi from './webapi';
import PageHost from './components/PageHost';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/index.css';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import Routes from './routes';
import logger from './utils/logger';

const LOG_TAG = 'index';

// store
const store = createStore(RootReducer);
window.store = store;

// axios
Axios.defaults.withCredentials = true;
Axios.defaults.headers.common.Accept = 'application/json';

window.axios = Axios;
window.webapi = WebApi;
window.moment = moment;
momentDurationFormatSetup(moment);

const RouterContent = () => {
  return <div>
    <Switch>
      <Route
        exact={true}
        path={Routes.login.path}
        component={Routes.login.component}
      />
      <Route
        exact={true}
        path={Routes.termsPrivacy.path}
        component={Routes.termsPrivacy.component}
      />
      <Route
        exact={true}
        path={Routes.visitor.path}
        component={Routes.visitor.component}
      />
      <Route
        exact={true}
        path={Routes.mainRoot.path}
        component={Routes.mainRoot.component}
      />
      <Route
        exact={true}
        from={Routes.root.path}
        component={Routes.root.component} />
      <Route component={Routes.noMatch.component} />
    </Switch>
  </div>
};
ReactDOM.render(
  <BusProvider>
    <Provider store={store}>
      <PageHost>
        <Router>
          <RouterContent/>
        </Router>
      </PageHost>
    </Provider>
  </BusProvider>
  ,
  document.getElementById('root')
);
/*function PrivateRoute({ component: Component, ...rest }) {
  // logout when refresh for now...
  const authInfo = LocalStorageHelper.getAuthInfo();
  let isAuthenticated = StateInfo.AuthInfo.isValid(authInfo);
  // console.log(LOG_TAG, 'PrivateRoute', isAuthenticated, process.env.REACT_APP_PRODUCTION);
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated === true ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: Routes.login.path,
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}*/
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
