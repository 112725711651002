import ParkingInfo from '../store/states/parkingInfo';
import logger from '../utils/logger';

const LOG_TAG = 'syncUserParking';
export default {
  execute(userInfo, onCompleteCb) {
    logger.d(LOG_TAG, 'execute');
    window.webapi.User.getLastParkingHistory(
      userInfo.user_id,
      (response) => {
        const data = response.data;
        logger.d(LOG_TAG, 'execute', 'onResponse', data);
        if (onCompleteCb) {
          if (data && data.length > 0) {
            const lastSession = ParkingInfo(data[data.length - 1]);
            logger.d(LOG_TAG, 'execute', 'onResponse', lastSession.isExpired());
            if (!lastSession.isExpired()) {
              onCompleteCb(true, lastSession)
            } else {
              onCompleteCb(false, null);
            }
          } else {
            onCompleteCb(false, null);
          }
        }
      },
      (error) => {
        const data = error.data;
        logger.d(LOG_TAG, 'execute', 'onError', error);
        onCompleteCb(false, null);
      }
    );
  },
};
