export default {
  tab: '',
  menu: '',
  format(tab, menu) {
    return {
      tab: tab,
      menu: menu
    };
  }
};
