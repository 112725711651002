import ApiHelper from './apiHelper';
import Auth from './binder/auth';
import Admin from './binder/admin';
import User from './binder/user';
import Group from './binder/group';
import Query from './binder/query';

/* APIs go through binder first for redirect
 * */
export default {
  ApiHelper,
  Auth,
  Admin,
  User,
  Group,
  Query,
};
