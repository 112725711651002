import logger from '../utils/logger';

const LOG_TAG = 'syncParkSlots';
export default {
  execute(siteId, onCompleteCb) {
    logger.d(LOG_TAG, 'execute', siteId);
    window.webapi.Query.querySiteSlots(
      siteId,
      (response) => {
        const data = response.data;
        logger.d(LOG_TAG, 'execute', 'onResponse', data);
        if (onCompleteCb) {
          onCompleteCb(true, data);
        }
      },
      (error) => {
        const data = error.data;
        logger.d(LOG_TAG, 'execute', 'onError', data);
        if (onCompleteCb) {
          onCompleteCb(false, data);
        }
      });
  },
};
