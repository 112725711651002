import React, {Component} from 'react';
import Routes from '../../routes';
import BodyHost from '../../components/bodyHost';
import Nav from 'react-bootstrap/Nav';
import logger from '../../utils/logger';
import accessHelper from '../../utils/accessHelper';
import localStorageHelper from '../../utils/localStorageHelper';
import Account from "./accountUsers/account";
import Users from "./accountUsers/users";

const LOG_TAG = 'AccountUsers';
class AccountUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuAct: Routes.main[Routes.INDEX_AU].routes[0],
      menuUsr: Routes.main[Routes.INDEX_AU].routes[1],
      options: [],
      selectedMenu: '',
    };
    const authUserInfo = localStorageHelper.getAuthUserInfo();
    // load options with respect to access level and default tab
    if (accessHelper.isSct(authUserInfo)) {
      this.state.options.push({
        id: this.state.menuAct.id,
        name: this.state.menuAct.name,
      });
      this.state.options.push({
        id: this.state.menuUsr.id,
        name: this.state.menuUsr.name,
      });
    } else {
      this.state.options.push({
        id: this.state.menuAct.id,
        name: this.state.menuAct.name,
      });
    }

    this.state.selectedMenu = this.state.menuAct.id;
  }
  componentDidMount() {
  }
  render() {
    return (
      <div>
        <BodyHost
          navi={
            <div>
              <Nav className="flex-nowrap navi-side-menu-override" onSelect={k => this.onClickNavi(k)}>
                {
                  this.state.options.map((option, index) =>
                    <Nav.Item
                      key={index}>
                      <Nav.Link
                        eventKey={index}
                        bsPrefix={'navi-side-menu-link-override'}>
                        <div className={
                          option.id === this.state.selectedMenu
                            ? 'navi-side-menu-link li__side-menu--focus'
                            : 'navi-side-menu-link li__side-menu--no-focus'
                        }>
                          {option.name}
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                  )
                }
              </Nav>
              <div className="nav-desktop ml-3 mb-1" style={{position: "fixed", bottom: "0"}}>
                <label style={{fontSize:"0.66rem", color: "grey"}}>{`Ver: ${process.env.REACT_APP_VERSION}`}</label>
              </div>
            </div>
          }
          content={
            this.state.selectedMenu === this.state.menuAct.id ?
              <Account></Account> :
              this.state.selectedMenu === this.state.menuUsr.id ?
                <Users></Users> : null
          }>
        </BodyHost>
      </div>
    );
  }
  onClickNavi = (k) => {
    logger.d(LOG_TAG, 'onClickNavi', k);
    this.setState({
      selectedMenu: this.state.options[k].id
    });
  };
}

export default AccountUsers;
