import React, {Component} from 'react';
import imgLogo from '../assets/images/logo.png';

class TermsPrivacy extends Component {
  render() {
    return (
      <div className="container mt-3">
        {/* logo */}
        <section className="row justify-content-center mt-5 p-2">
          <img
            className="app__img-120"
            src={imgLogo}
            alt="logo"
          />
        </section>

        <section className="justify-content-center p-3">
          <strong>Kiwi Park (App Policy)</strong>
          <br/><br/>
          <strong>Privacy Policy</strong>
          <br/><br/>

          <h5>OUR COMMITMENT TO PRIVACY</h5>
          <p>
            Your privacy is essential to us. To ensure we better protect your privacy,
            we provide this notice explaining our online
            information practices and the choices you can make about the way
            your information is collected and used.
          </p>
          <br/>
          <h5>THE INFORMATION WE COLLECT</h5>
          <p>
            This notice applies to all information collected or submitted to the
            Kiwi Park App.
            <br/><br/>
            The types of personal information collected when registering are:
            First name, Last Name, Email address. Phone Number is optional should the user choose to
            store it against their profile.
          </p>

          <br/>
          <h5>HOW KIWI PARK WILL USE MY INFORMATION?</h5>
          <p>
            We use the information you provide about yourself to validate who
            you are, and to communicate with you from time to time.
            We do not share this information with outside parties except for
            government agencies where we are required to do so under law.
            We use an individuals email address to answer the email we receive
            from them, in addition to sending automatic notifications.
            Such addresses are not used for any other purpose and are not
            shared with outside parties.
            <br/><br/>
            The types of Personal Information we collect may vary depending on your use of the features of
            the Platform. Examples include when you:
            <br/>
            <ol>
              <li>
                fill in any form on the Platform, such as when you register or update the details of your user account;
              </li>
              <li>
                access or use the Platform, such as to search for, or Host Carparks, create or accept bookings,
                pay for Carparks, enter your bank account number so you can be paid for hosting parking,
                book or pay for any associated services that may be available, post comments or reviews,
                or communicate with other users;
              </li>
              <li>
                link your account on a third party site (e.g. Google, Facebook Accounts) to your Kiwi Park account,
                in which case we will make available the Personal Information that you have provided to the third party site,
                to which the extent allowed by your settings with the third party site and authorized by you; and
              </li>
              <li>
                communicate with Kiwi Park.
              </li>
            </ol>
          </p>
          <br/>
          <h5>PERSONAL INFORMATION</h5>
          <p>
            Kiwi Parking will retain your personal information only for as long as is necessary for
            the purposes set out in this Privacy Policy. We will retain and use your information to
            the extent necessary to comply with our legal obligations, resolve disputes, and
            enforce our policies.

            <br/><br/>
            In certain circumstances, you have the following data protection rights:
            <br/>
            <ol>
              <li>
                The right to access, update or to delete the information we have on you.
              </li>
              <li>
                The right of rectification
              </li>
              <li>
                The right of restriction.
              </li>
              <li>
                The right to withdraw consent.
              </li>
            </ol>
            <br/>
            If you wish to be informed what Personal Information we hold about you and if you
            want it to be removed from our systems, please contact us.
            <br/><br/>
            Finally, we will never use or share the personally identifiable information provided to
            us online in ways unrelated to the ones described above without also providing you
            an opportunity to opt-out or otherwise prohibit such unrelated uses.
            <br/>

          </p>
          <br/>
          <h5>OUR COMMITMENT TO DATA SECURITY</h5>
          <p>
            We understand that security is important. To prevent unauthorized
            access, provide data accuracy, and ensure the correct use of
            information, we have put in place appropriate physical, electronic,
            and managerial procedures to safeguard and secure the information
            we collect and maintain.
          </p>
          <br/>
          <h5>NO REFUND POLICY</h5>
          <p>
            Should you end your parking session early and have not fulfilled the time you have paid for,
            you will not receive a refund for the remainder amount of time left on your parking session.
          </p>
          <br/>
          <h5>HOW CAN I ACCESS MY INFORMATION?</h5>
          <p>
            You can access and edit all your personally identifiable
            information (excluding information collected during identity
            verification, which is not made available over the internet) that we
            collect online. We use this procedure to better safeguard your
            information.
            <br/>
            To protect your privacy and security, we will also take reasonable
            steps to verify your identity before granting access or making
            corrections.
          </p>
          <br/>
          <h5>HOW TO CONTACT US</h5>
          <p>
            Should you have other questions or concerns about these privacy
            policies, please contact us at <a href = "mailto:info@kiwi-parking.co.nz">info@kiwi-parking.co.nz</a>
          </p>
        </section>
      </div>
    );
  }
}

export default TermsPrivacy;

