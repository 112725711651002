import React, {Component, useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import queryString from 'query-string';
import Axios from 'axios';
import Action, {setAuth, setAuthUser, setAuthUserGroups, setLocation, setUser} from '../store/actions';
/* Fronts icons */
import {library} from '@fortawesome/fontawesome-svg-core';
import {
  faArrowLeft,
  faChevronLeft,
  faConciergeBell,
  faEdit,
  faMapMarkerAlt,
  faPencilAlt,
  faPlus,
  faSearch,
  faSync,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
/* Routes */
import Routes from '../routes';
/* Images */
/* helper */
import PageHeader from '../components/pageHeader';
import MobileMenu from '../components/mobileMenu'
import {Button, Container} from 'react-floating-action-button'
import localStorageHelper from '../utils/localStorageHelper';
import accessHelper from '../utils/accessHelper';
import logger from '../utils/logger';
import {useBus, useListener} from 'react-bus'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import StateInfo from "../store/states";
import UserManagement from "./main/userManagement";
import ParkingAssets from "./main/parkingAssets";
import AccountUsers from "./main/accountUsers";

const LOG_TAG = 'Main';
const EventBusBinder = ({onEnableFabRefreshCb, onAxiosErrorCb}) => {
  useListener('ev_enable_fab_refresh', (event) => {
    logger.d(LOG_TAG, 'EventBusBinder', 'ev_enable_fab_refresh', event);
    onEnableFabRefreshCb(event);
  });
  useListener('ev_axios_error', (event) => {
    logger.d(LOG_TAG, 'EventBusBinder', 'ev_axios_error', event);
    onAxiosErrorCb(event);
  });
  const bus = useBus();
  useEffect(() => {
    Axios.interceptors.response.use(function (response) {
      return response;
    }, function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      const response = error.response;
      const data = response.data;
      logger.d(LOG_TAG, 'EventBusBinder', 'axios', 'error', data);
      if (
        data.err_code === 500 &&
        data.err_message === 'Access denied') {
        bus.emit('ev_axios_error', 'denied');
      } else if (
        data.err_code === 401 ||
        data.err_code === 403
      ) {
        bus.emit('ev_axios_error', 'expired');
      } else {
        bus.emit('ev_axios_error', 'error');
      }
      return Promise.reject(response);
    });
  }, []); // buggy without `uid` in this array
  const el = React.useRef(null);
  return <div ref={el}></div>
};
const FabRefresh = () => {
  const bus = useBus();
  const onClickRefresh = (event) => {
    logger.d(LOG_TAG, 'FabRefresh', 'onClickRefresh');
    bus.emit('ev_on_refresh', true);
  };
  return (
    <Container
      styles={{right: '1rem', bottom: '1rem'}}>
      <Button
        onClick={onClickRefresh}
        styles={{width: '2.6rem', height: '2.6rem', backgroundColor: 'var(--clr-primary)', color: '#fafafa'}}>
        <FontAwesomeIcon icon="sync"></FontAwesomeIcon>
      </Button>
    </Container>
  );
};
class Main extends Component {
  constructor(props) {
    super(props);
    //logger.d(LOG_TAG, 'constructor', tabInfo);
    this.state = {
      locationInfo: {
        tab: '',
      },
      naviOptions: {
        handler: this.onClickNav,
        options: [],
      },
      enableFabRefresh: false,
      tabUm: Routes.main[Routes.INDEX_UM],
      tabPa: Routes.main[Routes.INDEX_PA],
      tabAu: Routes.main[Routes.INDEX_AU],
    };
    const authUserInfo = localStorageHelper.getAuthUserInfo();
    // load options with respect to access level and default tab
    if (accessHelper.isSct(authUserInfo)) {
      Routes.main.forEach((route) => {
        this.state.naviOptions.options.push({
          id: route.id,
          name: route.name,
        });
      });
    } else {
      Routes.main.forEach((route) => {
        if (
          route.id === this.state.tabPa.id ||
          route.id === this.state.tabAu.id ) {
          this.state.naviOptions.options.push({
            id: route.id,
            name: route.name,
          });
        }
      });
    }
    library.add(faTrashAlt);
    library.add(faPencilAlt);
    library.add(faSearch);
    library.add(faEdit);
    library.add(faPlus);
    library.add(faMapMarkerAlt);
    library.add(faChevronLeft);
    library.add(faArrowLeft);
    library.add(faSync);
    library.add(faConciergeBell);
    window.store.subscribe(() => {
      logger.d(LOG_TAG, 'store.subscribe', this.props.locationInfo);
    });
  };
  componentDidMount() {
    const parsed = queryString.parse(this.props.location.search);

    const authInfo = localStorageHelper.getAuthInfo();
    const authUserInfo = localStorageHelper.getAuthUserInfo();
    const isAuthenticated = StateInfo.AuthInfo.isValid(authInfo);
    const isSct = accessHelper.isSct(authUserInfo);

    logger.d(LOG_TAG, 'componentDidMount', isAuthenticated, isSct);
    if (isAuthenticated) {
      const location = accessHelper.resolveUrlTarget(isSct, parsed);
      logger.d(LOG_TAG, 'componentDidMount', 'location', location);
      if (location.tab) {
        this.setLocation(location.tab)
      } else {
        setTimeout(() => {
          this.props.history.replace(Routes.noMatch.path);
        }, 350);
      }
    } else {
      setTimeout(() => {
        this.props.setAuth(null);
        this.props.setAuthUser(null);
        this.props.setAuthUserGroups(null);
        this.props.setUser(null);
        this.props.history.replace(Routes.login.path);
      }, 350);
    }
  };
  componentDidUpdate(oldProps) {
    const newProps = this.props;
    if (oldProps.locationInfo !== newProps.locationInfo) {
      // console.log(LOG_TAG, 'componentDidUpdate', newProps.locationInfo);
      this.setState({
        locationInfo: newProps.locationInfo,
      })
    }
  }
  componentWillUnmount () {
  }
  render() {
    return (
      <div>
        <EventBusBinder
          onEnableFabRefreshCb={this.onEnableFabRefresh}
          onAxiosErrorCb={this.onAxiosError}>
        </EventBusBinder>
        <MobileMenu naviOptions={this.state.naviOptions}></MobileMenu>
        <div>
          <section>
            <PageHeader naviOptions={this.state.naviOptions} />
          </section>
          {/* push down padding */}
          <section style={{ padding: '3.8rem 0px' }} />

          {/* body */}
          {/* <section style={{ padding: '6px 0px' }} />*/}
          {this.state.locationInfo.tab === this.state.tabUm.id && <UserManagement></UserManagement>}
          {this.state.locationInfo.tab === this.state.tabPa.id && <ParkingAssets></ParkingAssets>}
          {this.state.locationInfo.tab === this.state.tabAu.id && <AccountUsers></AccountUsers>}
          {this.state.enableFabRefresh && <FabRefresh/>}

        </div>
      </div>
    );
  }
  onClickNav = key => {
    logger.d(LOG_TAG, 'onClickNav', key);
    //const tabInfo = window.store.getState().tabInfo;
    switch (key) {
      case this.state.tabUm.id:
      case this.state.tabPa.id:
      case this.state.tabAu.id:
        {
          this.setLocation(key)
        }
        break;
      default:
    }
  };
  setLocation(tab) {
    if (this.state.locationInfo.tab !== tab) {
      logger.d(LOG_TAG, 'setLocation', tab);
      this.props.history.replace(Routes.mainRoot.path+'?t='+tab);
      window.store.dispatch(
        Action.setLocation(StateInfo.LocationInfo.format(tab, ''))
      );
    }
  }
  onClickReport = () => {
    logger.d(LOG_TAG, 'onClickReport');
  };
  onEnableFabRefresh = (enable) => {
    logger.d(LOG_TAG, 'onEnableFabRefresh', enable);
    this.setState({
      enableFabRefresh: enable
    })
  };
  onAxiosError = (error) => {
    logger.d(LOG_TAG, 'onAxiosError', error);
    if (error === 'expired') {
      setTimeout(() => {
        this.props.setAuth(null);
        this.props.setAuthUser(null);
        this.props.setAuthUserGroups(null);
        this.props.setUser(null);
        this.props.history.replace(Routes.login.path+'?expired=true');
      }, 660);
    }
  };
}
const mapStateToProps = state => {
  const locationInfo = state.locationInfo;
  return {
    locationInfo,
  };
};
export default (withRouter(connect(
  mapStateToProps,
  {
    setLocation,
    setAuth,
    setAuthUser,
    setAuthUserGroups,
    setUser,
  }
)(Main)));
