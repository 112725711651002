import React, {Component} from 'react';
import imgLogo from '../assets/images/logo.png';
import {connect} from 'react-redux';
import {setAuth, setAuthUser, setAuthUserGroups, setLoading, setNotification, setUser} from '../store/actions';
import StateInfo from '../store/states';
import logger from '../utils/logger';
import syncUser from '../handlers/syncUser';
import SelectSite from '../pages/visitor/selectSite';
import VisitorParking from '../pages/visitor/visitorParking';
import accessHelper from '../utils/accessHelper';
import localStorageHelper from '../utils/localStorageHelper';

const LOG_TAG = 'Visitor';
class Visitor extends Component {
  constructor(props) {
    super(props);
    // init and sync
    this.state = {
      username: '',
      password: '',
      isAuthenticated: false,
      userInfo: null,
      groupOptions: null,
      parkSiteSelected: null,
    };
    //logger.d(LOG_TAG, 'constructor', localStorageHelper.getAuthInfo());
  }
  componentDidMount() {
    logger.d(LOG_TAG, 'componentDidMount', window.location.href, window.history.length);
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', this.onHandlePopState);
    this.resetState();
  }
  componentWillUnmount() {
    window.removeEventListener('popstate', this.onHandlePopState);
  }
  onHandlePopState = (e) => {
    logger.d(LOG_TAG, 'onHandlePopState', window.location.href, window.history.length);
    window.history.pushState(null, document.title,  window.location.href);
  };
  resetState() {
    logger.d(LOG_TAG, 'resetState');
    // is visitor?
    const isVisitor = localStorageHelper.getIsVisitor();
    logger.d(LOG_TAG, 'resetState', 'visitor', isVisitor);
    if (!isVisitor) {
      // reset all and start over
      this.props.setAuth(null);
      this.props.setAuthUser(null);
      this.props.setAuthUserGroups(null);
      this.props.setUser(null);
      localStorageHelper.setIsVisitor(true);
      this.setState({
        username: '',
        password: '',
        isAuthenticated: false,
        userInfo: null,
        groupOptions: null,
        parkSiteSelected: null,
      })
    } else {
      // has signed in sct only?
      const authInfo = localStorageHelper.getAuthInfo();
      const userInfo = localStorageHelper.getAuthUserInfo();
      const authUserGroups = localStorageHelper.getAuthUserGroups();

      const validUser = authInfo && (accessHelper.isSct(userInfo) || accessHelper.hasManagedGroups(authUserGroups));
      logger.d(LOG_TAG, 'resetState', 'validUser', authInfo, userInfo, authUserGroups);
      if (validUser) {
        this.setState({
          isAuthenticated: true,
          userInfo: userInfo,
          groupOptions: authUserGroups,
        })
      } else {
        // reset all and start over
        this.props.setAuth(null);
        this.props.setAuthUser(null);
        this.props.setAuthUserGroups(null);
        this.props.setUser(null);
        this.setState({
          username: '',
          password: '',
          isAuthenticated: false,
          userInfo: null,
          groupOptions: null,
          parkSiteSelected: null,
        })
      }
    }
  }
  render() {
    return (
      <div className="container mt-3">
        {/* SignIn section */}
        {
          (this.state.isAuthenticated && this.state.parkSiteSelected) ?
            <div>
              <div className="float-right" onClick={this.onClickLogout}>Logout</div>
              <VisitorParking
                siteInfo={this.state.parkSiteSelected}>
              </VisitorParking>
            </div>

            :
            (this.state.isAuthenticated) ?
              <div>
                <div className="float-right" onClick={this.onClickLogout}>Logout</div>
                <SelectSite
                  userInfo={this.state.userInfo}
                  groupInfo={this.state.groupOptions}
                  onSelectParkSite={this.onSelectParkSite}>

                </SelectSite>
              </div>
              :
              <section>
                <section className="row justify-content-center mt-5 p-2">
                  <img
                    className="app__img-120"
                    src={process.env.REACT_APP_IS_PRODUCTION === 'true' ? imgLogo : ''}
                    alt="logo"
                  />
                </section>
                <section className="row justify-content-center p-3">
                  <h4>
                    {process.env.REACT_APP_IS_PRODUCTION === 'true'
                      ? 'KIWI PARKING Visitor Parking Management'
                      : 'Hello World 2'}
                  </h4>
                </section>

                {/* user inputs */}
                <div className="p-3" />
                <form onSubmit={this.onClickSignIn}>
                  <div className="m-auto" style={{ width: '100%', maxWidth: '18rem' }}>
                    <div className="form-group">
                      <label htmlFor="">Email address</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        value={this.state.username}
                        onChange={this.onChangeUsername}/>
                    </div>
                    <div className="form-group">
                      <label htmlFor="">Password</label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        value={this.state.password}
                        onChange={this.onChangePassword}/>
                    </div>
                    <div className="form-group">
                      <button className="btn btn-sign-in">
                        Sign In
                      </button>
                    </div>
                  </div>
                </form>
              </section>
        }
      </div>
    );
  }
  onChangeUsername = value => {
    this.setState({
      username: value.target.value,
    });
  };
  onChangePassword = value => {
    this.setState({
      password: value.target.value,
    });
  };
  validate() {
    if (this.state.username && this.state.password) {
      return true;
    } else {
      return false;
    }
  };
  onClickSignIn = e => {
    e.preventDefault();
    const isValid = this.validate();
    logger.d(LOG_TAG, 'onClickSignIn', isValid);
    if (!isValid) {
      this.props.setNotification(
        StateInfo.NotificationInfo.format('danger', null, 'Invalid username or password')
      );
    }
    else {
      this.props.setLoading(StateInfo.LoadingInfo.format(true, 'Please wait...'));
      let provider = 'local';
      if (StateInfo.AuthInfo.isMockIdentity(this.state.username)) {
        provider = 'mock';
      }
      window.webapi.Auth.postOAuth(
        provider, this.state,
        (response) => {
          this.onSignInSuccess(response.data);
        },
        (error) => {
          this.onSignInError();
        });
    }
  };
  onSignInSuccess(value) {
    logger.d(LOG_TAG, 'onSignInSuccess', value);
    this.props.setAuth(value);

    const userId = value.managed_users[0].user_id;
    logger.d(LOG_TAG, 'onSignInSuccess', 'syncUser', userId);
    syncUser.execute(userId, (userInfo, groups) => {
      if (userInfo) {
        this.onUserSuccess(userInfo, groups);
      } else {
        this.onUserError();
      }
    });
  };
  onSignInError(value) {
    setTimeout(() => {
      this.props.setLoading(StateInfo.LoadingInfo.format(false, ''));
      this.props.setNotification(
        StateInfo.NotificationInfo.format('danger', null, 'Unknown username or password')
      );
    }, 1200);
  };
  onUserSuccess(userInfo, groups) {
    logger.d(LOG_TAG, 'onUserSuccess', userInfo, groups);
    this.props.setAuthUser(userInfo);
    this.props.setAuthUserGroups(groups);
    if (accessHelper.hasManagedGroups(groups)) {
      setTimeout(() => {
        this.props.setLoading(StateInfo.LoadingInfo.format(false, ''));
        this.setState({
          isAuthenticated: true,
          userInfo: userInfo,
          groupOptions: groups,
          parkSiteSelected: null
        })
      }, 1200);
    } else {
      StateInfo.NotificationInfo.format('danger', null, 'Unauthorized access')
    }
  };
  onUserError(value) {
    setTimeout(() => {
      this.props.setLoading(StateInfo.LoadingInfo.format(false, ''));
      this.props.setNotification(
        StateInfo.NotificationInfo.format('danger', null, 'Error retrieving user info')
      );
    }, 1200);
  };
  onSelectParkSite = value => {
    logger.d(LOG_TAG, 'onSelectParkSite', value);
    this.setState({
      parkSiteSelected: value
    })
  };
  onClickLogout = () => {
    logger.d(LOG_TAG, 'onClickLogout');
    // reset all and start over
    this.props.setAuth(null);
    this.props.setAuthUser(null);
    this.props.setAuthUserGroups(null);
    this.props.setUser(null);
    this.setState({
      username: '',
      password: '',
      isAuthenticated: false,
      userInfo: null,
      groupOptions: null,
      parkSiteSelected: null,
    })
  }
}

// connect and map actions
export default connect(
  null,
  {
    setAuth,
    setAuthUser,
    setAuthUserGroups,
    setLoading,
    setNotification,
    setUser,
  }
)(Visitor);
