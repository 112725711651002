import Binder from './index';
import logger from '../../utils/logger';

const LOG_TAG = 'binder.query';
export default {
  querySites(onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'querySites');
    Binder.WebApi().Query.querySites(onResponseCb, onErrorCb);
  },
  querySiteSlots(siteId, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'querySiteSlots');
    Binder.WebApi().Query.querySiteSlots(siteId, onResponseCb, onErrorCb);
  },
  queryAvailableTime(slotId, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'queryAvailableTime');
    Binder.WebApi().Query.queryAvailableTime(slotId, onResponseCb, onErrorCb);
  },
  queryAvailableTimeBeyondActiveSession(slotId, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'queryAvailableTimeBeyondActiveSession');
    Binder.WebApi().Query.queryAvailableTimeBeyondActiveSession(slotId, onResponseCb, onErrorCb);
  }
};
