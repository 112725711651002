import ApiHelper from '../apiHelper';
import logger from '../../utils/logger';

const TARGET_API = 'api/query';
const API_SITES = 'sites';
const API_SITE = 'site';
const API_SLOTS = 'slots';
const API_AVAILABLE_TIME = 'available_time';
const LOG_TAG = 'api.query';
export default {
  querySites(onResponseCb, onErrorCb) {
    const url = ApiHelper.createUrl(TARGET_API, API_SITES);
    logger.d(LOG_TAG, 'querySites', url);

    ApiHelper.apiGet(url, onResponseCb, onErrorCb);
  },
  querySiteSlots(siteId, onResponseCb, onErrorCb) {
    const url = ApiHelper.createUrl(TARGET_API, API_SITE, siteId, API_SLOTS);
    logger.d(LOG_TAG, 'querySiteSlots', url);

    ApiHelper.apiGet(url, onResponseCb, onErrorCb);
  },
  queryAvailableTime(slotId, onResponseCb, onErrorCb) {
    const url = ApiHelper.createUrl(TARGET_API, API_AVAILABLE_TIME, slotId);
    logger.d(LOG_TAG, 'queryAvailableTime', url);

    ApiHelper.apiGet(url, onResponseCb, onErrorCb);
  },
  queryAvailableTimeBeyondActiveSession(slotId, onResponseCb, onErrorCb) {
    const url = ApiHelper.createUrl(TARGET_API, API_AVAILABLE_TIME, slotId+'?excludeCurrent=true');
    logger.d(LOG_TAG, 'queryAvailableTimeBeyondActiveSession', url);

    ApiHelper.apiGet(url, onResponseCb, onErrorCb);
  }
};
