import Binder from './index';
import logger from '../../utils/logger';

const LOG_TAG = 'binder.auth';
export default {
  postOAuth(provider, payload, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'postOAuth', provider);
    if (provider === 'mock') {
      Binder.WebApiMock().Auth.postOAuth(provider, payload, onResponseCb, onErrorCb);
    } else {
      Binder.WebApi().Auth.postOAuth(provider, payload, onResponseCb, onErrorCb);
    }
  }
};
