import StateInfo from '../states';
import logger from '../../utils/logger';

const LOG_TAG = 'ParkSiteInfo';

export default {
  group_id: 0,
  name: '',
  address: '',
  gps_location: {
    lat: '',
    lon: ''
  },
  description: '',
  logo_url: '',
  /* reserved */
  min_duration: 0,     // unit minutes
  max_duration: 0, // unit minutes
  grace_period: 0,
  base_price: 0, // unit cents 100 = $1.00
  slots_available: 0,
  slots_total: 0,
  vip_plates: [],
  initial_duration: 0,
  initial_rate: 0,
  fixed_rates: [],
  // model helper
  create(groupId, name) {
    return {
      group_id: groupId,
      name: name,
      setLocation(lat, lng, address) {
        this.gps_location = {
          lat: lat,
          lon: lng,
        };
        this.address = address;
        return this;
      },
      setPricing(minDuration, maxDuration, basePrice) {
        this.min_duration = minDuration;
        this.max_duration = maxDuration;
        this.base_price = basePrice;
        return this;
      },
      setTowGracePeriod(gracePeriod) {
        this.grace_period = gracePeriod;
        return this;
      },
      setDescription(description) {
        this.description = description;
        return this;
      },
      setLogoUrl(logoUrl) {
        this.logo_url = logoUrl;
        return this;
      },
      setInitialPromo(initialDuration, initialRate) {
        this.initial_duration = initialDuration;
        this.initial_rate = initialRate;
        return this;
      },
      addFixedRates(rate) {
        if (!this.fixed_rates) {
          this.fixed_rates = [];
        }
        let rateInfo = this.fixed_rates.find((rateInfo) => rateInfo.day === rate.day);
        if (!rateInfo) {
          rateInfo = StateInfo.SlotInfo().setDay(rate.day);
          this.fixed_rates.push(rateInfo);
        }
        rateInfo.addWindow(StateInfo.WindowInfo().createRateWindow(rate));
        return this;
      }
    };
  },
};

