import logger from '../utils/logger';

const LOG_TAG = 'searchCarPlate';
export default {
  searchViaSites(carPlate, onCompleteCb) {
    logger.d(LOG_TAG, 'searchViaSites');
    window.webapi.Admin.searchCarPlate(
      carPlate.toLowerCase(),
      (response) => {
        const data = response.data;
        if (onCompleteCb) {
          onCompleteCb(true, data);
        }
      },
      (error) => {
        if (onCompleteCb) {
          onCompleteCb(false, []);
        }
      }
    )
  },
};
