import ApiHelper from '../apiHelper';
import logger from '../../utils/logger';

const TARGET_API = 'api/group';
const API_SITES = 'sites';
const API_SITE = 'site';
const API_SLOT = 'slot';
const API_SENSOR = 'sensor';
const API_RESERVATION = 'reservation';
const API_RESERVATIONS = 'reservations';

const LOG_TAG = 'api.group';
export default {
  get(groupId, onResponseCb, onErrorCb) {
    const url = ApiHelper.createUrl(TARGET_API, groupId);
    logger.d(LOG_TAG, 'get', groupId);

    const extraParams = {};
    ApiHelper.apiGet(url, onResponseCb, onErrorCb, extraParams);
  },
  getParkSites(groupId, onResponseCb, onErrorCb) {
    const url = ApiHelper.createUrl(TARGET_API, groupId, API_SITES);
    logger.d(LOG_TAG, 'getParkSites', url);

    ApiHelper.apiGet(url, onResponseCb, onErrorCb);
  },
  getParkSiteInfo(groupId, siteId, onResponseCb, onErrorCb) {
    const url = ApiHelper.createUrl(TARGET_API, groupId, API_SITE, siteId);
    logger.d(LOG_TAG, 'getParkSiteInfo', url);

    ApiHelper.apiGet(url, onResponseCb, onErrorCb);
  },
  putParkSiteInfo(groupId, siteId, payload, onResponseCb, onErrorCb) {
    const url = ApiHelper.createUrl(TARGET_API, groupId, API_SITE, siteId);
    logger.d(LOG_TAG, 'putParkSiteInfo', url);

    ApiHelper.apiPut(url, payload, onResponseCb, onErrorCb);
  },
  postParkSiteSlot(groupId, siteId, payload, onResponseCb, onErrorCb) {
    const url = ApiHelper.createUrl(TARGET_API, groupId, API_SITE, siteId, API_SLOT);
    logger.d(LOG_TAG, 'postParkSiteSlot', url);

    ApiHelper.apiPost(url, payload, onResponseCb, onErrorCb);
  },
  getParkSlotInfo(groupId, siteId, slotId, onResponseCb, onErrorCb) {
    const url = ApiHelper.createUrl(TARGET_API, groupId, API_SITE, siteId, API_SLOT, slotId);
    logger.d(LOG_TAG, 'getParkSlotInfo', url);

    ApiHelper.apiGet(url, onResponseCb, onErrorCb);
  },
  putParkSlotInfo(groupId, siteId, slotId, payload, onResponseCb, onErrorCb) {
    const url = ApiHelper.createUrl(TARGET_API, groupId, API_SITE, siteId, API_SLOT, slotId);
    logger.d(LOG_TAG, 'putParkSlotInfo', url);

    ApiHelper.apiPut(url, payload, onResponseCb, onErrorCb);
  },
  delParkSlotInfo(groupId, siteId, slotId, onResponseCb, onErrorCb) {
    const url = ApiHelper.createUrl(TARGET_API, groupId, API_SITE, siteId, API_SLOT, slotId);
    logger.d(LOG_TAG, 'delParkSlotInfo', url);

    ApiHelper.apiDelete(url, onResponseCb, onErrorCb);
  },
  putParkSlotSensor(groupId, siteId, slotId, payload, onResponseCb, onErrorCb) {
    const url = ApiHelper.createUrl(TARGET_API, groupId, API_SITE, siteId, API_SLOT, slotId, API_SENSOR);
    logger.d(LOG_TAG, 'putParkSlotSensor', url);

    ApiHelper.apiPut(url, payload, onResponseCb, onErrorCb);
  },
  getSlotReservations(groupId, siteId, slotId, onResponseCb, onErrorCb) {
    const url = ApiHelper.createUrl(TARGET_API, groupId, API_SITE, siteId, API_SLOT, slotId, API_RESERVATIONS);
    logger.d(LOG_TAG, 'getSlotReservations', url);

    ApiHelper.apiGet(url, onResponseCb, onErrorCb);
  },
  postSlotReservation(groupId, siteId, slotId, payload, onResponseCb, onErrorCb) {
    const url = ApiHelper.createUrl(TARGET_API, groupId, API_SITE, siteId, API_SLOT, slotId, API_RESERVATION);
    logger.d(LOG_TAG, 'postSlotReservation', url);

    ApiHelper.apiPost(url, payload, onResponseCb, onErrorCb);
  },
  delSlotReservation(groupId, siteId, slotId, reserveId, onResponseCb, onErrorCb) {
    const url = ApiHelper.createUrl(TARGET_API, groupId, API_SITE, siteId, API_SLOT, slotId, API_RESERVATION, reserveId);
    logger.d(LOG_TAG, 'delSlotReservation', url);

    ApiHelper.apiDelete(url, onResponseCb, onErrorCb);
  },
};
