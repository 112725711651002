import React, {Component} from 'react';
import LoadingOverlay from 'react-loading-overlay';

class ContentHost extends Component {
  render() {
    return (
      <div>
        <LoadingOverlay
          active={this.props.loading}
          spinner
          styles={{
            overlay: base => ({
              ...base,
              marginTop: '160px',
              backgroundColor: 'transparent',
              '& svg circle': {
                stroke: 'var(--clr-primary)',
              },
            }),
          }}>
          {this.props.children}
        </LoadingOverlay>
      </div>
    );
  }
}

export default ContentHost;
