import React from 'react';
import {CardElement} from 'react-stripe-elements';
import logger from '../../utils/logger';

const LOG_TAG = 'CardSection';
class CardSection extends React.Component {
  render() {
    return (
      <div>
        <label>Card details</label>
        <div className="form-control">
          <CardElement hidePostalCode={true} onReady={this.onReady}/>
        </div>

      </div>
    );
  }
  onReady = e => {
    logger.d(LOG_TAG, 'onReady');
  }
}

export default CardSection;
