import localStorageHelper from '../../utils/localStorageHelper';
import StateInfo from '../../store/states/index';
import logger from '../../utils/logger';

const LOG_TAG = 'store.action';

export const ACTIONS = {
  SET_TAB: 'SET_TAB',
  SET_LOCATION: 'SET_LOCATION',
  SET_AUTH: 'SET_AUTH',
  SET_AUTH_USER: 'SET_AUTH_USER',
  SET_AUTH_USER_GROUPS: 'SET_AUTH_USER_GROUPS',
  SET_USER: 'SET_USER',
  SET_LAST_SESSION: 'SET_LAST_SESSION',
  SET_LOADING: 'SET_LOADING',
  SET_NOTIFICATION: 'SET_NOTIFICATION',
  SET_NOTIFY_START_PARKING: 'SET_NOTIFY_START_PARKING',
};
export const setTab = tabInfo => ({
  type: ACTIONS.SET_TAB,
  tabInfo: tabInfo
});
export const setLocation = locationInfo => ({
  type: ACTIONS.SET_LOCATION,
  locationInfo: locationInfo
})
export function setAuth(authInfo) {
  logger.d(LOG_TAG, 'setAuth', authInfo);
  localStorageHelper.setAuthInfo(authInfo);
  if (authInfo) {
    const expiresReadable = window.moment(authInfo.jwt.expires * 1000).format('MMMM Do YYYY, h:mm');
    logger.d(LOG_TAG, 'setAuth', 'expires', expiresReadable);
  }

  // is mock?
  if (StateInfo.AuthInfo.isValidIdentity(authInfo) && StateInfo.AuthInfo.isMockIdentity(authInfo.identities[0])) {
    localStorageHelper.setIsMock(true);
  } else {
    localStorageHelper.setIsMock(null);
  }
  return {
    type: ACTIONS.SET_AUTH,
    authInfo: authInfo
  }
}
export function setAuthUser(userInfo) {
  logger.d(LOG_TAG, 'setAuthUser', userInfo);
  localStorageHelper.setAuthUserInfo(userInfo);

  return {
    type: ACTIONS.SET_AUTH_USER,
    userInfo: userInfo
  }
}
export function setAuthUserGroups(userGroups) {
  logger.d(LOG_TAG, 'setAuthUserGroups', userGroups);
  localStorageHelper.setAuthUserGroups(userGroups);
  if (!userGroups) {
    localStorageHelper.setLastGroup(null);
  }
  return {
    type: ACTIONS.SET_AUTH_USER_GROUPS,
    userGroups: userGroups
  }
}
export function setUser(userInfo) {
  logger.d(LOG_TAG, 'setUser', userInfo);
  localStorageHelper.setUserInfo(userInfo);
  return {
    type: ACTIONS.SET_USER,
    userInfo: userInfo
  }
}
export function setLastSession(lastSessionInfo) {
  logger.d(LOG_TAG, 'setLastSession', lastSessionInfo);
  localStorageHelper.setLastSessionInfo(lastSessionInfo);
  return {
    type: ACTIONS.SET_LAST_SESSION,
    lastSessionInfo: lastSessionInfo
  }
}
export const setLoading = loadingInfo => ({
  type: ACTIONS.SET_LOADING,
  loadingInfo: loadingInfo
});
export const setNotification = notificationInfo => ({
  type: ACTIONS.SET_NOTIFICATION,
  notificationInfo: notificationInfo
});
export const setNotifyStartParking = notifyStartParking => ({
  type: ACTIONS.SET_NOTIFY_START_PARKING,
  notifyStartParking: notifyStartParking
});
export default {
  setTab,
  setLocation,
  setAuth,
  setAuthUser,
  setUser,
  setLastSession,
  setLoading,
  setNotification,
  setNotifyStartParking
};
