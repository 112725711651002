import logger from '../utils/logger';
import Routes from '../routes';

const LOG_TAG = 'accessHelper';
export default {
  resolveUrlTarget(isSct, target) {
    const location = {
      tab: '',
      menu: '',
    };
    const tab = target.t;
    const menu = target.m;
    const tabUm = Routes.main[Routes.INDEX_UM];
    const tabPa = Routes.main[Routes.INDEX_PA];
    const tabAu = Routes.main[Routes.INDEX_AU];
    if (isSct) {
      if (tab === tabUm.id || tab === tabPa.id || tab === tabAu.id) {
        location.tab = tab;
      }
    } else {
      if (tab === tabPa.id || tab === tabAu.id) {
        location.tab = tab;
      }
    }
    logger.d(LOG_TAG, 'resolveUrlTarget', isSct, target, tab, menu);
    return location;
  },
  resolveUrlTargetExpired(target) {
    const expired = target.expired;
    return target.expired;
  },
  validateTab(tab) {
    if (
      tab !== 'userManagement' &&
      tab !== 'parkingAssets' &&
      tab !== 'accountUsers'
    ) {
      tab = 'userManagement';
    }
    return tab;
  },
  validateMenu(tab, menu) {
    if (tab === 'userManagement') {
      if (
        menu !== 'parkingStatus' &&
        menu !== 'profile' &&
        menu !== 'vipPlates'
      ) {
        menu = 'parkingStatus';
      }
    } else if (tab === 'parkingAssets') {
      if (
        menu !== 'parkingSites' &&
        menu !== 'siteSlots'
      ) {
        menu = 'parkingSites';
      }
    } else if (tab === 'accountUsers') {
      if (
        menu !== 'account' &&
        menu !== 'user'
      ) {
        menu = 'account';
      }
    }

    return menu;
  },
  formatMainPathInfo(tab, menu) {
    return `/main/${tab}/${menu}`;
  },
  // sct user gets full access control
  isSct(userInfo) {
    if (userInfo && userInfo.is_sct) {
      return true;
    }
    return false;
  },
  hasManagedGroups(groups) {
    let result = false;
    if (groups && groups.length > 0) {
      result = true;
    }
    return result;
  },
  // manager gets restricted editing access
  isManager(userInfo, groups, groupId) {
    let result = false;
    if (userInfo && groups) {
      const matchGroup = groups.filter((group) => group.group_id === groupId);
      if (matchGroup.length > 0 && matchGroup[0].managers) {
        const matchManager = matchGroup[0].managers.filter((userId) => userId === userInfo.user_id);
        if (matchManager.length > 0) {
          result = true;
        }
      }
    }
    return result;
  },
  isInternalTester(userInfo) {
    let result = false;
    if (userInfo) {
      result =
        userInfo.email === 'oni@gmail.com' ||
        userInfo.email === 'aaron.admin@bettersense.io' ||
        userInfo.email === 'sam@bettersense.io' ||
        userInfo.email === 'igor@bettersense.io';
    }
    return result;
  }
};
