import logger from '../utils/logger';

const LOG_TAG = 'syncParkSites';
export default {
  execute(onCompleteCb) {
    logger.d(LOG_TAG, 'execute');
    window.webapi.Query.querySites(
      (response) => {
        const data = response.data;
        if (onCompleteCb) {
          onCompleteCb(true, data);
        }
      },
      (error) => {
        if (onCompleteCb) {
          onCompleteCb(false, []);
        }
      }
    )
  },
};
