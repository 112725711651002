import React, {Component, useEffect} from 'react';
import connect from 'react-redux/es/connect/connect';
import imgLocationOn from '../../../assets/images/location_on.png';
import imgLocationOff from '../../../assets/images/location_off.png';
import {setLoading} from '../../../store/actions';
import GoogleMap from '../../../components/google/map';
import syncParkSites from '../../../handlers/syncParkSites';
import syncParkSlots from '../../../handlers/syncParkSlots';
import syncUserParking from '../../../handlers/syncUserParking';
import syncParkSlotAvailableTime from '../../../handlers/syncParkSlotAvailableTime';
import logger from '../../../utils/logger';
import StateInfo from '../../../store/states';
import slotHelper from '../../../utils/slotHelper';
import dataHelper from '../../../utils/dataHelper';
import {useBus, useListener} from 'react-bus'

const LOG_TAG = 'SiteProfile';
const EventBusBinder = ({onRefreshCb}) => {
  const bus = useBus();
  useListener('ev_on_refresh', (event) => {
    logger.d(LOG_TAG, 'EventBusBinder', 'ev_on_refresh', event);
    onRefreshCb();
  });
  useEffect(() => {
    bus.emit('ev_enable_fab_refresh', true);
    return () => {
      bus.emit('ev_enable_fab_refresh', false);
    };
  }, []);
  const el = React.useRef(null)
  return <div ref={el}></div>
};

class SiteProfile extends Component {
  static defaultProps = {
    center: {
      lat: -36.894681,
      lng: 174.742858
    },
    zoom: 12
  };
  constructor(props) {
    super(props);
    this.state = {
      googleApi: null,
      markers: [],
      // park info
      parkSites: [],
      parkSiteSelected: 'select',

      parkSlots: [],
      parkSlotSelected: 'select',
      parkSlotTimeAvailable: null,

      userInfo: null,
      userParkInfo: null, // active parking session
    };
  }
  componentDidMount() {
    logger.d(LOG_TAG, 'componentDidMount');
    this.resetState();
  }
  componentDidUpdate(oldProps) {
    const newProps = this.props;
    if (oldProps.userInfo !== newProps.userInfo) {
      logger.d(LOG_TAG, 'componentDidUpdate', 'userInfo', oldProps.userInfo, newProps.userInfo);
      this.resetState();
    } else if (oldProps.notifyStartParking !== newProps.notifyStartParking) {
      logger.d(LOG_TAG, 'componentDidUpdate', 'notifyStartParking', oldProps.notifyStartParking, newProps.notifyStartParking);
      this.resetState();
    }
  };
  componentWillUnmount () {
  }
  resetState(loading) {
    logger.d(LOG_TAG, 'resetState');
    if (loading) {
      this.props.setLoading(StateInfo.LoadingInfo.format(true, 'Please wait...'));
    }
    syncParkSites.execute(
      (result, value) => {
        logger.d(LOG_TAG, 'resetState', 'syncParkSites', result, value);
        const newMarkers = [];
        if (result) {
          value.forEach((site) => {
            newMarkers.push({
              position: {
                lat: parseFloat(site.gps_location.lat),
                lng: parseFloat(site.gps_location.lon)
              },
              icon: site.slots_available > 0 ? imgLocationOn : imgLocationOff
            });
            //
            // test
            //
            /*
            if (newMarkers.length % 2 === 0) {
              site.initial_duration = 30;
              site.initial_rate = 50;

              site.fixed_rates = [
                {
                  day: 'Monday',
                  windows: [
                    {
                      start: '00:00',
                      end: '03:00',
                      offer_end: '01:00',
                      description: 'Early Bird',
                      price: 50
                    },
                  ]
                },
                {
                  day: 'Tuesday',
                  windows: [
                    {
                      start: '00:00',
                      end: '03:00',
                      offer_end: '01:00',
                      description: 'Early Bird',
                      price: 50
                    },
                  ]
                },
                {
                  day: 'Wednesday',
                  windows: [
                    {
                      start: '00:00',
                      end: '03:00',
                      offer_end: '01:00',
                      description: 'Early Bird',
                      price: 50
                    },
                  ]
                },
                {
                  day: 'Thursday',
                  windows: [
                    {
                      start: '00:00',
                      end: '03:00',
                      offer_end: '01:00',
                      description: 'Early Bird',
                      price: 50
                    },
                  ]
                },
                {
                  day: 'Friday',
                  windows: [
                    {
                      start: '00:00',
                      end: '03:00',
                      offer_end: '01:00',
                      description: 'Early Bird',
                      price: 50
                    },
                  ]
                },
                {
                  day: 'Saturday',
                  windows: [
                    {
                      start: '00:00',
                      end: '03:00',
                      offer_end: '01:00',
                      description: 'Early Bird',
                      price: 50
                    },
                  ]
                }
              ];
            } */
          });
        }
        if (result) {
          value.sort((a, b) => a.name.localeCompare(b.name));
        }
        this.setState({
          parkSites: result ? value : [],
          parkSiteSelected: 'select',
          parkSlots: [],
          parkSlotSelected: 'select',
          markers: newMarkers,
        });

        // sync again userInfo if available
        this.resetUserProfile(this.props.userInfo);
        if (loading) {
          setTimeout(() => {
            this.props.setLoading(StateInfo.LoadingInfo.format(false, ''));
          }, 1200);
        }
      }
    );
  };
  resetUserProfile(userInfo) {
    logger.d(LOG_TAG, 'resetUserProfile', userInfo);
    if (userInfo) {
      syncUserParking.execute(
        userInfo,
        (result, value) => {
          logger.d(LOG_TAG, 'resetUserProfile', result, value);
          if (result) {
            const siteIndex = this.state.parkSites.findIndex((site) => site.site_id === value.site_id);
            if (siteIndex !== -1) {
              this.setState({
                parkSiteSelected: siteIndex,
                userInfo: userInfo,
                userParkInfo: value,
              });
              this.resetParkSlot(this.state.parkSites[siteIndex], value.slot_id);
            }
          } else {
            this.setState({
              userInfo: null,
              userParkInfo: null,
            });
          }
        }
      )
    } else {
      this.setState({

        parkSlotSelected: 'select',
        parkSlotTimeAvailable: null,

        userInfo: null,
        userParkInfo: null,
      });
    }
  };
  resetParkSlot(siteInfo, defaultSlot) {
    logger.d(LOG_TAG, 'resetParkSlot', siteInfo, defaultSlot);
    syncParkSlots.execute(
      siteInfo.site_id,
      (result, value) => {
        logger.d(LOG_TAG, 'resetParkSlot', syncParkSlots, result, value.length);
        if (value) {
          value.sort((a, b) => (`${dataHelper.paddingZero(a.number, 3)}` > `${dataHelper.paddingZero(b.number, 3)}`) ? 1 : -1);
        }
        let parkSlots = [];
        let parkSlotSelected = 'select';
        let slotIndex = -1;
        if (result && defaultSlot) {
          slotIndex = value.findIndex((slot) => slot.slot_id === defaultSlot);
        }
        if (slotIndex !== -1) {
          parkSlots = value;
          parkSlotSelected = slotIndex
        } else {
          value.forEach((slot) => {
            if (slotHelper.isVisible(slot) === true) {
              parkSlots.push(slot);
            }
          });
        }
        const newState = {
          parkSlots: result ? parkSlots : [],
          parkSlotSelected: parkSlotSelected,
        };
        this.setState(newState);
        if (this.props.onSelectParkSite) {
          this.props.onSelectParkSite(siteInfo);
        }
        if (this.props.onSelectParkSlot) {
          this.props.onSelectParkSlot(null, 0);
        }
        if (newState.parkSlotSelected !== 'select') {
          const parkSlot = value[newState.parkSlotSelected];
          this.resetParkSlotAvailableTime(parkSlot);
        }
      }
    );
  };
  render() {
    return (
      <div className="card">
        <EventBusBinder
          onRefreshCb={this.onClickRefreshSite}>
        </EventBusBinder>
        <div className="card-body">
          <div style={{ width: '100%', height: '21rem'}}>
            <GoogleMap
              markers={this.state.markers}
              onClickMarker={this.onClickMarker}
              selected={
                this.state.parkSiteSelected !== 'select' ?
                  this.state.parkSiteSelected : 0
              }>

            </GoogleMap>
          </div>
          {/* map info section */}
          <div className="col-md-12 mt-3">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="">Select site</label>
                  <select
                    className="form-control"
                    value={this.state.parkSiteSelected}
                    disabled={this.state.userParkInfo !== null}
                    onChange={this.onSelectParkSite}>
                    <option value="select">Select Park Site</option>
                    {
                      this.state.parkSites.map((site, index) => (
                        <option
                          key={index}
                          value={index}>{site.name}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="">Select Space</label>
                  <select
                    className="form-control"
                    value={this.state.parkSlotSelected}
                    disabled={this.state.userParkInfo !== null}
                    onChange={this.onSelectParkSlot}>
                    <option value="select">Select Space</option>
                    {
                      this.state.parkSiteSelected !== 'select' ?
                        this.state.parkSlots.map((slot, index) => (
                          <option
                            key={index}
                            value={index}>{slot.number}</option>
                        ))
                        : null
                    }
                  </select>
                  {
                    this.state.userParkInfo ?
                      <small className="clr-primary">Parking session in progress</small>
                      :
                      this.state.parkSlotTimeAvailable ?
                        <div>
                          {
                            this.state.parkSlotTimeAvailable.err_message ?
                              <small className="clr-warning">{this.state.parkSlotTimeAvailable.err_message}</small>
                              :
                              <small>{this.state.parkSlotTimeAvailable.message}</small>
                          }
                        </div>
                        : <small className="clr-secondary">Availability: Select a space</small>
                  }
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="">Site Details</label>
                  <div>
                    {
                      (this.state.parkSiteSelected !== 'select' && this.state.parkSites[this.state.parkSiteSelected].initial_duration > 0) &&
                      <small className="clr-warning">{dataHelper.formatInitialPromoRate(this.state.parkSites[this.state.parkSiteSelected])}</small>
                    }
                  </div>
                  <div>
                    {
                      (this.state.parkSiteSelected !== 'select' && this.state.parkSites[this.state.parkSiteSelected].fixed_rates) &&
                      <small className="clr-warning">{dataHelper.formatFixedRates(this.state.parkSites[this.state.parkSiteSelected])}</small>
                    }
                  </div>
                  <textarea
                    className="form-control"
                    rows="5"
                    disabled={true}
                    value={this.getParkSiteDescription()}/>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  onClickRefreshSite = () => {
    logger.d(LOG_TAG, 'onClickRefreshSite');
    this.resetState(true);
  };
  onSelectParkSite = value => {
    const pId  = value.target.value.toString();
    if (pId === 'select') {
      this.setState({
        parkSiteSelected: pId,
        parkSlots: [],
        parkSlotSelected: 'select',
        parkSlotTimeAvailable: null,
        durationDomain: [0, 1],
        durationStep: 1,
      });
    } else {
      const siteInfo = this.state.parkSites[pId];
      logger.d(LOG_TAG, 'onSelectParkSite', pId, siteInfo);

      this.setState({
        parkSiteSelected: pId,
        parkSlotSelected: 'select',
        parkSlotTimeAvailable: null,
        durationDomain: [siteInfo.min_duration, siteInfo.max_duration],
        durationStep: siteInfo.min_duration,
      });
      this.resetParkSlot(this.state.parkSites[pId], null);
    }

  };
  onSelectParkSlot = value => {
    const selected = value.target.value;
    logger.d(LOG_TAG, 'onSelectParkSlot', selected);

    this.setState({
      parkSlotSelected: selected,
      parkSlotTimeAvailable: null,
    });
    if (selected !== 'select') {
      const parkSlot = this.state.parkSlots[selected];
      this.resetParkSlotAvailableTime(parkSlot);
    }
  };
  resetParkSlotAvailableTime(parkSlot) {
    syncParkSlotAvailableTime.execute(
      parkSlot.slot_id,
      (result, value) => {
        if (result) {
          let availableTime = '';
          const time = window.moment.duration(value.available_time, "minutes").format("h:mm");
          logger.d(LOG_TAG, 'resetParkSlotAvailableTime', 'onResponse', time);

          const timeSplit = time.split(':');
          if (timeSplit.length === 1) {
            availableTime = `${timeSplit[0]} minutes`
          } else {
            availableTime = `${timeSplit[0]} hour(s) and  ${timeSplit[1]} minute(s)`
          }
          this.setState({
            parkSlotTimeAvailable: {
              message: `Availability: ${availableTime}`,
            }
          });
          /* test fixed rate */
          /*
          value.fixed_rates_available = [
            {
              day: 'Monday',
              windows: [
                {
                  code: 'earlybird',
                  start: '04:00',
                  end: '15:00',
                  offer_end: '06:00',
                  description: 'Early Bird',
                  price: 100
                },
                {
                  code: 'day',
                  start: '07:00',
                  end: '15:00',
                  offer_end: '09:00',
                  description: 'Day Parking',
                  price: 200
                },
              ],
            },
          ] */
          if (this.props.onSelectParkSlot) {
            this.props.onSelectParkSlot(parkSlot, value.available_time, value.fixed_rates_available);
          }
        } else {
          this.setState({
            parkSlotTimeAvailable: {
              err_message: value.err_message
            }
          });
          if (this.props.onSelectParkSlot) {
            this.props.onSelectParkSlot(parkSlot, 0);
          }
        }
      },
      this.state.userParkInfo !== null
    );
  };
  getParkSiteDescription = value => {
    if (this.state.parkSiteSelected === 'select') {
      return '';
    } else {
      const selected = this.state.parkSites[this.state.parkSiteSelected];
      const description = selected.description;

      let availability = ' Availability: No slots available';
      if (selected.slots_available && selected.slots_total) {
        availability = ` Availability: ${selected.slots_available}/${selected.slots_total}`;
      }
      const rate = `Rate: $${((selected.base_price * (60 / selected.min_duration)) / 100).toFixed(2)} / hour`;
      const minDuration = `Min Duration: ${selected.min_duration} minutes`;
      const maxDuration = `Max Duration: ${selected.max_duration} minutes`;

      return `${description}\n-${availability}\n- ${rate}\n- ${minDuration}\n- ${maxDuration}`;
    }
  };
  onClickMarker = param => {
    logger.d(LOG_TAG, 'onClickMarker');
    if (this.state.userParkInfo) {
      return;
    }
    const pId = param.toString();
    const siteInfo = this.state.parkSites[pId];
    logger.d(LOG_TAG, 'onClickMarker', param, siteInfo);
    this.setState({
      parkSiteSelected: pId,
      parkSlotSelected: 'select',
      durationDomain: [siteInfo.min_duration, siteInfo.max_duration],
      durationStep: siteInfo.min_duration,
    });
    this.resetParkSlot(this.state.parkSites[pId], null);
  };
  onMapChange = () => {
  };
  onGoogleApiLoaded = value => {
    if (!this.state.googleApi) {
      this.setState({
        googleApi: value.map,
      });
      logger.d(LOG_TAG, 'onGoogleApiLoaded', value.map);
    }
  };
}
// listens to state change
const mapStateToProps = state => {
  const notifyStartParking = state.notifyStartParking;
  return {
    notifyStartParking,
  };
};
export default (connect(
  mapStateToProps,
  {
    setLoading
  }
)(SiteProfile));


