import Binder from './index';
import logger from '../../utils/logger';

const LOG_TAG = 'binder.group';
export default {
  get(groupId, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'get', groupId);
    Binder.WebApi().Group.get(groupId, onResponseCb, onErrorCb);
  },
  getParkSites(groupId, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'getParkSites', groupId);
    Binder.WebApi().Group.getParkSites(groupId, onResponseCb, onErrorCb);
  },
  getParkSiteInfo(groupId, siteId, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'getParkSiteInfo', groupId);
    Binder.WebApi().Group.getParkSiteInfo(groupId, siteId, onResponseCb, onErrorCb);
  },
  putParkSiteInfo(groupId, siteId, payload, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'putParkSiteInfo', groupId, siteId, payload);
    Binder.WebApi().Group.putParkSiteInfo(groupId, siteId, payload, onResponseCb, onErrorCb);
  },
  postParkSiteSlot(groupId, siteId, payload, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'postParkSiteSlot', groupId, siteId);
    Binder.WebApi().Group.postParkSiteSlot(groupId, siteId, payload, onResponseCb, onErrorCb);
  },
  getParkSlotInfo(groupId, siteId, slotId, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'getParkSlotInfo', groupId, siteId, slotId);
    Binder.WebApi().Group.getParkSlotInfo(groupId, siteId, slotId, onResponseCb, onErrorCb);
  },
  putParkSlotInfo(groupId, siteId, slotId, payload, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'putParkSlotInfo', groupId, siteId, slotId);
    Binder.WebApi().Group.putParkSlotInfo(groupId, siteId, slotId, payload, onResponseCb, onErrorCb);
  },
  delParkSlotInfo(groupId, siteId, slotId, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'delParkSlotInfo', groupId, siteId, slotId);
    Binder.WebApi().Group.delParkSlotInfo(groupId, siteId, slotId, onResponseCb, onErrorCb);
  },
  putParkSlotSensor(groupId, siteId, slotId, payload, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'putParkSlotSensor', groupId, siteId);
    Binder.WebApi().Group.putParkSlotSensor(groupId, siteId, slotId, payload, onResponseCb, onErrorCb);
  },
  getSlotReservations(groupId, siteId, slotId, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'getSlotReservations', groupId, siteId, slotId);
    Binder.WebApi().Group.getSlotReservations(groupId, siteId, slotId, onResponseCb, onErrorCb);
  },
  postSlotReservation(groupId, siteId, slotId, payload, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'postSlotReservation', groupId, siteId);
    Binder.WebApi().Group.postSlotReservation(groupId, siteId, slotId, payload, onResponseCb, onErrorCb);
  },
  delSlotReservation(groupId, siteId, slotId, reserveId, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'delSlotReservation', groupId, siteId);
    Binder.WebApi().Group.delSlotReservation(groupId, siteId, slotId, reserveId, onResponseCb, onErrorCb);
  }
};
