import React, {Component} from 'react';
import localStorageHelper from "../utils/localStorageHelper";
import StateInfo from "../store/states";
import accessHelper from "../utils/accessHelper";
import logger from "../utils/logger";
import Routes from "../routes";

const LOG_TAG = 'Redirect';
class Redirect extends Component {
  componentDidMount() {
    const authInfo = localStorageHelper.getAuthInfo();
    const authUserInfo = localStorageHelper.getAuthUserInfo();
    const isAuthenticated = StateInfo.AuthInfo.isValid(authInfo);
    const isSct = accessHelper.isSct(authUserInfo);

    logger.d(LOG_TAG, 'componentDidMount', isAuthenticated, isSct);
    if (isAuthenticated) {
      if (accessHelper.isSct(authUserInfo)) {
        this.props.history.replace(Routes.mainRoot.path+'?t=um');
      } else {
        this.props.history.replace(Routes.mainRoot.path+'?t=pa');
      }
    } else {
      this.props.history.replace(Routes.login.path);
    }
  }
  render() {
    return (
      <div className="container mt-3">
      </div>
    );
  }
}

export default Redirect;

