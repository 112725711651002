import ApiHelper from '../apiHelper';
import logger from '../../utils/logger';

const LOG_TAG = 'api.admin';
const TARGET_API = 'api/admin';
const API_USER = 'user';
const API_LOCAL_USER = 'local-users';
const API_GROUP = 'group';
const API_SITE = 'site';
const API_SEARCH = 'search';
const API_CAR_PLATE = 'car_plate';
export default {
  postUser(payload, onResponseCb, onErrorCb) {
    const url = ApiHelper.createUrl(TARGET_API, API_USER);
    logger.d(LOG_TAG, 'postUser', payload);

    const extraParams = {};
    ApiHelper.apiPost(url, payload, onResponseCb, onErrorCb, extraParams);
  },
  getUser(userId, onResponseCb, onErrorCb) {
    const url = ApiHelper.createUrl(TARGET_API, API_USER, userId);
    logger.d(LOG_TAG, 'getUser', userId);

    const extraParams = {};
    ApiHelper.apiGet(url, onResponseCb, onErrorCb, extraParams);
  },
  putUser(userId, payload, onResponseCb, onErrorCb) {
    const url = ApiHelper.createUrl(TARGET_API, API_USER, userId);
    logger.d(LOG_TAG, 'putUser', userId);

    const extraParams = {};
    ApiHelper.apiPut(url, payload, onResponseCb, onErrorCb, extraParams);
  },
  delUser(userId, onResponseCb, onErrorCb) {
    const url = ApiHelper.createUrl(TARGET_API, API_USER, userId);
    logger.d(LOG_TAG, 'delUser', userId);

    const extraParams = {};
    ApiHelper.apiDelete(url, onResponseCb, onErrorCb, extraParams);
  },
  getLocalUsers(onResponseCb, onErrorCb) {
    const url = ApiHelper.createUrl(TARGET_API, API_LOCAL_USER);
    logger.d(LOG_TAG, 'getLocalUsers');

    const extraParams = {};
    ApiHelper.apiGet(url, onResponseCb, onErrorCb, extraParams);
  },
  postGroup(payload, onResponseCb, onErrorCb) {
    const url = ApiHelper.createUrl(TARGET_API, API_GROUP);
    logger.d(LOG_TAG, 'postGroup', payload);

    const extraParams = {};
    ApiHelper.apiPost(url, payload, onResponseCb, onErrorCb, extraParams);
  },
  /*
  getGroup(groupId, onResponseCb, onErrorCb) {
    const url = ApiHelper.createUrl(TARGET_API, API_GROUP, groupId);
    logger.d(LOG_TAG, 'getGroup', groupId);

    const extraParams = {};
    ApiHelper.apiGet(url, onResponseCb, onErrorCb, extraParams);
  },
  */
  putGroup(groupId, payload, onResponseCb, onErrorCb) {
    const url = ApiHelper.createUrl(TARGET_API, API_GROUP, groupId);
    logger.d(LOG_TAG, 'putGroup', groupId, payload);

    const extraParams = {};
    ApiHelper.apiPut(url, payload, onResponseCb, onErrorCb, extraParams);
  },
  postGroupSite(groupId, payload, onResponseCb, onErrorCb) {
    const url = ApiHelper.createUrl(TARGET_API, API_GROUP, groupId, API_SITE);
    logger.d(LOG_TAG, 'postGroupSite', groupId, payload);

    const extraParams = {};
    ApiHelper.apiPost(url, payload, onResponseCb, onErrorCb, extraParams);
  },
  delGroupSite(groupId, siteId, onResponseCb, onErrorCb) {
    const url = ApiHelper.createUrl(TARGET_API, API_GROUP, groupId, API_SITE, siteId);
    logger.d(LOG_TAG, 'delGroupSite', groupId, siteId);

    const extraParams = {};
    ApiHelper.apiDelete(url, onResponseCb, onErrorCb, extraParams);
  },
  searchEmail(email, onResponseCb, onErrorCb) {
    const url = ApiHelper.createUrl(TARGET_API, API_SEARCH, email);
    logger.d(LOG_TAG, 'searchEmail', email);

    const extraParams = {};
    ApiHelper.apiGet(url, onResponseCb, onErrorCb, extraParams);
  },
  searchCarPlate(carPlate, onResponseCb, onErrorCb) {
    const url = ApiHelper.createUrl(TARGET_API, API_SEARCH, API_CAR_PLATE, carPlate);
    logger.d(LOG_TAG, 'searchCarPlate', carPlate);

    const extraParams = {};
    ApiHelper.apiGet(url, onResponseCb, onErrorCb, extraParams);
  }
};
