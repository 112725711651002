import React, {Component} from 'react';
import connect from 'react-redux/es/connect/connect';
import AnimPage from '../../../components/anim/animPage';
import {setUser, setLastSession} from '../../../store/actions';
import CardSearchUserSessionByCarPlate from '../../../components/cardSearchUserSessionByCarPlate';
import SessionProfile from './sessionProfile';
import localStorageHelper from '../../../utils/localStorageHelper';
import logger from '../../../utils/logger';

const LOG_TAG = 'parkingStatus';

class ParkingStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // profile
      userInfo: null,
      lastSessionInfo: null,
      vipSites: null,
      lastProfile: null,
    };
  }
  componentDidMount() {
    logger.d(LOG_TAG, 'componentDidMount');
  }
  componentDidUpdate(oldProps) {
    logger.d(LOG_TAG, 'componentDidUpdate');
    const newProps = this.props;
    if (oldProps.userInfo !== newProps.userInfo ||
      oldProps.lastSessionInfo !== newProps.lastSessionInfo) {
      this.resetState();
    }
  }
  resetState() {
    const userInfo = this.props.userInfo;
    const lastSessionInfo = this.props.lastSessionInfo;

    logger.d(LOG_TAG, 'resetState', userInfo, lastSessionInfo);
    this.setState({
      userInfo: userInfo,
      lastSessionInfo: lastSessionInfo,
    });
  };
  render() {
    return (
      <AnimPage>
        <div className="container-fluid p-0 mt-3">
          <div className="row app-main__content">
            <div className="col-md-8 app-main__content__card">
              <CardSearchUserSessionByCarPlate
                userInfo={this.state.userInfo}
                onResult={this.onSearchUserSessionResult}/>
              {
                this.state.userInfo && (
                  <SessionProfile
                    userInfo={this.state.userInfo}
                    lastSessionInfo={this.state.lastSessionInfo}
                    vipSites={this.state.vipSites}
                    lastProfile={this.state.lastProfile}>
                  </SessionProfile>
                )
              }
            </div>
          </div>
        </div>
      </AnimPage>
    );
  }
  onSearchUserSessionResult = (userInfo, sessionInfo, vipSites, lastProfile) => {
    logger.d(LOG_TAG, 'onSearchResult', userInfo, sessionInfo, vipSites);
    // this.props.setUser(userInfo);
    // this.props.setLastSession(sessionInfo);
    this.setState({
      userInfo: userInfo,
      lastSessionInfo: sessionInfo,
      vipSites: vipSites,
      lastProfile: lastProfile,
    });
  };
}

// listens to state change
const mapStateToProps = state => {
  const userInfo = state.userInfo;
  const lastSessionInfo = state.lastSessionInfo;
  return {
    userInfo,
    lastSessionInfo,
  };
};

export default connect(
  mapStateToProps,
  {
    setUser,
    setLastSession
  }
)(ParkingStatus);

