import localStorageHelper from '../utils/localStorageHelper';

const FORMAT_ERROR_RESPONSE = () => {
  return {
    data: {
      err_code: -1,
      err_message: 'Invalid response data',
      }
    }
};
export default {
  LOG_TAG: 'WebApi:',
  ERR_API_RESP: 'Error response from API',
  ERR_API_COMM: 'Error communicating with API',
  targetEndpoint() {
    return process.env.REACT_APP_API_SRC;
  },
  apiBaseHeader() {
    const authInfo = localStorageHelper.getAuthInfo();
    if (authInfo && authInfo.jwt && authInfo.jwt.token) {
      const object = {
        headers: {
          'Authorization': 'Bearer ' + authInfo.jwt.token,
        },
      };
      return object;
    }
    return null;
  },
  createUrl(...args) {
    let url = this.targetEndpoint();
    for (let index = 0; index < args.length; index += 1) {
      url += args[index];
      if (index < args.length - 1) {
        url += '/';
      }
    }
    return url;
  },
  apiGet(url, onResponseCb, onErrorCb, extraParams) {
    const vm = this;
    let requireDataArray = false;
    if (extraParams && extraParams.require_data_array === true) {
      requireDataArray = true;
    }
    //
    // validate result
    const onResponse = function onResponse(response) {
      if (response && response.data) {
        if (requireDataArray === true) {
          if (response.data.constructor === Array) {
            onResponseCb(response);
          } else {
            onErrorCb(FORMAT_ERROR_RESPONSE);
          }
        } else {
          onResponseCb(response);
        }
      } else {
        onErrorCb(FORMAT_ERROR_RESPONSE);
      }
    };
    const onError = function onError(error) {
      onErrorCb(error);
    };
    window.axios
      .get(url, vm.apiBaseHeader())
      .then(onResponse)
      .catch(onError);
  },
  apiPost(url, payload, onResponseCb, onErrorCb, extraParams) {
    const vm = this;
    //
    // validate result
    const onResponse = function onResponse(response) {
      if (response && response.data) {
        onResponseCb(response);
      } else {
        onErrorCb(FORMAT_ERROR_RESPONSE);
      }
    };
    const onError = function onError(error) {
      onErrorCb(error);
    };
    // extra params
    if (extraParams) {
    }
    if (payload) {
      window.axios
        .post(url, payload, vm.apiBaseHeader())
        .then(onResponse)
        .catch(onError);
    } else {
      window.axios
        .post(url, vm.apiBaseHeader())
        .then(onResponse)
        .catch(onError);
    }
  },
  apiPut(url, payload, onResponseCb, onErrorCb, extraParams) {
    const vm = this;
    //
    // validate result
    const onResponse = function onResponse(response) {
      if (response && response.data) {
        onResponseCb(response);
      } else {
        onErrorCb(FORMAT_ERROR_RESPONSE);
      }
    };
    const onError = function onError(error) {
      onErrorCb(error);
    };
    window.axios
      .put(url, payload, vm.apiBaseHeader())
      .then(onResponse)
      .catch(onError);
  },
  apiDelete(url, onResponseCb, onErrorCb) {
    const vm = this;
    //
    // validate result
    const onResponse = function onResponse(response) {
      if (response) {
        onResponseCb(response);
      } else {
        onErrorCb(FORMAT_ERROR_RESPONSE);
      }
    };
    const onError = function onError(error) {
      onErrorCb(error);
    };
    window.axios
      .delete(url, vm.apiBaseHeader())
      .then(onResponse)
      .catch(onError);
  },
};
