import syncUserGroup from '../handlers/syncUserGroups';
import localStorageHelper from '../utils/localStorageHelper';
import logger from '../utils/logger';

const LOG_TAG = 'syncUser';
export default {
  execute(userId, onCompleteCb) {
    logger.d(LOG_TAG, 'execute', userId);
    const onResponse = response => {
      this.onUserResponse(response.data, onCompleteCb);
    };
    const onError = error => {
      this.onUserError(error.data, onCompleteCb);
    };
    window.webapi.User.get(
        userId,
        onResponse,
        onError
    );
  },
  onUserResponse(value, onCompleteCb) {
    logger.d(LOG_TAG, 'onUserResponse', value);
    const onGroupCompleteCb = response => {
      logger.d(LOG_TAG, 'onUserResponse', 'onGroupCompleteCb', value, response);
      // update cache
      localStorageHelper.setAuthUserInfo(value);
      localStorageHelper.setAuthUserGroups(response);
      // result callback
      if (onCompleteCb) {
        onCompleteCb(value, response);
      }
    };
    syncUserGroup.execute(value.groups, onGroupCompleteCb);
  },
  onUserError(value, onCompleteCb) {
    logger.d(LOG_TAG, 'onUserError');
    if (onCompleteCb) {
      onCompleteCb(null, null);
    }
  },
};
