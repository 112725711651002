import React, {Component} from 'react';
import '../assets/css/row_.css';

class RowEmpty extends Component {
  render() {
    return (
      <div className="list-group-item list-group-item-action flex-column align-items-start p-0">
        <div className="d-flex">
          <div className="list-item-col-content align-items-center">
            <div className="ml-3 list-item-col-content__primary">
              {this.props.value}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RowEmpty;
