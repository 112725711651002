export default {
  user_id: 0,
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  password: '',
  car_plates: [],
  isValid(value) {
    if (value.email) {
      return true;
    }
    return false;
  },
  mock(email) {
    return {
      user_id: 111111,
      first_name: 'mock first',
      last_name: 'mock last',
      email: email,
      phone: 'mock 12345678',
      password: 'mock123',
    };
  },
};
