import firebase from 'firebase/app';
import 'firebase/database'; // If using Firebase database
import dataHelper from '../utils/dataHelper';
import logger from '../utils/logger';


const LOG_TAG = 'firebaseService';
const REF_FRBS_CAR_PLATE = 'car_plate';
const REF_FRBS_ADMIN = '_admin';
const REF_FRBS_USER = 'user';


const firebaseConfig = {
    apiKey: "AIzaSyC7VcTqq6osugNBAzcZZJ_wuuwdov9YYiE",
    authDomain: "kiwi-parking.firebaseapp.com",
    databaseURL: "https://kiwi-parking.firebaseio.com",
    projectId: "kiwi-parking",
    storageBucket: "kiwi-parking.appspot.com",
    messagingSenderId: "164443504421",
    appId: "1:164443504421:web:05614fab8a49ca3d3ca4cc",
    measurementId: "G-XBXPLWKGDP"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default {
    startParkingSessionByAdmin(adminEmail, userId, userEmail, carPlate, duration, siteName, slotNumber, code) {
        logger.d(LOG_TAG, 'startParkingSessionByAdmin', carPlate);
        const carPlateRef = `${process.env.REACT_APP_IS_PRODUCTION === 'true' ? 'prod' : 'dev'}/${REF_FRBS_CAR_PLATE}/`;
        // find car plate
        // find user profile
        // 2020-09-18 16:27:12
        const timestampInSec = dataHelper.getCurrentTimestampInSec();
        firebase.database().ref(`${carPlateRef}${carPlate.toLowerCase()}/${REF_FRBS_ADMIN}`)
          .update({
              'admin': adminEmail,
              'user_id': userId.toString(),
              'email': userEmail,
              'car_plate': carPlate.toLowerCase(),
              'start_parking_at': dataHelper.formatTimestampFromSec(timestampInSec),
              'duration': duration ? duration.toString() : '',
              'site_name': siteName,
              'slot_number': slotNumber.toString(),
              'timestamp': timestampInSec,
              'code': code ? code : ''
          });
    },
    extendParkingSessionByAdmin(adminEmail, userId, userEmail, carPlate, duration, siteName, slotNumber, code) {
        logger.d(LOG_TAG, 'extendParkingSessionByAdmin', carPlate);
        const carPlateRef = `${process.env.REACT_APP_IS_PRODUCTION === 'true' ? 'prod' : 'dev'}/${REF_FRBS_CAR_PLATE}/`;
        // find car plate
        // find user profile
        // 2020-09-18 16:27:12
        const timestampInSec = dataHelper.getCurrentTimestampInSec();
        firebase.database().ref(`${carPlateRef}${carPlate.toLowerCase()}/${REF_FRBS_ADMIN}`)
          .update({
              'admin': adminEmail,
              'user_id': userId.toString(),
              'email': userEmail,
              'car_plate': carPlate.toLowerCase(),
              'extend_at': dataHelper.formatTimestampFromSec(timestampInSec),
              'extend_duration': duration.toString(),
              'site_name': siteName,
              'slot_number': slotNumber.toString(),
              'timestamp': timestampInSec,
              'code': code ? code : ''
          });
    },
    searchUserByCarPlate(carPlate, onCompleteCb, onErrorCb) {
        logger.d(LOG_TAG, 'searchUserByCarPlate', carPlate);
        const carPlateRef = `${process.env.REACT_APP_IS_PRODUCTION === 'true' ? 'prod' : 'dev'}/${REF_FRBS_CAR_PLATE}/`;
        const userRef = `${process.env.REACT_APP_IS_PRODUCTION === 'true' ? 'prod' : 'dev'}/${REF_FRBS_USER}/`;
        // find car plate
        // find user profile
        firebase.database().ref(`${carPlateRef}${carPlate.toLowerCase()}`)
          .orderByChild('timestamp')
          .limitToLast(1)
          .once('value').then( function (carPlateSnapshot) {
                if (carPlateSnapshot.val()) {
                    carPlateSnapshot.forEach(function(childSnapshot) {
                        logger.d(LOG_TAG, 'searchUserByCarPlate', childSnapshot.key, childSnapshot.val());
                        if (childSnapshot.key === '_admin') {
                            onCompleteCb(childSnapshot.val(), childSnapshot.val());
                        } else {
                            firebase.database().ref(`${userRef}${childSnapshot.key}`).once('value')
                              .then( function (userSnapShot) {
                                  onCompleteCb(userSnapShot.val(), childSnapshot.val());
                              })
                        }


                    });
                } else {
                    onErrorCb('No history found');
                }
            }).catch( function (e) {
            onErrorCb('No history found');
        });
    },
};
