import React, {Component} from 'react';
import logger from "../../utils/logger";
import {CSSTransition, TransitionGroup} from "react-transition-group";

const LOG_TAG = 'AnimComponent';
class AnimComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mountReady: false,
    };
  }
  componentDidMount() {
    logger.d(LOG_TAG, 'componentDidMount');
    setTimeout(() => {
      this.setState({
        mountReady: true,
      })
    }, 150);
  }
  render() {
    return (
      <TransitionGroup component={null}>
        {
          this.state.mountReady && (
            <CSSTransition classNames="anim-comp-s" timeout={350}>
              {this.props.children}
            </CSSTransition>
          )
        }
      </TransitionGroup>
    );
  }
}

export default AnimComponent;
