import logger from '../utils/logger';

const LOG_TAG = 'syncAdminParkSlots';
export default {
  execute(groupId, siteId, onCompleteCb) {
    logger.d(LOG_TAG, 'execute', groupId, siteId);
    window.webapi.Group.getParkSiteInfo(
      groupId,
      siteId,
      (response) => {
        const data = response.data;
        logger.d(LOG_TAG, 'execute', 'onResponse', data, data.slots.length);
        if (onCompleteCb) {
          onCompleteCb(true, data.slots);
        }
      },
      (error) => {
        const data = error.data;
        logger.d(LOG_TAG, 'execute', 'onError', data);
        if (onCompleteCb) {
          onCompleteCb(false, []);
        }
      });
  },
};
