import logger from '../../utils/logger';

const LOG_TAG = 'api-mock.query';
export default {
  querySites(onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'querySites');
    setTimeout(() => {
      onResponseCb({
        data: []
      });
    }, 800);
  },
  querySiteSlots(siteId, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'querySiteSlots');
    setTimeout(() => {
      onResponseCb({
        data: []
      });
    }, 800);
  },
  queryAvailableTime(slotId, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'queryAvailableTime');
    setTimeout(() => {
      onResponseCb({
        data: {}
      });
    }, 800);
  },
  queryAvailableTimeBeyondActiveSession(slotId, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'queryAvailableTimeBeyondActiveSession');
    setTimeout(() => {
      onResponseCb({
        data: {}
      });
    }, 800);
  }

};
