import React, {Component} from 'react';
import AnimPage from '../../../components/anim/animPage';
import connect from 'react-redux/es/connect/connect';
import CardSearchVipPlate from '../../../components/cardSearchVipPlate';

import DlgAddVIP from '../../../dialog/dlgAddVIP';
import syncParkSites from '../../../handlers/syncParkSites';
import Row2Col from '../../../components/row2Col';
import RowEmpty from '../../../components/rowEmpty';
import {setLoading, setNotification} from '../../../store/actions';
import logger from '../../../utils/logger';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import updateParkSiteInfo from '../../../handlers/updateParkSiteInfo';
import dataHelper from "../../../utils/dataHelper";
import StateInfo from "../../../store/states";

const LOG_TAG = 'VipPlates';

class VipPlates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vipResults: [],

      // park info
      enableAddVIP: false,
      parkSites: [],
      parkSiteSelected: 'select',
      vipList: [],
    };
  }
  componentDidMount() {
    logger.d(LOG_TAG, 'componentDidMount');
    this.resetState();
  }
  resetState() {
    syncParkSites.execute(
      (result, value) => {
        const newState = {
          parkSiteSelected: 'select',
          parkSites: value,
          vipList: [],
        };
        this.setState(newState);
      }
    )
  };

  render() {
    return (
      <AnimPage>
        <div className="container-fluid p-0 mt-3">
          <div className="row app-main__content">
            {/* left section*/}
            <div className="col-md-6 app-main__content__card">
              {/* user search card*/}
              <CardSearchVipPlate
                onResult={this.onSearchResult}>
              </CardSearchVipPlate>
              {/* searched result */}
              {
                <div className="card mt-3">
                  <div className="card-body">
                    <div className="list-group">
                      {
                        this.state.vipResults.length > 0 ?
                          this.state.vipResults.map((result, index) => (
                            <Row2Col
                              key={index}
                              id={index}
                              index={index+1}
                              value1={result.name}>
                            </Row2Col>
                          ))
                          :
                          <RowEmpty value={"No Search results found."}></RowEmpty>
                      }
                    </div>
                  </div>
                </div>
              }
            </div>
            <div className="col-md-6 app-main__content__card">
              <div className="card">
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="">Select site</label>
                    <select
                      className="form-control"
                      value={this.state.parkSiteSelected}
                      onChange={this.onSelectParkSite}>
                      <option value="select">Select Park Site</option>
                      {
                        this.state.parkSites.map((site, index) => (
                          <option
                            key={index}
                            value={index}>{site.name}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12">
                  <button className={`${this.state.parkSiteSelected !== 'select' ?'btn-invert':'btn__disabled'}
                            btn-default float-right`}
                          onClick={this.onClickAddVip}>
                    <FontAwesomeIcon icon="plus"></FontAwesomeIcon> Add VIP
                  </button>
                </div>
              </div>


              <div className="card mt-2">
                <div className="card-body">

                  <div className="list-group">
                    {
                      this.state.vipList.length > 0 ?
                        this.state.vipList.map((result, index) => (
                          <Row2Col
                            key={index}
                            id={index}
                            index={index+1}
                            value1={result.toUpperCase()}
                            value1Style={'text-style-car-plate'}
                            action1={<FontAwesomeIcon icon="trash-alt" />}
                            onClickAction1={this.onClickItemDelete}
                          >
                          </Row2Col>
                        ))
                        :
                        <RowEmpty value={"No VIPs found."}></RowEmpty>
                    }
                  </div>
                </div>

              </div>
            </div>
          </div>
          <DlgAddVIP
            show={this.state.enableAddVIP}
            siteInfo={this.state.parkSites[this.state.parkSiteSelected]}
            onHide={this.onHideAddVIP}/>
        </div>
      </AnimPage>
    );
  }
  onSearchResult = value => {
    logger.d(LOG_TAG, 'onSearchResult', value);
    if (value) {
      this.setState({
        vipResults: value
      });
    } else {
      this.setState({
        vipResults: []
      });
    }
  };
  onSelectParkSite = value => {
    const pId = value.target.value.toString();

    const newState = {
      parkSiteSelected: pId,
    };
    if (pId === 'select') {
      newState.vipList = [];
    } else {
      const parkSiteSelected = this.state.parkSites[pId];
      if (parkSiteSelected.vip_plates) {
        newState.vipList = parkSiteSelected.vip_plates;
      } else {
        newState.vipList = [];
      }
    }
    this.setState({
      parkSiteSelected: pId,
    });
    this.setState(newState);

  };
  onClickAddVip = () => {
    logger.d(LOG_TAG, 'onClickAddVip');
    if (this.state.parkSiteSelected !== 'select') {
      this.setState({
        enableAddVIP: true,
      });
    }
  };
  onHideAddVIP = (reqUpdate = false, value) => {
    this.setState({
      enableAddVIP: false,
    });
    if (reqUpdate) {
      // sync updated site info
      const index = this.state.parkSites.findIndex((site) => site.site_id === value.site_id);
      if (-1 !== index) {
        const parkSites = this.state.parkSites;
        parkSites[index] = value;
        this.setState({
          parkSites: parkSites,
          vipList: value.vip_plates
        });
      }
    }
  };
  onClickItemDelete = param => e => {
    e.stopPropagation();
    const carPlate = this.state.vipList[param];
    window.confirm(`Are you sure you wish to remove ${carPlate.toUpperCase()} from VIPs?`) &&
    this.onRemoveVIP(carPlate);
  };
  onRemoveVIP(toRemove) {
    const siteInfo = this.state.parkSites[this.state.parkSiteSelected];
    const vipPlates = [];
    if (siteInfo.vip_plates) {
      siteInfo.vip_plates.forEach((plate) => {
        if (plate !== toRemove) {
          vipPlates.push(plate.toLowerCase());
        }
      });
    }
    siteInfo.vip_plates = vipPlates;
    this.props.setLoading(StateInfo.LoadingInfo.format(true, 'Removing VIP'));
    updateParkSiteInfo.execute(
      siteInfo.group_id,
      siteInfo.site_id,
      siteInfo,
      (result, value) => {
        setTimeout(() => {
          this.props.setLoading(StateInfo.LoadingInfo.format(false, ''));
          if (result) {
            this.onHideAddVIP(true, value);
          } else {
            const notify = dataHelper.formatError('Remove VIP', value);
            this.props.setNotification(
              StateInfo.NotificationInfo.format('danger', notify.title, notify.message)
            );
          }
        }, 800);
      }
    );
  }
}
// listens to state change
export default connect(
  null,
  {
    setLoading,
    setNotification,
  }
)(VipPlates);
