import React, {Component} from 'react';
import '../assets/css/row_.css';
import slotHelper from '../utils/slotHelper';

class Row4Col extends Component {
  render() {
    return (
      <div
        className="list-group-item list-group-item-action flex-column align-items-start p-0"
        key={this.props.id}
        onClick={this.props.onClick(this.props.id)}
      >
        {/*test 1..*/}
        <div className="d-flex">
          <div className="list-item-col-index justify-content-center align-items-center"
            style={{
              color:
                this.props.color === slotHelper.TRAFFIC_LIGHT.GREEN ? 'var(--clr-ltgray)' :
                this.props.color === slotHelper.TRAFFIC_LIGHT.AMBER ? 'var(--clr-ltgray)' :
                this.props.color === slotHelper.TRAFFIC_LIGHT.RED ? 'var(--clr-ltgray)' :
                'var(--clr-dkgray)',
              backgroundColor:
                this.props.color === slotHelper.TRAFFIC_LIGHT.GREEN ? 'var(--clr-green)' :
                this.props.color === slotHelper.TRAFFIC_LIGHT.AMBER ? 'var(--clr-amber)' :
                this.props.color === slotHelper.TRAFFIC_LIGHT.RED ? 'var(--clr-red)' :
                'var(--clr-ltgray)'
            }}>
            <h4>{this.props.index}</h4>
          </div>
          <div className="list-item-col-content align-items-center">
            <div className="list-item-4-col__1">
              <div>{this.props.value1}</div>
            </div>
            <div className="list-item-4-col__2 list-item-col-content__default">
              <div>{this.props.value2}</div>
            </div>
            <div className="list-item-4-col__index list-item-col-content__default">
              <div>{this.props.value3}</div>
            </div>
            <div className="list-item-4-col__index list-item-col-content__default">
              <div>{this.props.value4}</div>
            </div>
          </div>

          {
            this.props.clickOptions ?
              <div className="list-item-col-action justify-content-end align-items-center">
              {
                 this.props.clickOptions.length > 0 ?
                  this.props.clickOptions.map((option, index) => (
                    <div className="p-2"
                         key={index}
                         onClick={option.action(this.props.id)}>
                      {option.icon}
                    </div>
                  )) : <div></div>
              }
              <div className="p-2" />
            </div>
              :
            <div className="list-item-col-action justify-content-end align-items-center">
              {
                this.props.onClickAction1 ?
                  <div className="p-2" onClick={this.props.onClickAction1(this.props.id)}>
                    {this.props.action1}
                  </div>
                  : null
              }
              <div className="p-1" />
              {
                this.props.onClickAction2 ?
                  <div className="p-2" onClick={this.props.onClickAction2(this.props.id)}>
                    {this.props.action2}
                  </div>
                  : null
              }
              <div className="p-2" />
            </div>
          }

        </div>
        <div className="nav-desktop">
          {this.props.extra ? this.props.extra : null}
        </div>
      </div>
    );
  }
}

export default Row4Col;
