import React, {Component} from 'react';
import connect from 'react-redux/es/connect/connect';
import AnimPage from '../../../components/anim/animPage';
import {setLoading, setNotification} from '../../../store/actions';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ContentHost from '../../../components/contentHost';
import DlgAddParkSite from '../../../dialog/dlgAddParkSite';
import Row2Col from '../../../components/row2Col';
import RowEmpty from '../../../components/rowEmpty';
import localStorageHelper from '../../../utils/localStorageHelper';
import syncUser from '../../../handlers/syncUser';
import logger from '../../../utils/logger';
import StateInfo from '../../../store/states';
import dataHelper from '../../../utils/dataHelper';
import accessHelper from '../../../utils/accessHelper';

const LOG_TAG = 'ParkingSites';
class ParkingSites extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authUserInfo: null,
      groupOptionSelected: 'select',
      groupOptions: [],

      enableCreateSite: false,
      enableLoading: false,
      sites: [],
      siteSelected: null,
    };
  }
  componentDidMount() {
    logger.d(LOG_TAG, 'componentDidMount');
    this.refreshState();
  }
  refreshState() {
    const lastGroupSelected = localStorageHelper.getLastGroup();
    const authUserInfo = localStorageHelper.getAuthUserInfo();
    const authUserGroups = localStorageHelper.getAuthUserGroups();
    logger.d(LOG_TAG, 'refreshState', lastGroupSelected);
    const groups = [];
    authUserGroups.forEach((info) => {
      if (info) {
        groups.push(info);
      }
    });
    groups.sort((a, b) => a.name.localeCompare(b.name));
    let selectedIndex = 'select';
    if (groups.length > 0) {
      selectedIndex = groups.findIndex((group) => lastGroupSelected === group.group_id);
      if (selectedIndex === -1) {
        selectedIndex = 0;
      }
      logger.d(LOG_TAG, 'refreshState', 'last index', selectedIndex);
      this.refreshParkSites(
        groups[selectedIndex].group_id,
        (success, response) => {
          if (success) {
            this.setState({
              authUserInfo: authUserInfo,
              groupOptions: groups,
              groupOptionSelected: selectedIndex,
              sites: response,
            });
          } else {
            this.setState({
              authUserInfo: authUserInfo,
              groupOptions: groups,
              groupOptionSelected: selectedIndex,
            });
          }
        });
    } else {
      this.setState({
        authUserInfo: authUserInfo,
        groupOptions: groups,
        groupOptionSelected: selectedIndex,
      });
    }
  }
  refreshParkSites(groupId, onCompleteCb) {
    logger.d(LOG_TAG, 'refreshParkSites', groupId);
    window.webapi.Group.getParkSites(
      groupId,
      (response) => {
        const data = response.data;
        if (onCompleteCb) {
          onCompleteCb(true, data);
        }
        logger.d(LOG_TAG, 'refreshParkSites', 'onResponse', data);
      },
      (error) => {
        const data = error.data;
        logger.d(LOG_TAG, 'refreshParkSites', 'onError', data);
        if (onCompleteCb) {
          onCompleteCb(false, []);
        }
      }
    )
  }
  isManager(groupId) {
    let result = false;
    result =
      accessHelper.isSct(this.state.authUserInfo) ||
      accessHelper.isManager(this.state.authUserInfo, this.state.groupOptions, groupId);
    //logger.d(LOG_TAG, 'isManager', result);
    return result;
  }
  render() {
    return (
      <AnimPage>
        <div className="container-fluid p-0 mt-3">
          <ContentHost
            loading={this.state.enableLoading}
            children={<div className="row app-main__content">
              <div className="col-md-12 app-main__content__card">
                <div className="row mt-3">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-4 mb-2">
                        <select
                          className="form-control"
                          value={this.state.groupOptionSelected}
                          onChange={this.onSelectOrganization}>
                          <option value="select">Select organization</option>
                          {
                            this.state.groupOptions.map((option, index) => (
                              <option
                                key={index}
                                value={index}>{option.name}</option>
                            ))
                          }
                        </select>
                      </div>
                      {
                        ( accessHelper.isSct(this.state.authUserInfo) ) && (
                          <div className="col-md-8">
                            <button className="btn btn-invert btn-default float-right" onClick={this.onClickCreate}>
                              <FontAwesomeIcon icon="plus"></FontAwesomeIcon> New Site
                            </button>
                          </div>
                        )
                      }
                    </div>

                  </div>
                </div>
                <div className="card mt-3">
                  <div className="card-body">
                    <div className="list-group">
                      {
                        this.state.sites.length > 0 ?
                          this.state.sites.map((site, index) => (
                            // You can render a <Route> in as many places
                            // as you want in your app. It will render along
                            // with any other <Route>s that also match the URL.
                            // So, a sidebar or breadcrumbs or anything else
                            // that requires you to render multiple things
                            // in multiple places at the same URL is nothing
                            // more than multiple <Route>s.

                            <Row2Col
                              key={index}
                              id={index}
                              index={index+1}
                              value1={site.name}
                              value2={site.description}
                              value2_1={dataHelper.formatInitialPromoRate(site)}
                              value2_2={dataHelper.formatFixedRates(site)}
                              onClick={this.onClickItem.bind(this, site)}
                              action1={this.isManager(site.group_id) ? <FontAwesomeIcon icon="pencil-alt" /> : null}
                              onClickAction1={this.isManager(site.group_id) ? this.onClickItemEdit : null}
                              action2={this.isManager(site.group_id) ? <FontAwesomeIcon icon="trash-alt" /> : null}
                              onClickAction2={this.isManager(site.group_id) ? this.onClickItemDelete : null}
                            >
                            </Row2Col>
                          ))
                          :
                          <RowEmpty value={"No sites found."}></RowEmpty>
                      }

                    </div>
                  </div>
                </div>
              </div>
            </div>}>
          </ContentHost>
          <DlgAddParkSite
            show={this.state.enableCreateSite}
            options={this.state.groupOptions}
            defaultGroup={this.state.groupOptionSelected}
            siteInfo={this.state.siteSelected}
            onHide={this.onHideCreateSite}
            onSave={this.onSaveCreateSite}/>
        </div>
      </AnimPage>
    );
  }
  onSelectOrganization = e => {
    const selected = e.target.value;
    if (selected !== 'select') {
      const groupId = this.state.groupOptions[selected].group_id;
      localStorageHelper.setLastGroup(groupId);
      logger.d(LOG_TAG, 'onSelectOrganization', selected, groupId);
      this.refreshParkSites(
        groupId,
        (success, response) => {
          if (success) {
           this.setState({
             groupOptionSelected: selected,
             sites: response,
           });
          }
        });
    }
  };
  onClickCreate = () => {
    this.setState({
      enableCreateSite: true,
      siteSelected: null,
    });
  };
  onHideCreateSite = (reqUpdate = false) => {
    logger.d(LOG_TAG, 'onHideCreateSte', reqUpdate);
    this.setState({
      enableCreateSite: false,
      siteSelected: null,
    });
    if (reqUpdate) {
      syncUser.execute(
        this.state.authUserInfo.user_id,
        (userInfo, groups) => {
          this.refreshState();
        }
      )
    }
  };
  onSaveCreateSite = () => {
    this.setState({
      enableCreateSite: false,
    });
  };
  onClickItem = param => e => {
    e.stopPropagation();
    const siteInfo = `${param.group_id}:${param.site_id}`;
    this.props.onClickSite(siteInfo);
  };
  onClickItemEdit = param => e => {
    e.stopPropagation();
    const selected = this.state.sites[param];
    logger.d(LOG_TAG, 'onClickItemEdit', param, selected);
    this.setState({
      enableCreateSite: true,
      siteSelected: selected,
    });
  };
  onClickItemDelete = param => e => {
    logger.d(LOG_TAG, 'onClickItemDelete', param);
    e.stopPropagation();
    const selected = this.state.sites[param];
    window.confirm(`Are you sure you wish to delete ${selected.name}?`) &&
    this.deleteSite(selected);
  };
  deleteSite = value => {
    logger.d(LOG_TAG, 'deleteSite', value);
    this.props.setLoading(StateInfo.LoadingInfo.format(true, `Deleting ${value.name}`));
    window.webapi.Admin.delGroupSite(
      value.group_id,
      value.site_id,
      (response) => {
        setTimeout(() => {
          this.props.setLoading(StateInfo.LoadingInfo.format(false, ''));
          this.refreshState();
        }, 800);
      },
      (error) => {
        setTimeout(() => {
          const notify = dataHelper.formatError('Site', error.data);
          this.props.setLoading(StateInfo.LoadingInfo.format(false, ''));
          this.props.setNotification(
            StateInfo.NotificationInfo.format('danger', notify.title, notify.message)
          );
        }, 800);
      }
    )
  }
}
export default connect(
  null,
  {
    setLoading,
    setNotification,
  }
)(ParkingSites);

