import React, {Component, useEffect} from 'react';
import connect from 'react-redux/es/connect/connect';
import AnimPage from '../../../components/anim/animPage';
import {setLoading, setNotification} from '../../../store/actions';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ContentHost from '../../../components/contentHost';
import Row4Col from "../../../components/row4Col";
import RowTimeTable from "../../../components/rowTimeTable";
import logger from '../../../utils/logger';
import DlgAddSiteSlot from '../../../dialog/dlgAddSiteSlot';
import DlgAddReservations from '../../../dialog/dlgAddReservations';
import RowEmpty from '../../../components/rowEmpty';
import StateInfo from '../../../store/states';
import accessHelper from '../../../utils/accessHelper';
import localStorageHelper from '../../../utils/localStorageHelper';
import slotHelper from '../../../utils/slotHelper';
import dataHelper from '../../../utils/dataHelper';
import { useBus, useListener } from 'react-bus';

const LOG_TAG = 'SiteSlots';

const EventBusBinder = ({onRefreshCb}) => {
  const bus = useBus();
  useListener('ev_on_refresh', (event) => {
    logger.d(LOG_TAG, 'EventBusBinder', 'ev_on_refresh', event);
    onRefreshCb();
  });
  useEffect(() => {
    bus.emit('ev_enable_fab_refresh', true);
    return () => {
      bus.emit('ev_enable_fab_refresh', false);
    };
  }, []);
  const el = React.useRef(null)
  return <div ref={el}></div>
};
class SiteSlots extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enableCreateSlot: false,
      enableCreateReservation: false,
      enableUpdateSensor: false,
      enableLoading: false,
      siteInfo: {},
      slotOptions: [],
      slotInfoSelected: null,
      isManager: false,
    };
    logger.d(LOG_TAG, 'constructor', this.props);
  }
  componentDidMount() {
    logger.d(LOG_TAG, 'componentDidMount');
    // this.props.bus.emit('ev_enable_fab_refresh', true)
    // this.props.bus.on('ev_on_refresh', this.onClickRefreshSlot)
    this.refreshState();
  }
  componentWillUnmount () {
    // this.props.bus.emit('ev_enable_fab_refresh', false)
    // this.props.bus.off('ev_on_refresh', this.onClickRefreshSlot)
  }
  refreshState(loading) {
    if (loading) {
      this.props.setLoading(StateInfo.LoadingInfo.format(true, 'Please wait...'));
    }
    const params = this.props.params;
    const siteInfo = params.split(':');
    const groupId = parseInt(siteInfo[0], 10);
    const siteId = parseInt(siteInfo[1], 10);

    const authUserInfo = localStorageHelper.getAuthUserInfo();
    const groups = localStorageHelper.getAuthUserGroups();

    const isManager = accessHelper.isSct(authUserInfo) || accessHelper.isManager(authUserInfo, groups, groupId);

    window.webapi.Group.getParkSiteInfo(
      groupId, siteId,
      (response) => {
        const data = response.data;
        logger.d(LOG_TAG, 'refreshState', 'onResponse', data);
        const slotOptions = []
        if (data.slots && data.slots.length > 0) {
          data.slots.sort((a, b) => (`${dataHelper.paddingZero(a.number, 3)}` > `${dataHelper.paddingZero(b.number, 3)}`) ? 1 : -1);
          data.slots.forEach((slot, index) => {
            const options = []
            options.push({
              icon: slot.sensor_data.device_id !== 0 ?
                <FontAwesomeIcon icon="concierge-bell" /> :
                <FontAwesomeIcon icon="concierge-bell" className='fa-concierge-bell-none'/>,
              action: this.onClickItemEditSensor,
            })
            if (isManager) {
              options.push({
                icon: <FontAwesomeIcon icon="pencil-alt" />,
                action: this.onClickItemEdit,
              })
              options.push({
                icon: <FontAwesomeIcon icon="trash-alt" />,
                action: this.onClickItemDelete,
              })
            }
            slotOptions.push(options)
          })
        }

        //
        //
        //
        /*data.initial_rate = 50;
        data.initial_duration = 30;
        data.fixed_rates = [
          {
            "day": "Monday",
            "windows": [
              {
                "start": "00:00",
                "end": "03:00",
                "description": "Early Bird",
                "price": 50
              },
              {
                "start": "03:00",
                "end": "06:00",
                "description": "Day Parking",
                "price": 50
              }
            ]
          },
          {
            "day": "Tuesday",
            "windows": [
              {
                "start": "00:00",
                "end": "03:00",
                "description": "Early Bird",
                "price": 50
              },
              {
                "start": "03:00",
                "end": "06:00",
                "description": "Day Parking",
                "price": 50
              }
            ]
          },
          {
            "day": "Wednesday",
          },
          {
            "day": "Thursday",
          },
          {
            "day": "Friday",
          },
          {
            "day": "Saturday",
          },
          {
            "day": "Sunday",
          },
        ];*/
        this.setState({
          enableLoading: false,
          siteInfo: data,
          slotOptions: slotOptions,
          isManager: isManager,
        });
        if (loading) {
          setTimeout(() => {
            this.props.setLoading(StateInfo.LoadingInfo.format(false, ''));
          }, 1200);
        }
      },
      (error) => {
        this.setState({
          enableLoading: false,
          isManager: isManager,
        });
        if (loading) {
          setTimeout(() => {
            this.props.setLoading(StateInfo.LoadingInfo.format(false, ''));
          }, 1200);
        }
      });
    this.setState({
      enableLoading: true,
      isManager: isManager,
    });
  };
  render() {
    return (
      <AnimPage>
        <div className="container-fluid p-0 mt-3">
          <EventBusBinder
            onRefreshCb={this.onClickRefreshSlot}>
          </EventBusBinder>
          <ContentHost
            loading={this.state.enableLoading}
            children={
              <div className="row app-main__content">
                {
                  this.state.siteInfo.site_id !== 0 && (
                    <div className="col-md-12 app-main__content__card">
                      {/* add park site button */}
                      {
                        this.state.isManager && (
                          <div className="row mt-3">
                            <div className="col-md-12">
                              <div className="form-group">
                                <button className="btn-invert btn-default float-right" onClick={this.onClickNewSlot}>
                                  <FontAwesomeIcon icon="plus"></FontAwesomeIcon> New Space
                                </button>
                              </div>
                            </div>
                          </div>
                        )
                      }
                      <div
                        className="row ml-1 clickable"
                        onClick={this.onClickSiteHeader}>
                        <h4>
                          <FontAwesomeIcon icon="arrow-left"></FontAwesomeIcon>
                          <span className="ml-2">{this.state.siteInfo.name}</span>
                          {
                            (this.state.siteInfo.slots && this.state.siteInfo.slots.length > 0) && (
                              <span className="ml-2">({this.state.siteInfo.slots.length})</span>
                            )
                          }
                        </h4>
                      </div>
                      <div className="card mt-3">
                        <div className="card-body">
                          <div className="row">
                            {
                              this.state.siteInfo.logo_url && (
                                <div className="ml-4">
                                  <img
                                    className="app-img__site--logo"
                                    src={this.state.siteInfo.logo_url}
                                    alt="logo"
                                  />
                                </div>
                              )
                            }
                            <div className="ml-4">
                              <h5>{this.state.siteInfo.address}</h5>
                              <h6>{this.state.siteInfo.description}</h6>
                              {
                                (this.state.siteInfo.initial_duration > 0) && (
                                  <div className="label_ext">
                                    {dataHelper.formatInitialPromoRate(this.state.siteInfo)}
                                  </div>
                                )
                              }
                              {
                                (this.state.siteInfo.fixed_rates) && (
                                  <div className="label_ext">
                                    {dataHelper.formatFixedRates(this.state.siteInfo)}
                                  </div>
                                )
                              }
                            </div>
                          </div>
                          <div className="list-group">
                            {
                              this.state.siteInfo.slots && this.state.siteInfo.slots.length > 0 ?
                                this.state.siteInfo.slots.map((slot, index) => (
                                  // You can render a <Route> in as many places
                                  // as you want in your app. It will render along
                                  // with any other <Route>s that also match the URL.
                                  // So, a sidebar or breadcrumbs or anything else
                                  // that requires you to render multiple things
                                  // in multiple places at the same URL is nothing
                                  // more than multiple <Route>s.
                                  <Row4Col
                                    key={index}
                                    id={index}
                                    index={slot.number}
                                    value1={''}
                                    value2={''}
                                    value3={''}
                                    value4={''}
                                    color={slotHelper.trafficLight(slot)}
                                    extra={
                                      <RowTimeTable timetable={slot.days_available}></RowTimeTable>
                                    }
                                    onClick={this.onClickItem}
                                    clickOptions={this.state.slotOptions[index]}>
                                  </Row4Col>
                                ))
                                :
                                <RowEmpty value={"No slot found."}></RowEmpty>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }

              </div>
            }>
          </ContentHost>
          <DlgAddSiteSlot
            show={this.state.enableCreateSlot}
            siteInfo={this.state.siteInfo}
            slotInfo={this.state.slotInfoSelected}
            sensorOnly={this.state.enableUpdateSensor}
            onHide={this.onHideNewSlot}
            onSave={this.onSaveNewSlot}/>
          <DlgAddReservations
            show={this.state.enableCreateReservation}
            siteInfo={this.state.siteInfo}
            slotInfo={this.state.slotInfoSelected}
            onHide={this.onHideNewReservation}
            onSave={this.onSaveNewReservation}/>
        </div>
      </AnimPage>
    );
  }
  onClickRefreshSlot = () => {
    logger.d(LOG_TAG, 'onClickRefreshSlot');
    this.refreshState(true);
  };
  onClickSiteHeader = () => {
    logger.d(LOG_TAG, 'onClickSiteHeader');
    this.setState({mounted: false})
    this.props.onClickBack();
  };
  onClickNewSlot = () => {
    this.setState({
      enableCreateSlot: true,
      enableUpdateSensor: false,
      slotInfoSelected: null,
    });
  };
  onHideNewSlot = () => {
    this.setState({
      enableCreateSlot: false,
      enableUpdateSensor: false,
      slotInfoSelected: null,
    });
  };
  onSaveNewSlot = (reqUpdate = false) => {
    logger.d(LOG_TAG, 'onSaveNewSlot', reqUpdate);
    if (reqUpdate) {
      this.refreshState();
      this.setState({
        enableCreateSlot: false,
        enableUpdateSensor: false,
        slotInfoSelected: null,
      });
    }
  };
  onSaveNewReservation = (reqUpdate = false) => {
    logger.d(LOG_TAG, 'onSaveNewReservation', reqUpdate);
    if (reqUpdate) {
      this.refreshState();
      this.setState({
        enableCreateReservation: false,
        slotInfoSelected: null,
      });
    }
  };
  onHideNewReservation = () => {
    this.setState({
      enableCreateReservation: false,
      slotInfoSelected: null,
    });
  };
  onClickItem = param => e => {
    logger.d(LOG_TAG, 'onClickItem', param);
    e.stopPropagation();
    this.setState({
      enableCreateReservation: true,
      slotInfoSelected: this.state.siteInfo.slots[param],
    });
  };
  onClickItemEditSensor = param => e => {
    logger.d(LOG_TAG, 'onClickItemEditSensor', param);
    e.stopPropagation();
    if (this.state.isManager) {
      this.setState({
        enableCreateSlot: true,
        enableUpdateSensor: true,
        slotInfoSelected: this.state.siteInfo.slots[param],

      });
    }
  };
  onClickItemEdit = param => e => {
    logger.d(LOG_TAG, 'onClickItemEdit', param);
    e.stopPropagation();
    if (this.state.isManager) {
      this.setState({
        enableCreateSlot: true,
        slotInfoSelected: this.state.siteInfo.slots[param],
      });
    }
  };
  onClickItemDelete = param => e => {
    logger.d(LOG_TAG, 'onClickItemDelete', param);
    e.stopPropagation();
    const selected = this.state.siteInfo.slots[param];
    window.confirm(`Are you sure you wish to delete ${selected.number}?`) &&
    this.deleteSlot(selected);
  };
  deleteSlot = value => {
    logger.d(LOG_TAG, 'deleteSlot', value);
    this.props.setLoading(StateInfo.LoadingInfo.format(true, `Deleting ${value.number}`));
    window.webapi.Group.delParkSlotInfo(
      value.group_id,
      value.site_id,
      value.slot_id,
      (response) => {
        setTimeout(() => {
          this.props.setLoading(StateInfo.LoadingInfo.format(false, ''));
          this.refreshState();
        }, 800);
      },
      (error) => {
        setTimeout(() => {
          const notify = dataHelper.formatError('Slot', error.data);
          this.props.setLoading(StateInfo.LoadingInfo.format(false, ''));
          this.props.setNotification(
            StateInfo.NotificationInfo.format('danger', notify.title, notify.message)
          );
        }, 800);
      }
    )
  }
}
export default (connect(
  null,
  {
    setLoading,
    setNotification,
  }
)(SiteSlots));
