export default {
  //
  // returns slotInfo and sensorInfo if successful
  //
  execute(groupId, siteId, siteInfo, onCompleteCb) {
    window.webapi.Group.putParkSiteInfo(
      groupId,
      siteId,
      siteInfo,
      (response) => {
        const data = response.data;
        if (onCompleteCb) {
          onCompleteCb(true, data);
        }
      },
      (error) => {
        const data = error.data;
        if (onCompleteCb) {
          onCompleteCb(false, data);
        }
      }
    )
  },
};
