import React, {Component} from 'react';
import imgLogo from '../../assets/images/logo.png';
import logger from '../../utils/logger';
import accessHelper from '../../utils/accessHelper';

const LOG_TAG = 'SelectSite';
class SelectSite extends Component {
  constructor(props) {
    super(props);
    // init and sync
    this.state = {
      userInfo: null,
      groupOptions: null,
      parkSites: [],
      parkSiteSelected: 'select',
    };
  }
  componentDidMount() {
    this.resetState();
  }
  componentDidUpdate(oldProps) {
    const newProps = this.props;
    if (oldProps.userInfo !== newProps.userInfo ||
    oldProps.groupInfo !== newProps.groupInfo) {
      this.resetState();
    }
  }
  resetState() {
    logger.d(LOG_TAG, 'resetState');
    window.webapi.Query.querySites(
      (response) => {
        const data = response.data;
        logger.d(LOG_TAG, 'resetState', 'querySites', 'onResponse', data);
        const managedSites = [];
        data.forEach((site) => {
          if (accessHelper.isSct(this.props.userInfo) ||
            accessHelper.isManager(this.props.userInfo, this.props.groupInfo, site.group_id)) {
            managedSites.push(site);
          }
        });
        managedSites.sort((a, b) => a.name.localeCompare(b.name));
        this.setState({
          parkSites: managedSites,
          parkSlotSelected: 'select',
        });
      },
      (error) => {
        logger.d(LOG_TAG, 'resetState', 'querySites', 'onError', error);
        this.setState({
          parkSites: [],
          parkSlotSelected: 'select',
        });
      }
    )
  }
  render() {
    return (
      <div className="container mt-3">
        {/* SignIn section */}
        <section>
          <section className="row justify-content-center mt-5 p-2">
            <img
              className="app__img-120"
              src={process.env.REACT_APP_IS_PRODUCTION === 'true' ? imgLogo : ''}
              alt="logo"
            />
          </section>
          <section className="row justify-content-center p-3">
            <h4>
              {process.env.REACT_APP_IS_PRODUCTION === 'true'
                ? 'KIWIPARK Visitor Parking Management'
                : 'Hello World 2-1'}
            </h4>
          </section>

          {/* user inputs */}
          <div className="p-3" />
          <section>
            <div className="m-auto" style={{ width: '100%', maxWidth: '18rem' }}>
              <div className="form-group">
                <label htmlFor="">Park Site</label>
                <select
                  className="form-control"
                  value={this.state.parkSiteSelected}
                  onChange={this.onSelectParkSite}>
                  <option value="select">Select Park Site</option>
                  {
                    this.state.parkSites.map((site, index) => (
                      <option
                        key={index}
                        value={index}>{site.name}</option>
                    ))
                  }
                </select>
              </div>

              <div className="form-group">
                <button
                  className={`${this.validate() ?'btn':'btn__disabled'} btn-sign-in`}
                  onClick={this.onClickNext}>
                  Next
                </button>
              </div>
            </div>
          </section>
        </section>
      </div>
    );
  }
  onSelectParkSite = e => {
    const selected = e.target.value;
    logger.d(LOG_TAG, 'onSelectParkSite', selected);
    this.setState({
      parkSiteSelected: selected
    })
  };
  validate() {
    if (this.state.parkSiteSelected !== 'select') {
      return true;
    }
    return false;
  };
  onClickNext = () => {
    logger.d(LOG_TAG, 'onClickNext');
    const validated = this.validate();
    if (validated && this.props.onSelectParkSite) {
      this.props.onSelectParkSite(this.state.parkSites[this.state.parkSiteSelected]);
    }
  };
}

// connect and map actions
export default SelectSite;
