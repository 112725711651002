import React from 'react';
import Alert from 'react-bootstrap/Alert';
import logger from '../utils/logger';

const LOG_TAG = 'AlertDismissible';
class AlertDismissible extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mIsShow: false,
    };
  }
  componentDidUpdate(oldProps) {
    const newProps = this.props;
    if (oldProps.alert !== newProps.alert) {
      this.enableAlert(true);
    }
  }
  enableAlert(enable) {
    this.setState({
      mIsShow: enable,
    });
    const alert = this.props.alert;
    if (alert.type === 'success' || alert.type === 'danger') {
      setTimeout(() => {
        this.onClose();
      }, 3000);
    }
  }
  render() {
    const alert = this.props.alert;
    logger.d(LOG_TAG, 'render', alert);
    if (alert === null) {
      return null;
    }
    return (
      <div className="row fixed-bottom" style={{zIndex: '4000'}}>
        <div className="col-md-12">
          <Alert
            dismissible
            show={this.state.mIsShow}
            variant={alert.type}
            onClose={this.onClose}>
            {
              alert.headline ? <h5>{alert.headline}</h5> : null
            }
            {
              alert.message ? <p>{alert.message}</p> : null
            }
          </Alert>
        </div>
      </div>
    );
  }
  onClose = () => {
    logger.d(LOG_TAG, 'onClose');
    this.setState({
      mIsShow: false,
    });
  };
}
export default AlertDismissible;
