import React, {Component} from 'react';
import {connect} from 'react-redux';
import {setLoading, setNotification} from '../store/actions';
import Modal from 'react-bootstrap/Modal';
import logger from '../utils/logger';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import StateInfo from '../store/states';
import dataHelper from '../utils/dataHelper';

const WINDOW_OPTIONS = [
  {value: '00:00', text: '00:00'}, {value: '00:30', text: '00:30'},
  {value: '01:00', text: '01:00'}, {value: '01:30', text: '01:30'},
  {value: '02:00', text: '02:00'}, {value: '02:30', text: '02:30'},
  {value: '03:00', text: '03:00'}, {value: '03:30', text: '03:30'},
  {value: '04:00', text: '04:00'}, {value: '04:30', text: '04:30'},
  {value: '05:00', text: '05:00'}, {value: '05:30', text: '05:30'},
  {value: '06:00', text: '06:00'}, {value: '06:30', text: '06:30'},
  {value: '07:00', text: '07:00'}, {value: '07:30', text: '07:30'},
  {value: '08:00', text: '08:00'}, {value: '08:30', text: '08:30'},
  {value: '09:00', text: '09:00'}, {value: '09:30', text: '09:30'},
  {value: '10:00', text: '10:00'}, {value: '10:30', text: '10:30'},
  {value: '11:00', text: '11:00'}, {value: '11:30', text: '11:30'},
  {value: '12:00', text: '12:00'}, {value: '12:30', text: '12:30'},
  {value: '13:00', text: '13:00'}, {value: '13:30', text: '13:30'},
  {value: '14:00', text: '14:00'}, {value: '14:30', text: '14:30'},
  {value: '15:00', text: '15:00'}, {value: '15:30', text: '15:30'},
  {value: '16:00', text: '16:00'}, {value: '16:30', text: '16:30'},
  {value: '17:00', text: '17:00'}, {value: '17:30', text: '17:30'},
  {value: '18:00', text: '18:00'}, {value: '18:30', text: '18:30'},
  {value: '19:00', text: '19:00'}, {value: '19:30', text: '19:30'},
  {value: '20:00', text: '20:00'}, {value: '20:30', text: '20:30'},
  {value: '21:00', text: '21:00'}, {value: '21:30', text: '21:30'},
  {value: '22:00', text: '22:00'}, {value: '22:30', text: '22:30'},
  {value: '23:00', text: '23:00'}, {value: '23:30', text: '23:30'},
  {value: '23:59', text: '24:00 (23:59)'},
];

const LOG_TAG = 'DlgAddReservations';
class DlgAddReservations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      siteInfo: null,
      slotInfo: null,
      reservations: [],

      name: '',
      carPlate: '',
      start: '',
      end: '',
      startSelected: 'select',
      endSelected: 'select',
    };
  }
  componentDidMount() {
    logger.d(LOG_TAG, 'componentDidMount');
    // format window options
    this.resetState();
  };
  componentDidUpdate(oldProps) {
    const newProps = this.props;
    if (
      oldProps.siteInfo !== newProps.siteInfo ||
      oldProps.slotInfo !== newProps.slotInfo) {
      logger.d(LOG_TAG, 'componentDidUpdate', newProps.siteInfo, newProps.slotInfo);
      this.resetState();
      this.resetReservation();
    }
  }
  resetState() {
    const now = window.moment.now();
    const date = window.moment(now).format('YYYY-MM-DD');
    this.setState({
      siteInfo: this.props.siteInfo,
      slotInfo: this.props.slotInfo,
      reservations: [],

      name: '',
      carPlate: '',
      start: date,
      end: date,
      startSelected: 'select',
      endSelected: 'select',
    });
  };
  resetReservation(onCompleteCb) {
    if (this.props.show && this.props.siteInfo && this.props.slotInfo) {
      logger.d(LOG_TAG, 'resetReservation');
      window.webapi.Group.getSlotReservations(
        this.props.siteInfo.group_id,
        this.props.siteInfo.site_id,
        this.props.slotInfo.slot_id,
        (response) => {
          const data = response.data;
          logger.d(LOG_TAG, 'resetReservation', 'onResponse', data);
          const newState = {
          };
          const reservations = [];
          const nowSeconds = dataHelper.formatSecFromMillisec(window.moment().valueOf());
          logger.d(LOG_TAG, 'resetReservation', 'current', nowSeconds, window.moment(dataHelper.formatMillisecFromSec(nowSeconds)).format('Do MMM YYYY, HH:mm'));
/*
          const newData = [
            {
              parking_id: 1,
              full_name: 'test',
              car_plate: 'test plate',
              time_start: nowSeconds-10,
              time_end: nowSeconds+60,
            },
            {
              parking_id: 2,
              full_name: 'test2',
              car_plate: 'test plate2',
              time_start: nowSeconds+120,
              time_end: nowSeconds+240,
            }
          ];
          */
          data.forEach((reservation) => {
            const res = {
              parkId: reservation.parking_id,
              name: reservation.full_name,
              carPlate: reservation.car_plate,
              startDate: window.moment(dataHelper.formatMillisecFromSec(reservation.time_start)).format('DD.MMM'),
              startTime: window.moment(dataHelper.formatMillisecFromSec(reservation.time_start)).format('HH:mm'),
              endDate: window.moment(dataHelper.formatMillisecFromSec(reservation.time_end)).format('DD.MMM'),
              endTime: window.moment(dataHelper.formatMillisecFromSec(reservation.time_end)).format('HH:mm'),
              // start: window.moment(dataHelper.formatMillisecFromSec(reservation.time_start)).format('DD.MMM.YY - HH:mm'),
              // end: window.moment(dataHelper.formatMillisecFromSec(reservation.time_end)).format('DD.MMM.YY - HH:mm'),
            };
            // user ended early?
            if ((reservation.actual_time_end) && reservation.time_end > reservation.actual_time_end) {
              res.actualEndDate = window.moment(dataHelper.formatMillisecFromSec(reservation.actual_time_end)).format('DD.MMM');
              res.actualEndTime = window.moment(dataHelper.formatMillisecFromSec(reservation.actual_time_end)).format('HH:mm');
            }
            // is active session?
            if (
              (!reservation.owner_start && !reservation.owner_end) &&
              nowSeconds > reservation.time_start && nowSeconds < reservation.time_end) {
              res.isActive = true;
              // set default start time as user session end time
              const timeEnd = window.moment(dataHelper.formatMillisecFromSec(reservation.time_end)).format('HH:mm');
              for (let index = 0; WINDOW_OPTIONS.length > index; index+=1) {
                const window = WINDOW_OPTIONS[index];
                if (timeEnd <= window.value ) {
                  newState.startSelected = index;
                  newState.endSelected = index;
                  break;
                }
              }
            }
            reservations.push(res);
          });
          newState.reservations = reservations;
          this.setState(newState);
          if (onCompleteCb) {
            onCompleteCb(true);
          }
        },
        (error) => {
          const data = error.data;
          logger.d(LOG_TAG, 'resetReservation', 'onError', data);
          this.setState({
            reservations: [],
          });
          if (onCompleteCb) {
            onCompleteCb(false, error);
          }
        }
      )
    }
  };
  render() {
    return (
      <Modal
        show={this.props.show}
        backdrop={'static'}
        onHide={this.onClickHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Reservations{this.state.slotInfo ? <span> ({this.state.slotInfo.number})</span> : ''}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* reservation */}
          <div className="row">
            <div className="col-md-12">
              <div className="row">

                <div className="col-md-6">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="">Full name</label>
                          <input type="text" className="form-control"
                          value={this.state.name}
                          onChange={this.onChangeName}/>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="">Car Registration</label>
                          <input type="text" className="form-control"
                          value={this.state.carPlate}
                          onChange={this.onChangeCarPlate}/>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-7">
                        <div className="form-group">
                          <label className="form-check-label" htmlFor="">From</label>
                          <input className="form-control" type="date"
                                 value={this.state.start}
                                 onChange={this.onChangeReservationFrom}></input>
                        </div>
                      </div>
                      <div className="col-md-5">
                        <label className="form-check-label" htmlFor=""></label>
                        <select
                          className="form-control"
                          value={this.state.startSelected}
                          onChange={this.onSelectReservationStartTime}>
                          <option value="select">Select time</option>
                          {
                            WINDOW_OPTIONS.map((option, index) => (
                              <option
                                key={index}
                                value={index}>{option.text}</option>
                            ))
                          }
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-7">
                        <div className="form-group">
                          <label className="form-check-label" htmlFor="">To</label>
                          <input className="form-control" type="date"
                                 value={this.state.end}
                                 onChange={this.onChangeReservationTo}></input>
                        </div>
                      </div>
                      <div className="col-md-5">
                        <label className="form-check-label" htmlFor=""></label>
                        <select
                          className="form-control"
                          value={this.state.endSelected}
                          onChange={this.onSelectReservationEndTime}>
                          <option value="select">Select time</option>
                          {
                            WINDOW_OPTIONS.map((option, index) => (
                              <option
                                key={index}
                                value={index}>{option.text}</option>
                            ))
                          }
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <button className={`${this.validateReservation()?'btn-invert':'btn__disabled'}
                            btn-default-small mt-1 pt-1 float-right`}
                            onClick={this.onClickAddReservation}>
                          <FontAwesomeIcon icon="plus"></FontAwesomeIcon> Add
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* reservation list */}
                <div className="col-md-6">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-12">
                        {
                          this.state.reservations.map((reservation, index) => (
                            <div key={index} className="app-item-underline-border pt-1 pb-1">
                              <div style={{fontWeight: 'bold'}}>{reservation.name}: {reservation.carPlate}</div>
                              <div className="pl-3 pr-3 row flex-row justify-content-between">
                                <div
                                  className={`${reservation.isActive?'clr-warning':''}`}
                                  style={{fontSize: '12px', fontWeight: 'bold'}}>
                                  <span>
                                    {reservation.startTime}, {reservation.startDate} ~ {reservation.endTime}, {reservation.endDate}
                                  </span>
                                  {reservation.actualEndDate ?
                                  <div style={{fontWeight: 'bold'}}>Session stopped at {reservation.actualEndTime}, {reservation.actualEndDate}</div>
                                      : null}
                                </div>
                                {
                                  reservation.isActive ?
                                    null
                                    :
                                    <div className="mr-1"  style={{fontSize: '13px'}} onClick={this.onClickRemoveReservation.bind(this, index)}>
                                      <FontAwesomeIcon icon="trash-alt" />
                                    </div>
                                }

                              </div>
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
  onClickHide = e => {
    logger.d(LOG_TAG, 'onClickHide');
    if (this.props.onHide) {
      this.resetState();
      this.props.onHide();
    }
  };
  onChangeName = e => {
    const value = e.target.value;
    logger.d(LOG_TAG, 'onChangeName', value);
    this.setState({
      name: value,
    });
  };
  onChangeCarPlate = e => {
    const value = e.target.value;
    logger.d(LOG_TAG, 'onChangeCarPlate', value);
    this.setState({
      carPlate: value
    });
  };
  onChangeReservationFrom = e => {
    const value = e.target.value;
    logger.d(LOG_TAG, 'onChangeReservationFrom', value);
    const date = window.moment(window.moment.now()).format('YYYY-MM-DD');
    if (date <= value) {
      this.setState({
        start: value,
        end: value,
      });
    }
  };
  onChangeReservationTo = e => {
    const value = e.target.value;
    logger.d(LOG_TAG, 'onChangeReservationTo', value);
    // only allow to date if it is more or equal to start date
    if (this.state.start <= value) {
      this.setState({
        end: value
      });
    }
  };
  onSelectReservationStartTime = e => {
    const value = e.target.value;
    logger.d(LOG_TAG, 'onSelectReservationStartTime', value);
    this.setState({
      startSelected: value,
      endSelected: value
    });
  };
  onSelectReservationEndTime = e => {
    const value = e.target.value;
    logger.d(LOG_TAG, 'onSelectReservationEndTime', value);
    this.setState({
      endSelected: value
    });
  };
  validateReservation() {
    if (this.state.name && this.state.carPlate &&
      this.state.start && this.state.end &&
      this.state.startSelected !== 'select' && this.state.endSelected !== 'select') {
      return true;
    }
    return false;
  }
  onClickAddReservation = () => {
    const validated = this.validateReservation();
    logger.d(LOG_TAG, 'onClickAddReservation', validated);
    if (validated) {
      this.props.setLoading(StateInfo.LoadingInfo.format(true, 'Creating new reservation'));

      const reservation = StateInfo.ReservationInfo
      .create(this.state.name, this.state.carPlate)
      .setDuration(
        this.state.start, WINDOW_OPTIONS[this.state.startSelected].value,
        this.state.end, WINDOW_OPTIONS[this.state.endSelected].value,);
      logger.d(LOG_TAG, 'onClickAddReservation', reservation);
      window.webapi.Group.postSlotReservation(
        this.state.siteInfo.group_id,
        this.state.siteInfo.site_id,
        this.state.slotInfo.slot_id,
        reservation,
        (response) => {
          const data = response.data;
          logger.d(LOG_TAG, 'onClickAddReservation', 'onResponse', data);
          this.resetReservation((isSuccess, error) => {
            setTimeout(() => {
              this.props.setLoading(StateInfo.LoadingInfo.format(false));
              if (!isSuccess) {
                const notify = dataHelper.formatError('Reservation', error.data);
                this.props.setNotification(
                  StateInfo.NotificationInfo.format('danger', notify.title, notify.message)
                );
              }
            }, 800);
          });
        },
        (error) => {
          const data = error.data;
          logger.d(LOG_TAG, 'onClickAddReservation', 'onError', data);
          setTimeout(() => {
            const notify = dataHelper.formatError('Reservation', data);
            this.props.setLoading(StateInfo.LoadingInfo.format(false));
            this.props.setNotification(
              StateInfo.NotificationInfo.format('danger', notify.title, notify.message)
            );
          }, 800);
        }
      )
    }
  };
  onClickRemoveReservation = param => {
    logger.d(LOG_TAG, 'onClickRemoveReservation', param);
    const reservation = this.state.reservations[param];
    window.confirm(`Are you sure you wish to delete reservation:
     \n${reservation.parkId}?\n${reservation.name}: ${reservation.carPlate}\n${reservation.startDate}-${reservation.startTime} ~ ${reservation.endDate}-${reservation.endTime}`) &&
    this.deleteReservation(reservation);
  };
  deleteReservation = value => {
    logger.d(LOG_TAG, 'deleteReservation', value);
    this.props.setLoading(StateInfo.LoadingInfo.format(true, 'Deleting reservation'));
    window.webapi.Group.delSlotReservation(
      this.state.siteInfo.group_id,
      this.state.siteInfo.site_id,
      this.state.slotInfo.slot_id,
      value.parkId,
      (response) => {
        const data = response.data;
        logger.d(LOG_TAG, 'deleteReservation', 'onResponse', data);
        this.resetReservation((isSuccess, error) => {
          setTimeout(() => {
            this.props.setLoading(StateInfo.LoadingInfo.format(false));
            if (!isSuccess) {
              const notify = dataHelper.formatError('Reservation', error.data);
              this.props.setNotification(
                StateInfo.NotificationInfo.format('danger', notify.title, notify.message)
              );
            }
          }, 800);
        });
      },
      (error) => {
        const data = error.data;
        logger.d(LOG_TAG, 'deleteReservation', 'onError', data);
        setTimeout(() => {
          const notify = dataHelper.formatError('Reservation', data);
          this.props.setLoading(StateInfo.LoadingInfo.format(false));
          this.props.setNotification(
            StateInfo.NotificationInfo.format('danger', notify.title, notify.message)
          );
        }, 800);
      }
    )
  };
}
export default connect(
  null,
  {
    setLoading,
    setNotification,
  }
)(DlgAddReservations);


