import logger from '../utils/logger';

const LOG_TAG = 'syncUserGroups';
export default {
  execute(groups, onCompleteCb) {
    logger.d(LOG_TAG, 'execute', groups);
    if (groups && groups.length > 0) {
      const groupResult = [];
      const onComplete = response => {
        if (onCompleteCb) {
          onCompleteCb(response);
        }
      };
      // start syncing group info
      this.syncNextGroup(0, groups, groupResult, onComplete);
    } else {
      if (onCompleteCb) {
        onCompleteCb([]);
      }
    }
  },
  syncNextGroup(index, groups, groupResult, onCompleteCb)
  {
    logger.d(LOG_TAG, 'syncNextGroup', index);
    if (groups.length <= index) {
      onCompleteCb(groupResult);
    } else {
      const onResponse = response => {
        groupResult.push(response.data);
        this.syncNextGroup(index+1, groups, groupResult, onCompleteCb);
      };
      const onError = error => {
        groupResult.push(null);
        this.syncNextGroup(index+1, groups, groupResult, onCompleteCb);
      };
      window.webapi.Group.get(
        groups[index],
        onResponse,
        onError
      )
    }

  },
};
