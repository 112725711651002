import React, {Component} from 'react';
import '../assets/css/row_.css';

class Row2Col extends Component {
  render() {
    return (
      <div
        className="list-group-item list-group-item-action flex-column align-items-start p-0"
        key={this.props.id}
        onClick={this.props.onClick ? this.props.onClick(this.props.id) : null}>
        {/*test 1*/}
        <div className="d-flex">
          <div className="list-item-col-index justify-content-center align-items-center">
            <h4>{this.props.index}</h4>
          </div>
          <div className="list-item-col-content align-items-center">
            <div
              className={`list-item-2-col__1 list-item-col-content__primary ${this.props.value1Style?this.props.value1Style:''}` }>
              {this.props.value1}
            </div>
            <div className="list-item-2-col__2 list-item-col-content__secondary">
              <label style={{margin: '0', padding: '0', fontSize: '1rem', fontWeight: 'bold'}}>
                {this.props.value2}
              </label>
              <label className="list-item-content__sub-text">
                {this.props.value2_1}
              </label>
              <label className="list-item-content__sub-text">
                {this.props.value2_2}
              </label>
            </div>
          </div>
          {
            this.props.clickOptions ?
              <div className="list-item-col-action justify-content-end align-items-center">
                {
                  this.props.clickOptions.length > 0 ?
                    this.props.clickOptions.map((option, index) => (
                      <div className="p-2"
                           key={index}
                           onClick={option.action(this.props.id)}>
                        {option.icon}
                      </div>
                    )) : <div></div>
                }
                <div className="p-2" />
              </div>
              :
              <div className="list-item-col-action justify-content-end align-items-center">
                {
                  this.props.onClickAction1 ?
                    <div className="p-2" onClick={this.props.onClickAction1(this.props.id)}>
                      {this.props.action1}
                    </div>
                    : null
                }
                <div className="p-1" />
                {
                  this.props.onClickAction2 ?
                    <div className="p-2" onClick={this.props.onClickAction2(this.props.id)}>
                      {this.props.action2}
                    </div>
                    : null
                }
                <div className="p-2" />
              </div>
          }
        </div>
      </div>
    );
  }
}

export default Row2Col;
