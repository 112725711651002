export default {
  user_id: 0,
  email: '',
  first_name: '',
  last_name: '',
  phone: '',
  address: '',
  groups: [],
  cannot_create_users: false,
  hash: '',
  blocked: false,
  is_sct: true,
  password: '',
  car_plates: [],
};

