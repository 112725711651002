import dataHelper from '../../utils/dataHelper';
export default function (copy) {
  let object;
  if (copy) {
    object = JSON.parse(JSON.stringify(copy));
  } else {
    object = {
      code: '',
      start: '', //"04:30",
      end: '', //"10:30",
      offer_end: '',
      description: '',//"Early Bird",
      price: 0 //600,
    };
  }
  object.createRateWindow = function createRateWindow(rate) {
    object.code = rate.code;
    object.start = rate.start;
    object.end = rate.end;
    object.offer_end = rate.offer_end;
    object.description = rate.description;
    object.price = rate.price;
    return object;
  }

  return object;
};

