import ApiHelper from '../apiHelper';
import logger from '../../utils/logger';

const LOG_TAG = 'api.auth';
const TARGET_API = 'api/auth/oauth';

export default {
  postOAuth(provider, payload, onResponseCb, onErrorCb) {
    const url = ApiHelper.createUrl(TARGET_API, provider);
    logger.d(LOG_TAG, 'postOAuth', url);

    const extraParams = {};
    ApiHelper.apiPost(url, payload, onResponseCb, onErrorCb, extraParams);
  }
};
