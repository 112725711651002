import logger from '../utils/logger';

const LOG_TAG = 'dataHelper';

const FIXED_RATE_PLANS = [
  {text: 'Early Bird', value: 'earlybird', sortId: 1},
  {text: 'Day Parking', value: 'day', sortId: 2},
  {text: 'Evening Parking', value: 'evening', sortId: 3},
  {text: 'Weekend Parking', value: 'weekend', sortId: 4},
];

const DAY_OF_WEEK = [
  {short: 'Mon', text: 'Monday', value: 'Monday', sortId: 1},
  {short: 'Tue', text: 'Tuesday', value: 'Tuesday', sortId: 2},
  {short: 'Wed', text: 'Wednesday', value: 'Wednesday', sortId: 3},
  {short: 'Thu', text: 'Thursday', value: 'Thursday', sortId: 4},
  {short: 'Fri', text: 'Friday', value: 'Friday', sortId: 5},
  {short: 'Sat', text: 'Saturday', value: 'Saturday', sortId: 6, weekend: true},
  {short: 'Sun', text: 'Sunday', value: 'Sunday', sortId: 7, weekend: true},
];
function DAY_OF_WEEK_SID(dayOfWeek) {
  switch (dayOfWeek) {
    case 'Monday': return 1;
    case 'Tuesday': return 2;
    case 'Wednesday': return 3;
    case 'Thursday': return 4;
    case 'Friday': return 5;
    case 'Saturday': return 6;
    case 'Sunday': return 7;
    default:
  }
};
const WINDOW_OPTIONS = [
  {value: '00:00', text: '00:00'}, {value: '00:30', text: '00:30'},
  {value: '01:00', text: '01:00'}, {value: '01:30', text: '01:30'},
  {value: '02:00', text: '02:00'}, {value: '02:30', text: '02:30'},
  {value: '03:00', text: '03:00'}, {value: '03:30', text: '03:30'},
  {value: '04:00', text: '04:00'}, {value: '04:30', text: '04:30'},
  {value: '05:00', text: '05:00'}, {value: '05:30', text: '05:30'},
  {value: '06:00', text: '06:00'}, {value: '06:30', text: '06:30'},
  {value: '07:00', text: '07:00'}, {value: '07:30', text: '07:30'},
  {value: '08:00', text: '08:00'}, {value: '08:30', text: '08:30'},
  {value: '09:00', text: '09:00'}, {value: '09:30', text: '09:30'},
  {value: '10:00', text: '10:00'}, {value: '10:30', text: '10:30'},
  {value: '11:00', text: '11:00'}, {value: '11:30', text: '11:30'},
  {value: '12:00', text: '12:00'}, {value: '12:30', text: '12:30'},
  {value: '13:00', text: '13:00'}, {value: '13:30', text: '13:30'},
  {value: '14:00', text: '14:00'}, {value: '14:30', text: '14:30'},
  {value: '15:00', text: '15:00'}, {value: '15:30', text: '15:30'},
  {value: '16:00', text: '16:00'}, {value: '16:30', text: '16:30'},
  {value: '17:00', text: '17:00'}, {value: '17:30', text: '17:30'},
  {value: '18:00', text: '18:00'}, {value: '18:30', text: '18:30'},
  {value: '19:00', text: '19:00'}, {value: '19:30', text: '19:30'},
  {value: '20:00', text: '20:00'}, {value: '20:30', text: '20:30'},
  {value: '21:00', text: '21:00'}, {value: '21:30', text: '21:30'},
  {value: '22:00', text: '22:00'}, {value: '22:30', text: '22:30'},
  {value: '23:00', text: '23:00'}, {value: '23:30', text: '23:30'},
  {value: '23:59', text: '24:00'},
];
const DEVICE_MODEL_ID_OPTIONS = [
  {value: '1000012', text: 'TBS-220'},
  {value: '1000119', text: 'TBS-223'},
];
export default {
  formatSecFromMin(value) {
    return value * 60;
  },
  formatSecFromMillisec(value) {
    return parseInt((value / 1000).toString(), 10);
  },
  formatMillisecFromSec(value) {
    return value * 1000;
  },
  formatTimestampFromSec(value) {
    return window.moment(this.formatMillisecFromSec(value)).format('YYYY-MMM-DD HH:mm');
  },
  formatTimestampFromString(value) {
    return window.moment(value, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MMM-DD HH:mm');
  },
  formatParkingDurationFromMin(value, simple) {
    let minutes = parseInt(value);
    let translateHour = 0;
    if (minutes >= 60) {
      translateHour = parseInt(minutes / 60, 10);
    }
    let translateMin = minutes - (translateHour * 60);
    // logger.d(LOG_TAG, 'formatParkingDurationFromMin', minutes, translateHour, translateMin);
    if (simple) {
      return `${translateHour > 0 ? translateHour + ' hr(s)' : ''} ${translateMin > 0 ? translateMin + ' mins' : ''}`;
    }
    return `${translateHour > 0 ? translateHour + ' hour(s)' : ''} ${translateMin > 0 ? translateMin + ' minutes' : ''}`;
  },
  formatMinFromSec(value) {
    return value / 60;
  },
  formatPriceFromValue(value) {
    if (isNaN(value)) {
      return '---';
    }
    return `$${(value / 100).toFixed(2)}`
  },
  parseFlatRateDays(siteInfo) {
    let days = '';
    if (siteInfo.fixed_rates) {
      siteInfo.fixed_rates.forEach((rate, index) => {
        days += rate.day;
        if (index < siteInfo.fixed_rates.length -1) {
          days += ', ';
        }
      });
    }
    return days;
  },
  formatInitialPromoRate(siteInfo) {
    if (siteInfo.initial_duration > 0) {
      return `- Initial rate: ${this.formatPriceFromValue(siteInfo.initial_rate)} for first ${siteInfo.initial_duration} minutes.`;
    }
    return '';
  },
  formatFixedRates(siteInfo) {
    if (siteInfo.fixed_rates) {
      return `- Fixed rates available on: ${this.parseFlatRateDays(siteInfo)}.`;
    }
    return '';
  },
  getCurrentTimestampInSec() {
    return parseInt(window.moment.now() / 1000);
  },
  formatError(prefix, data) {
    return {
      title: `${prefix} Error: ${data.err_code}`,
      message: `${data.err_message}`
    }
  },
  paddingZero(value, len) {
    let paddedValue = value;
    while (len > paddedValue.length) {
      paddedValue = '0' + paddedValue;
    }
    return paddedValue;
  },
  getDaysOfWeek() {
    return DAY_OF_WEEK;
  },
  getWeekDays() {
    return DAY_OF_WEEK.filter((day) => !day.weekend);
  },
  getWeekend() {
    return DAY_OF_WEEK.filter((day) => day.weekend);
  },
  getDayOfWeekSid(dayOfWeek) {
    return DAY_OF_WEEK_SID(dayOfWeek);
  },
  getWindowOptions(dayOfWeek) {
    return WINDOW_OPTIONS;
  },
  getFixedRatePlans() {
    return FIXED_RATE_PLANS;
  },
  getModelIdOptions() {
    return DEVICE_MODEL_ID_OPTIONS;
  },
  calculateParkingCharge(existingParkingDuration/* existing session*/, duration, minDuration, minCharge, initialDuration, initialRate) {
    let initialRatePerMin = (initialDuration > 0) ? initialRate / initialDuration : 0;
    let defaultRatePerMin = (minDuration > 0) ? minCharge / minDuration : 0;

    if (isNaN(initialRatePerMin)) {
      initialRatePerMin = 0;
    }
    if (isNaN(defaultRatePerMin)) {
      defaultRatePerMin = 0;
    }
    logger.d(LOG_TAG, 'calculateParkingCharge', existingParkingDuration, duration, minDuration, minCharge, initialDuration, initialRate);
    logger.d(LOG_TAG, 'calculateParkingCharge', initialRatePerMin, defaultRatePerMin);
    let charge = 0;
    if (existingParkingDuration > 0) {
      if (existingParkingDuration > initialDuration) {
        charge = duration * defaultRatePerMin;
        //logger.d(LOG_TAG, 'calculateParkingCharge1');
      } else {
        const initialRateDuration = initialDuration - existingParkingDuration;
        const totalParkingDuration = existingParkingDuration + duration;
        const defaultRateDuration = totalParkingDuration > initialDuration ? (totalParkingDuration - initialDuration) : 0;
        charge = (initialRateDuration * initialRatePerMin) + (defaultRateDuration * defaultRatePerMin);
        //logger.d(LOG_TAG, 'calculateParkingCharge2');
      }
    } else {
      if (duration > initialDuration) {
        const defaultRateDuration = duration - initialDuration;
        charge = (initialDuration * initialRatePerMin) + (defaultRateDuration * defaultRatePerMin);
        //logger.d(LOG_TAG, 'calculateParkingCharge3');
      } else {
        charge = duration * initialRatePerMin;
        //logger.d(LOG_TAG, 'calculateParkingCharge4');
      }
    }
    logger.d(LOG_TAG, 'calculateParkingCharge', charge);
    return charge;
  }
};
