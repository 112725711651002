import React from 'react';
import {injectStripe} from 'react-stripe-elements';
import CardSection from './cardSection';
import logger from '../../utils/logger';
import connect from 'react-redux/es/connect/connect';
import {setLoading, setNotification} from '../../store/actions';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import localStorageHelper from '../../utils/localStorageHelper';
import StateInfo from '../../store/states';

const LOG_TAG = 'AddCardForm';
class AddCardForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: null,
      stripe: null,
      error: '',
      token: '',
      name: '',
    };
  }
  componentDidMount() {
    logger.d(LOG_TAG, 'componentDidMount', this.props.name);
    const userInfo = localStorageHelper.getUserInfo();
    if (userInfo) {
      this.setState({
        userInfo: userInfo
      })
    }
  }
  render() {
    return (
      <form onSubmit={this.onClickSubmit}>
        <CardSection />
        <div>
          {
            this.state.error ?
              <small className="form-text clr-warning">{this.state.error}
              </small>
              : null
          }
        </div>
        <div>
          {
            this.state.token ?
              <small className="form-text">{this.state.token}
              </small>
              : null
          }
        </div>
        <button className="btn btn-invert btn-default float-right mt-3" onClick={this.onClickAddCard}>
          <FontAwesomeIcon icon="plus"></FontAwesomeIcon> Add
        </button>
      </form>
    );
  };
  onClickSubmit = e => {
    // We don't want to let default form submission happen here, which would refresh the page.
    e.preventDefault();
    logger.d(LOG_TAG, 'onClickSubmit');
  };
  onClickAddCard = e => {
    // We don't want to let default form submission happen here, which would refresh the page.
    e.preventDefault();
    this.props.setLoading(StateInfo.LoadingInfo.format(true, 'Adding new payment method'));

    logger.d(LOG_TAG, 'onClickAddCard');
    this.props.stripe
      .createToken({type: 'card', name: this.state.name})
      .then((response) => {
        logger.d(LOG_TAG, 'onClickAddCard', 'createToken', response);
        if (response.error) {
          this.props.setLoading(StateInfo.LoadingInfo.format(false, ''));
          this.setState({
            error: response.error.message,
            token: '',
          })
        } else {
          window.webapi.User.postPaymentMethod(
            this.state.userInfo.user_id,
            {token: response.token.id},
            (response) => {
              this.props.setLoading(StateInfo.LoadingInfo.format(false, ''));
              const data = response.data;
              if (this.props.onResult) {
                this.props.onResult(true, data);
              };
            },
            (error) => {
              this.props.setLoading(StateInfo.LoadingInfo.format(false, ''));
              const data = error.data;
              this.setState({
                error: data.err_message,
                token: '',
              });
            }
          );
        }
      });
  }
}
export default injectStripe(
  connect(
    null,
    {
      setLoading,
      setNotification,
    }
  )(AddCardForm)
);
