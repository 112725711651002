import Binder from './index';
import logger from '../../utils/logger';

const LOG_TAG = 'binder.user';
export default {
  get(userId, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'get', userId);
    Binder.WebApi().User.get(userId, onResponseCb, onErrorCb);
  },
  put(userId, payload, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'put', userId);
    Binder.WebApi().User.put(userId, payload, onResponseCb, onErrorCb);
  },
  putCarPlate(userId, payload, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'putCarPlate', userId, payload);
    Binder.WebApi().User.putCarPlate(userId, payload, onResponseCb, onErrorCb);
  },
  postStartParking(userId, payload, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'postStartParking', userId, payload);
    Binder.WebApi().User.postStartParking(userId, payload, onResponseCb, onErrorCb);
  },
  putExtendParking(userId, parkId, payload, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'putExtendParking', userId, parkId, payload);
    Binder.WebApi().User.putExtendParking(userId, parkId, payload, onResponseCb, onErrorCb);
  },
  getParkingHistory(userId, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'getParkingHistory', userId);
    Binder.WebApi().User.getParkingHistory(userId, onResponseCb, onErrorCb);
  },
  getLastParkingHistory(userId, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'getLastParkingHistory', userId);
    Binder.WebApi().User.getLastParkingHistory(userId, onResponseCb, onErrorCb);
  },
  postPaymentMethod(userId, payload, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'postPaymentMethod', userId, payload);
    Binder.WebApi().User.postPaymentMethod(userId, payload, onResponseCb, onErrorCb);
  },
  getPaymentMethods(userId, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'getPaymentMethods', userId);
    Binder.WebApi().User.getPaymentMethods(userId, onResponseCb, onErrorCb);
  },
  delPaymentMethod(userId, pId, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'delPaymentMethod', userId, pId);
    Binder.WebApi().User.delPaymentMethod(userId, pId, onResponseCb, onErrorCb);
  }
};
