import logger from '../../utils/logger';

const LOG_TAG = 'api-mock.admin';
export default {
  postUser(payload, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'postUser', payload);
    setTimeout(() => {
      onResponseCb({
        data: payload
      });
    }, 800);
  },
  getUser(userId, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'getUser', userId);
    setTimeout(() => {
      onResponseCb({
        data: {}
      });
    }, 800);
  },
  putUser(userId, payload, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'putUser', userId);
    setTimeout(() => {
      onResponseCb({
        data: {}
      });
    }, 800);
  },
  delUser(userId, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'delUser', userId);
    setTimeout(() => {
      onResponseCb({
        data: {}
      });
    }, 800);
  },
  getLocalUsers(onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'getLocalUsers');
    setTimeout(() => {
      onResponseCb({
        data: {}
      });
    }, 800);
  },
  postGroup(payload, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'postGroup', payload);
    setTimeout(() => {
      onResponseCb({
        data: {}
      });
    }, 800);
  },
  /*
  getGroup(groupId, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'getGroup', groupId);
    setTimeout(() => {
      onResponseCb({
        data: {}
      });
    }, 800);
  },
  */
  putGroup(groupId, payload, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'putGroup', groupId, payload);
    setTimeout(() => {
      onResponseCb({
        data: {}
      });
    }, 800);
  },
  postGroupSite(groupId, payload, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'postGroupSite', payload);
    setTimeout(() => {
      onResponseCb({
        data: {}
      });
    }, 800);
  },
  delGroupSite(groupId, siteId, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'delGroupSite', groupId, siteId);
    setTimeout(() => {
      onResponseCb({
        data: {}
      });
    }, 800);
  },
  searchEmail(email, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'searchEmail', email);
    setTimeout(() => {
      onResponseCb({
        data: {}
      });
    }, 800);
  },
  searchCarPlate(carPlate, onResponseCb, onErrorCb) {
    logger.d(LOG_TAG, 'searchCarPlate', carPlate);
    setTimeout(() => {
      onResponseCb({
        data: []
      });
    }, 800);
  }
};
