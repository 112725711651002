import React, {Component} from 'react';
import imgDashboardLogo from '../assets/images/logo.png';
import Nav from 'react-bootstrap/Nav';
import {connect} from 'react-redux';
import {setLocation, setAuth, setAuthUser, setAuthUserGroups, setUser} from '../store/actions';
import logger from '../utils/logger';
import Routes from '../routes';
import {withRouter} from 'react-router-dom';
import localStorageHelper from '../utils/localStorageHelper';

const LOG_TAG = 'PageHeader';
class PageHeader extends Component {
  constructor(props) {
    super(props);
    // init and sync
    this.state = {
      authUserInfo: null,
      locationInfo: {
        tab: '',
        menu: '',
        name: '',
      },
    };
  }
  componentDidMount() {
    logger.d(LOG_TAG, 'componentDidMount');
    this.resetAuthUserInfo();
    this.resetLocation(this.props.locationInfo);
  }
  componentDidUpdate(oldProps) {
    const newProps = this.props;
    if (oldProps.authUserInfo !== newProps.authUserInfo) {
      this.resetAuthUserInfo();
    }
    if (oldProps.locationInfo !== newProps.locationInfo) {
      this.resetLocation(newProps.locationInfo);
    }
  }
  resetLocation(locationInfo) {
    const route = Routes.main.find((route) => {
      return route.id === locationInfo.tab;
    });
    logger.d(LOG_TAG, 'componentDidUpdate', locationInfo, route);
    this.setState({
      locationInfo: {
        tab: locationInfo.tab,
        menu: locationInfo.menu,
        name: route ? route.name : '',
      },
    })
  }
  resetAuthUserInfo() {
    logger.d(LOG_TAG, 'componentDidUpdate', 'resetAuthUserInfo');
    const authUserInfo = localStorageHelper.getAuthUserInfo();
    this.setState({
      authUserInfo: authUserInfo,
    })
  }
  render() {
    const onNaviHandler = this.props.naviOptions.handler;
    return (
      <div className="fixed-top pt-3 pl-2 pr-2 app-main__header--color">
        {/* header logo */}
        <section>
          <div className="container-fluid">
            <div className="row">
              <div className="d-flex col-md-12 justify-content-between">
                <img
                  className="nav-desktop app__img--small-50"
                  src={
                    process.env.REACT_APP_IS_PRODUCTION === 'true'
                      ? imgDashboardLogo
                      : ''
                  }
                  alt="logo"/>
                <div className="nav-mobile app-main__header-title pl-1 pt-1">{this.state.locationInfo.name}</div>
                <div className="nav-desktop mr-3">{this.state.authUserInfo ? `Hi ${this.state.authUserInfo.first_name}` : ''}</div>
              </div>
            </div>
          </div>
        </section>

        {/* header menu */}
        <section className="nav-mobile app-main__header-tab-section app-item-underline-border">
        </section>
        <section className="nav-desktop container-fluid app-main__header-tab-section app-item-underline-border">
          <div className="p-2" />
          <div className="row flex-nowrap">
            <div className="col-md-9">
              <Nav
                className="flex-nowrap justify-content-center"
                onSelect={k => onNaviHandler(k)}
              >
                {this.props.naviOptions.options.map((option, index) => (
                  <Nav.Item
                    key={option.id}>
                    <Nav.Link
                      eventKey={option.id}
                      bsPrefix={'nav-link-override'}
                    >
                      <div
                        className={
                          option.id === this.state.locationInfo.tab
                            ? 'ml-5 mr-4 pb-2 li__tab-menu-item--focus'
                            : 'ml-5 mr-4 pb-2'
                        }
                      >
                        {option.name}
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </div>
            <div className="col-md-3">
              <div className="row flex-nowrap justify-content-end">
                {/*<div>Settings</div>
                <div className="mr-5" />
                <div>Help</div>
                <div className="mr-5" />*/}
                <div onClick={this.onClickLogout}>Logout</div>
                <div className="mr-5" />
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
  onClickLogout = e => {
    logger.d(LOG_TAG, 'onclickLogout');
    this.props.setAuth(null);
    this.props.setAuthUser(null);
    this.props.setAuthUserGroups(null);
    this.props.setUser(null);
    this.props.history.replace(Routes.login.path);
  }
}
// listens to state change
const mapStateToProps = state => {
  const locationInfo = state.locationInfo;
  const authUserInfo = state.authUserInfo;
  return {
    locationInfo,
    authUserInfo,
  };
};
// connect and map actions
export default withRouter(connect(
  mapStateToProps,
  {
    setLocation,
    setAuth,
    setAuthUser,
    setAuthUserGroups,
    setUser
  }
)(PageHeader));
