import dataHelper from '../../utils/dataHelper';
export default function (copy) {
  let object;
  if (copy) {
    object = JSON.parse(JSON.stringify(copy));
  } else {
    object = {
      user_id: 0,
      car_plate: '',
      slot_id: 0,
      site_id: 0,
      estimated_duration: 0,
      paymeth_id: 0,
      actual_time_end: 0,
      code: null, // fixed rate parking code
    };
  }

  object.isExpired = function isExpired() {
    const now = dataHelper.getCurrentTimestampInSec();
    return now > object.actual_time_end;
  };
  // model helper
  /* create(userId, siteId, slotId, carPlate) {
    return {
      user_id: userId,
      site_id: siteId,
      slot_id: slotId,
      car_plate: carPlate,
      setDuration(duration) {
        this.estimated_duration = duration;
        return this;
      },
      setPaymentMethod(paymentId) {
        this.paymeth_id = paymentId;
        return this;
      }
    };
  }, */
  object.setDuration = function setDuration(duration) {
    object.estimated_duration = duration;
  };
  object.setCode = function setCode(value) {
    object.code = value;
  };
  object.setPaymentMethod = function setPaymentMethod(paymentId) {
    object.paymeth_id = paymentId;
  };
  return object;
};

