import React, {Component} from 'react';
import firebaseService from '../handlers/firebaseService';
import searchCarPlate from '../handlers/searchCarPlate';

import logger from '../utils/logger';

const LOG_TAG = 'CardSearchUserSessionByCarPlate';
class CardSearchUserSessionByCarPlate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchCarPlate: '',
      searchError: '',
      hasValidSearch: false,
    };
  }
  componentDidMount() {
    const userInfo = this.props.userInfo;
    logger.d(LOG_TAG, 'componentDidMount', userInfo);
    this.resetState(userInfo);
  }
  componentDidUpdate(oldProps) {
    const newProps = this.props;
    if (oldProps.userInfo !== newProps.userInfo) {
      logger.d(LOG_TAG, 'componentDidUpdate', 'update');
      this.resetState(newProps.userInfo);
    }
  }
  resetState(userInfo) {
    logger.d(LOG_TAG, 'resetState', userInfo);
    if (userInfo) {
      this.setState({
        // ** need to get last carplate
        hasValidSearch: true,
      })
    } else {
      this.setState({
        searchCarPlate: '',
        hasValidSearch: false,
      })
    }
  }
  render() {
    return (
      <div className="card">
        <div className="card-body">
          <div className="row">
            <form onSubmit={this.onClickSearch}
                  className="col-md-12">
              <div className="form-group">
                <label htmlFor="">Search by Car Plate</label>
                <div className="d-flex">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Car Registration"
                    value={this.state.searchCarPlate}
                    maxLength={6}
                    onChange={this.onChangeSearchCarPlate}
                    disabled={this.state.hasValidSearch ? true : false}/>
                  <button
                    className="btn ml-2">
                    { this.state.hasValidSearch ? 'Reset' : 'Search'}
                  </button>
                </div>
                {
                  this.state.searchError ?
                    <small className="form-text clr-warning">{this.state.searchError}
                    </small>
                    : null
                }
              </div>
            </form>
          </div>
        </div>
      </div>

    );
  }
  onChangeSearchCarPlate = value => {
    this.setState({
      searchCarPlate: value.target.value.toUpperCase(),
      searchError: '',
    });
  };
  onClickSearch = e => {
    const vm = this;
    e.preventDefault();
    if (this.state.hasValidSearch) {
      // reset
      this.setState({
        searchCarPlate: '',
        searchError: '',
        hasValidSearch: false,
      });
      this.props.onResult(null, null);
    } else {
      const carPlate = this.state.searchCarPlate;
      logger.d(LOG_TAG, 'onClickSearch', carPlate);

      if (carPlate) {
        firebaseService.searchUserByCarPlate(
          carPlate,(resLastCarPlateSession, resLastCarPlateUsage) => {
            let lastSession;
            let lastProfile;
            if (resLastCarPlateSession.admin) {
              lastSession = resLastCarPlateSession;
            } else {
              lastSession = resLastCarPlateSession.last_session;
              lastProfile = resLastCarPlateSession.profile;
            }
            if (carPlate.toLowerCase() === lastSession.car_plate) {
              logger.d(LOG_TAG, 'searchUserByCarPlate', lastSession, lastProfile, resLastCarPlateUsage);
              let userId = lastSession.user_id;
              if (lastSession.fixed_rate_parking) {
                userId = lastProfile.user_id;
                // lastSession.start_parking_at = resLastCarPlateUsage.start_parking_at;
              }

              window.webapi.User.get(userId, (resUserInfo) => {
                  const userInfo = resUserInfo.data;
                  logger.d(LOG_TAG, 'onClickSearch', 'userGet', userInfo);
                  searchCarPlate.searchViaSites(
                    carPlate,
                    (result, resVipSites) => {
                      logger.d(LOG_TAG, 'onClickSearch', 'searchViaSites', resVipSites);
                      vm.setState({
                        hasValidSearch: true,
                      });
                      vm.props.onResult(userInfo, lastSession, resVipSites, lastProfile);
                    }
                  )

                },
                (error) => {
                  logger.d(LOG_TAG, 'onClickSearch', 'onError', error);
                  const data = error.data;
                  vm.setState({
                    searchError: data.err_message,
                  });
                })
            } else {
              vm.setState({
                searchError: 'No match found.',
              });
            }
          }, function (error) {
            vm.setState({
              searchError: error,
            });
          });
      } else {
        vm.setState({
          searchError: 'Invalid car plate'
        });
      }
    }
  };
}

export default CardSearchUserSessionByCarPlate;

