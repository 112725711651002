import logger from '../utils/logger';

const LOG_TAG = 'syncParkSlotAvailableTime';
export default {
  execute(slotId, onCompleteCb, excludeCurrent) {
    logger.d(LOG_TAG, 'execute', slotId, excludeCurrent);
    if (excludeCurrent) {
      window.webapi.Query.queryAvailableTimeBeyondActiveSession(
        slotId,
        (response) => {
          const data = response.data;
          if (onCompleteCb) {
            onCompleteCb(true, data);
          }
        },
        (error) => {
          const data = error.data;
          if (onCompleteCb) {
            onCompleteCb(false, data);
          }
        });
    } else {
      window.webapi.Query.queryAvailableTime(
        slotId,
        (response) => {
          const data = response.data;
          if (onCompleteCb) {
            onCompleteCb(true, data);
          }
        },
        (error) => {
          const data = error.data;
          if (onCompleteCb) {
            onCompleteCb(false, data);
          }
        });
    }
  },
};
