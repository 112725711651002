import logger from '../../../utils/logger';
import dataHelper from '../../../utils/dataHelper';
const LOG_TAG = 'api-mock-dataset';
const mockAuth = {
  jwt: {
    token: 'mock-token',
    /*expires: 1557700068,*/
    expires: 9999999999,
    new: true
  },
  identities: [
    {
      provider: '',
      provider_id: '',
      expires: 9999999999,
      name: 'Mock user',
      first_name: 'Mock',
      last_name: 'User',
      email: 'mock@gmail.com',
      email_verified: true,
      last_auth: true,
      role: 'admin'
    }
  ],
  managed_users: [
    {
      user_id: 1111111,
      name: 'Mock user',
      first_name: 'Mock',
      last_name: 'User',
      email: 'mock@gmail.com'
    }
  ]
};

const slots = [
  {
    name: 'Slot A',
    price: 2,
    minDuration: dataHelper.formatSecFromMin(1),
    maxDuration: dataHelper.formatSecFromMin(2),
    rate: '$2.00/hr',
    timetable: [
      {day: 'mon', from: '09:00', to: '17:00',},
      {day: 'tue', from: '09:00', to: '17:00',},
      {day: 'wed', from: '09:00', to: '17:00',},
      {day: 'thu', from: '09:00', to: '17:00',},
      {day: 'fri', from: '09:00', to: '17:00',},
      {day: 'sat', from: '09:00', to: '17:00',},
      {day: 'sun', from: '09:00', to: '17:00',},
    ]
  },
  {
    name: 'Slot B',
    price: 2,
    minDuration: dataHelper.formatSecFromMin(1),
    maxDuration: dataHelper.formatSecFromMin(2),
    rate: '$2.00/hr',
    timetable: [
      {day: 'mon', from: '09:00', to: '17:00',},
      {day: 'tue', from: '09:00', to: '17:00',},
      {day: 'wed', from: '09:00', to: '17:00',},
      {day: 'thu', from: '09:00', to: '17:00',},
      {day: 'fri', from: '09:00', to: '17:00',},
    ]
  },
  {
    name: 'Slot C',
    price: 2,
    minDuration: dataHelper.formatSecFromMin(1),
    maxDuration: dataHelper.formatSecFromMin(2),
    rate: '$2.00/hr',
    timetable: [
      {day: 'mon', from: '09:00', to: '10:00',},
      {day: 'mon', from: '12:00', to: '14:00',},
      {day: 'mon', from: '15:00', to: '17:00',},
      {day: 'thu', from: '09:00', to: '17:00',},
      {day: 'fri', from: '09:00', to: '17:00',},
      {day: 'sat', from: '09:00', to: '17:00',},
      {day: 'sun', from: '09:00', to: '17:00',},
    ]
  },
  {
    name: 'Slot D',
    price: 2,
    minDuration: dataHelper.formatSecFromMin(1),
    maxDuration: dataHelper.formatSecFromMin(2),
    rate: '$2.00/hr',
    timetable: [
      {day: 'sat', from: '09:00', to: '17:00',},
      {day: 'sun', from: '09:00', to: '17:00',},
    ]
  },
];
function createParkSite(id, title, desc, lat, lng) {
  return {
    id: id,
    title: title,
    desc: desc,
    lat: lat,
    lng: lng,
  }
}
function createParkSiteInfo(id, title, desc, lat, lng, slots) {
  const parkSite = createParkSite(id, title, desc, lat, lng);
  parkSite.slots = slots;
  return parkSite;
}
export default {
  getOAuth() {
    return mockAuth;
  },
  getParkSites() {
    return [
      createParkSite(
        1,
        'Pohutukawa Drive Car Park',
        'Description about Pohutukawa Drive Car Park',
        -36.896045,
        174.786786,

      ),
      createParkSite(
        2,
        'Manukau Station Road car park',
        'Description about Manukau Station Road car park',

        -36.993634,
        174.878862,
      ),
      createParkSite(
        3,
        'Westfield Manukau City Car Parking',
        'Description about Westfield Manukau City Car Parking',

        -36.989317,
        174.879992,
      ),
      createParkSite(
        4,
        'Secure Parking - Old Brewery Car Park',
        'Description about Secure Parking - Old Brewery Car Park',

        -36.866429,
        174.775249,
      ),
      createParkSite(
          5,
          'Civic Park',
          'Description about Civic Par',

          -36.852650,
          174.762674,
      ),
    ];
  },
  getSiteInfo(sId) {
    let info = {};
    switch (sId) {
      case '1':
        info = createParkSiteInfo(
            1,
            'Pohutukawa Drive Car Park',
            'Description about Pohutukawa Drive Car Park',
            -36.896045,
            174.786786,
          slots
        );
        break;
      case '2':
        info = createParkSiteInfo(
            2,
            'Manukau Station Road car park',
            'Description about Manukau Station Road car park',

            -36.993634,
            174.878862,
          slots
        );
        break;
      case '3':
        info = createParkSiteInfo(
            3,
            'Westfield Manukau City Car Parking',
            'Description about Westfield Manukau City Car Parking',

            -36.989317,
            174.879992,
          slots
        );
        break;
      case '4':
        info = createParkSiteInfo(
            4,
            'Secure Parking - Old Brewery Car Park',
            'Description about Secure Parking - Old Brewery Car Park',

            -36.866429,
            174.775249,
          slots
        );
        break;
      case '5':
        info = createParkSiteInfo(
            5,
            'Civic Park',
            'Description about Civic Par',

            -36.852650,
            174.762674,
            slots
        );
        break;
      default:
        return {}
    }
    logger.d(LOG_TAG, 'getSiteInfo', sId, info);
    return info;
  },
};
