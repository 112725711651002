import React, {Component} from 'react';
import imgLogo from '../assets/images/logo.png';

class NoMatch extends Component {
  render() {
    return (
      <div className="container mt-3">
        {/* SignIn section */}
        <section>
          <section className="row justify-content-center mt-5 p-2">
            <img
              className="app__img-120"
              src={process.env.REACT_APP_IS_PRODUCTION === 'true' ? imgLogo : ''}
              alt="logo"
            />
          </section>
          <section className="row justify-content-center p-3">
            <h4>Page not found.</h4>
          </section>
        </section>
      </div>
    );
  }
}

export default NoMatch;

