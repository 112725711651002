const KEY_AUTH_INFO = 'key-auth-info';
const KEY_AUTH_USER_INFO = 'key-auth-user-info';
const KEY_AUTH_USER_GROUPS = 'key_auth-user-groups';
const KEY_USER_INFO = 'key_user_info';
const KEY_LAST_SESSION_INFO = 'key_last_session_info';
const KEY_LAST_GROUP = 'key_last_group';

const KEY_IS_MOCK = 'key-is-mock';
const KEY_IS_VISITOR = 'key-is-visitor';

export default {
  setAuthInfo(obj) {
    if (obj !== null) {
      window.localStorage.setItem(KEY_AUTH_INFO, JSON.stringify(obj));
    } else {
      window.localStorage.removeItem(KEY_AUTH_INFO);
    }
  },
  getAuthInfo() {
    const data = window.localStorage.getItem(KEY_AUTH_INFO);
    if (data) {
      return JSON.parse(data);
    }
    return null;
  },
  setAuthUserInfo(obj) {
    if (obj !== null) {
      window.localStorage.setItem(KEY_AUTH_USER_INFO, JSON.stringify(obj));
    } else {
      window.localStorage.removeItem(KEY_AUTH_USER_INFO);
    }
  },
  getAuthUserInfo() {
    const data = window.localStorage.getItem(KEY_AUTH_USER_INFO);
    if (data) {
      return JSON.parse(data);
    }
    return null;
  },
  setAuthUserGroups(obj) {
    if (obj !== null) {
      window.localStorage.setItem(KEY_AUTH_USER_GROUPS, JSON.stringify(obj));
    } else {
      window.localStorage.removeItem(KEY_AUTH_USER_GROUPS);
    }
  },
  getAuthUserGroups() {
    const data = window.localStorage.getItem(KEY_AUTH_USER_GROUPS);
    if (data) {
      return JSON.parse(data);
    }
    return [];
  },
  setUserInfo(obj) {
    if (obj !== null) {
      window.localStorage.setItem(KEY_USER_INFO, JSON.stringify(obj));
    } else {
      window.localStorage.removeItem(KEY_USER_INFO);
    }
  },
  setLastSessionInfo(obj) {
    if (obj !== null) {
      window.localStorage.setItem(KEY_LAST_SESSION_INFO, JSON.stringify(obj));
    } else {
      window.localStorage.removeItem(KEY_LAST_SESSION_INFO);
    }
  },
  getUserInfo() {
    const data = window.localStorage.getItem(KEY_USER_INFO);
    if (data) {
      return JSON.parse(data);
    }
    return null;
  },
  getLastSessionInfo() {
    const data = window.localStorage.getItem(KEY_LAST_SESSION_INFO);
    if (data) {
      return JSON.parse(data);
    }
    return null;
  },
  setIsMock(value) {
    if (value !== null) {
      window.localStorage.setItem(KEY_IS_MOCK, JSON.stringify(value));
    } else {
      window.localStorage.removeItem(KEY_IS_MOCK);
    }
  },
  getIsMock() {
    const data = window.localStorage.getItem(KEY_IS_MOCK);
    return data === 'true' ? true : false;
  },
  setIsVisitor(value) {
    if (value !== null) {
      window.localStorage.setItem(KEY_IS_VISITOR, JSON.stringify(value));
    } else {
      window.localStorage.removeItem(KEY_IS_VISITOR);
    }
  },
  getIsVisitor() {
    const data = window.localStorage.getItem(KEY_IS_VISITOR);
    return data === 'true' ? true : false;
  },
  setLastGroup(value) {
    if (value !== null) {
      window.localStorage.setItem(KEY_LAST_GROUP, JSON.stringify(value));
    } else {
      window.localStorage.removeItem(KEY_LAST_GROUP);
    }
  },
  getLastGroup() {
    const data = window.localStorage.getItem(KEY_LAST_GROUP);
    if (data) {
      return JSON.parse(data);
    }
    return null;
  },
};
