import React, {Component} from 'react';
import {connect} from 'react-redux';
import {setLoading, setNotification} from '../store/actions';
import imgLocationOn from '../assets/images/location_on.png';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import RangeSlider from '../components/rangeSlider';
import AddressAutocomplete from '../components/google/addresssAutocomplete';
import GoogleMap from '../components/google/map';
import logger from '../utils/logger';
import localStorageHelper from '../utils/localStorageHelper';
import StateInfo from '../store/states';
import accessHelper from '../utils/accessHelper';
import dataHelper from '../utils/dataHelper';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const LOG_TAG = 'DlgAddParkSite';
const MIN_DURATION_OPTIONS = [
  {value: 15, text: 15,},
  {value: 30, text: 30,},
  {value: 60, text: 60,},
];
const INITIAL_DURATION_OPTIONS = [
  {value: 15, text: 15,},
  {value: 30, text: 30,},
  {value: 60, text: 60,},
  {value: 120, text: '120 (2hr)',},
  {value: 180, text: '180 (3hr)',},
  {value: 240, text: '240 (4hr)',},
  {value: 300, text: '300 (5hr)',},
  {value: 360, text: '360 (6hr)',},
];
const MAX_DURATION_OPTIONS = [
  {value: 60, text: 60,},
  {value: 120, text: '120 (2hr)',},
  {value: 180, text: '180 (3hr)',},
  {value: 240, text: '240 (4hr)',},
  {value: 300, text: '300 (5hr)',},
  {value: 360, text: '360 (6hr)',},
  {value: 420, text: '420 (7hr)',},
  {value: 480, text: '480 (8hr)',},
  {value: 720, text: '720 (12hr)',},
  {value: 1440, text: '1440 (24hr)',},
  {value: 2880, text: '2880 (48hr)',},
];
const TOW_GRACE_PERIOD_OPTIONS = [
  {value: 15, text: 15,},
  {value: 30, text: 30,},
  {value: 60, text: 60,},
  {value: 90, text: 90,},
  {value: 120, text: 120,},
  {value: 150, text: 150,},
  {value: 180, text: 180,},
];

const FIND_DURATION_OPTION_INDEX = (value, options) => {
  const index = options.findIndex((option) => value === option.value);
  // logger.d(LOG_TAG, 'FIND_DURATION_OPTION_INDEX', value, index);
  return index === -1 ? 'select' : index;
};
const DEFAULT_MIN_RATE = 50;
const DEFAULT_INITIAL_RATE = 0;
class DlgAddParkSite extends Component {
  static defaultProps = {
    center: {
      lat: -36.894681,
      lng: 174.742858
    },
    zoom: 12
  };
  constructor(props) {
    super(props);
    this.state = {
      siteInfo: null,
      authUserInfo: null,

      groupOptionSelected: 'select',
      groupOptions: [],
      groupNewName: '',

      name: '',
      minDurationSelected: 'select',
      maxDurationSelected: 'select',
      towGracePeriodSelected: 'select',
      rate: DEFAULT_MIN_RATE, // default minimum rate is $0.50

      lat: '',
      lng: '',
      address: '',
      description: '',
      logoUrl: '',
      marker: null,

      isInternalTestOnly: true,

// initial rate options
      initialRateEnabled: true,
      initialDurationSelected: 'select',
      initialRate: DEFAULT_INITIAL_RATE,

// fixed rate options
      fixedRatePlan: 'select',
      fixedRateDaysOfWeek: [],
      fixedRateStartSelected: 'select',
      fixedRateEndSelected: 'select',
      fixedRateOfferEndSelected: 'select',
      fixedRatePrice: DEFAULT_MIN_RATE,
      fixedRateWindows: [/*
        {
          description: dataHelper.getFixedRatePlans()[this.state.fixedRatePlan].text,
          day: dataHelper.getDaysOfWeek()[this.state.fixedRateDayOfWeek].text,
          start: dataHelper.getWindowOptions()[this.state.fixedRateStartSelected].value,
          end: dataHelper.getWindowOptions()[this.state.fixedRateEndSelected].value,
          price: 0,
        }
        */
      ],
    };
  };
  componentDidMount() {
    logger.d(LOG_TAG, 'componentDidMount');
    this.resetState();
  };
  componentDidUpdate(oldProps) {
    const newProps = this.props;
    if (oldProps.siteInfo !== newProps.siteInfo) {
      logger.d(LOG_TAG, 'componentDidUpdate', 'siteInfo', newProps.siteInfo);
      this.resetState();
    }
    else if (oldProps.options !== newProps.options) {
      logger.d(LOG_TAG, 'componentDidUpdate', 'options', oldProps.options.length, newProps.options.length);
      this.resetState();
    }
    if (oldProps.defaultGroup !== newProps.defaultGroup) {
      logger.d(LOG_TAG, 'componentDidUpdate', 'defaultGroup', oldProps.defaultGroup, newProps.defaultGroup);
      this.resetState();
    }
  }
  resetState() {
    logger.d(LOG_TAG, 'resetState', this.props.defaultGroup);
    const authUserInfo = localStorageHelper.getAuthUserInfo();
    const authUserGroups = localStorageHelper.getAuthUserGroups();
    const siteInfo = this.props.siteInfo;
    const groups = [];
    authUserGroups.sort((a, b) => a.name.localeCompare(b.name));
    authUserGroups.forEach((info) => {
      if (info) {
        groups.push({
          text: info.name,
          value: info.group_id,
          object: info,
        });
      }
    });
    groups.push({
      text: 'Create new',
      value: 'create',
      object: {},
    });
    const newState = {
      siteInfo: siteInfo,
      authUserInfo: authUserInfo,
      groupOptions: groups,
      groupNewName: '',

      // initial rate
      initialRateEnabled: false,
      initialDurationSelected: 'select',
      initialDurationWarning: false,
      initialRate: DEFAULT_INITIAL_RATE,

      // fixed rate
      fixedRatePlan: 'select',
      fixedRateDaysOfWeek: [],

      fixedRateStartSelected: 'select',
      fixedRateEndSelected: 'select',
      fixedRateOfferEndSelected: 'select',
      fixedRatePrice: DEFAULT_MIN_RATE,
      fixedRateWindows: [],

      // isInternalTestOnly: accessHelper.isInternalTester(authUserInfo)
    };

    if (siteInfo) {
      const groupIndex = authUserGroups.findIndex((group) => siteInfo.group_id === group.group_id);
      newState.groupOptionSelected = groupIndex === -1 ? 'select' : groupIndex;
      newState.name = siteInfo.name;
      const minDuration = FIND_DURATION_OPTION_INDEX(siteInfo.min_duration, MIN_DURATION_OPTIONS);
      const maxDuration = FIND_DURATION_OPTION_INDEX(siteInfo.max_duration, MAX_DURATION_OPTIONS);
      const towGracePeriod = FIND_DURATION_OPTION_INDEX(siteInfo.grace_period, TOW_GRACE_PERIOD_OPTIONS);
      newState.minDurationSelected = minDuration;
      newState.maxDurationSelected = maxDuration;
      newState.towGracePeriodSelected = towGracePeriod;
      newState.rate = siteInfo.base_price;

      newState.address = siteInfo.address;
      newState.description = siteInfo.description;
      newState.lat = siteInfo.gps_location ? siteInfo.gps_location.lat : '';
      newState.lng = siteInfo.gps_location ? siteInfo.gps_location.lon : '';
      newState.logoUrl = siteInfo.logo_url;
      newState.marker = {
        icon: imgLocationOn,
        position: {
          lat: parseFloat(newState.lat),
          lng: parseFloat(newState.lng),
        }
      };

      if (siteInfo.initial_duration > 0) {
        newState.initialDurationSelected = INITIAL_DURATION_OPTIONS.findIndex((option) => option.value === siteInfo.initial_duration);
        newState.initialRate = siteInfo.initial_rate;
        newState.initialRateEnabled = true;
      }
      /* siteInfo.fixed_rates = [
        {
          day: 'Monday',
          windows: [
            {
              code: 'earlybird',
              start: '00:00',
              end: '03:00',
              offer_end: '01:00',
              description: 'Early Bird',
              price: 50
            },
            {
              code: 'day',
              start: '03:00',
              end: '06:00',
              offer_end: '05:00',
              description: 'Day Parking',
              price: 50
            }
          ]
        }
      ] */
      if (siteInfo.fixed_rates) {
        siteInfo.fixed_rates.forEach((rate) => {
          rate.windows.forEach((window) => {
            newState.fixedRateWindows.push({
              sort_id: dataHelper.getDayOfWeekSid(rate.day),
              code: window.code,
              day: rate.day,
              description: window.description,
              start: window.start,
              end: window.end,
              offer_end: window.offer_end,
              price: window.price,
            });
          });
        });
      }
    } else {
      newState.groupOptionSelected = this.props.defaultGroup !== 'select' ? this.props.defaultGroup : 'select';
      newState.name = '';
      newState.minDurationSelected = 'select';
      newState.maxDurationSelected = 'select';
      newState.towGracePeriodSelected = 'select';
      newState.rate = DEFAULT_MIN_RATE;

      newState.lat = '';
      newState.lng = '';
      newState.address = '';
      newState.description = '';
      newState.logoUrl = '';
      newState.marker = null;
    }
    logger.d(LOG_TAG, 'resetState', newState);
    this.setState(newState);
  }
  render() {
    return (
      <Modal
        show={this.props.show}
        backdrop={'static'}
        onHide={this.onClickHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {this.state.siteInfo ? 'Update Site' : 'Create Site'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* organization */}
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="">Organization</label>
                <label className="marker-mandatory"> *</label>
                <select
                  disabled={this.state.siteInfo ? true : false}
                  className="form-control"
                  value={this.state.groupOptionSelected}
                  onChange={this.onSelectOrganization}>
                  <option value="select">Select organization</option>
                  {
                    this.state.groupOptions.map((option, index) => (
                      <option
                        key={index}
                        value={index}>{option.text}</option>
                    ))
                  }
                </select>
                {
                  this.state.groupOptionSelected !== 'select' && this.state.groupOptions[this.state.groupOptionSelected].value === 'create' ?
                    <input
                      type="text"
                      className="form-control mt-2"
                      placeholder="Enter organization name"
                      value={this.state.groupNewName}
                      onChange={this.onChangeOrganization}
                    />
                    : null
                }
              </div>
            </div>
            {/* name */}
            <div className="col-md-8">
              <div className="form-group">
                <label htmlFor="">Name</label>
                <label className="marker-mandatory"> *</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter site name"
                  value={this.state.name}
                  onChange={this.onChangeName}
                />
              </div>
            </div>
          </div>
          {/* pricing and time config */}
          <h5>Standard Hourly Rate:</h5>
            <div className="row">
              <div className="col-md-4">
                {/* default hourly rates */}
                <div className="form-group">
                  <label htmlFor="">Minimum duration(min)</label>
                  <label className="marker-mandatory"> *</label>
                  <select
                    className="form-control"
                    value={this.state.minDurationSelected}
                    onChange={this.onSelectMinDuration}>
                    <option value="select">Select</option>
                    {
                      MIN_DURATION_OPTIONS.map((option, index) => (
                        <option
                          key={index}
                          value={index}>{option.text}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
              <div className="col-md-8">
                <div className="form-group">
                  <label htmlFor="">Maximum duration(min)</label>
                  <label className="marker-mandatory"> *</label>
                  <select
                    className="form-control"
                    value={this.state.maxDurationSelected}
                    onChange={this.onSelectMaxDuration}>
                    <option value="select">Select</option>
                    {
                      MAX_DURATION_OPTIONS.map((option, index) => (
                        <option
                          key={index}
                          value={index}>{option.text}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="">Tow grace period(min)</label>
                  <label className="marker-mandatory"> *</label>
                  <select
                    className="form-control"
                    value={this.state.towGracePeriodSelected}
                    onChange={this.onSelectTowGracePeriod}>
                    <option value="select">Select</option>
                    {
                      TOW_GRACE_PERIOD_OPTIONS.map((option, index) => (
                        <option
                          key={index}
                          value={index}>{option.text}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
              <div className="col-md-8">
                <div className="form-group">
                  <RangeSlider
                    title="Rates / minimum duration"
                    domain={[DEFAULT_MIN_RATE, 500]}
                    domainLabelLeft={`$0.${DEFAULT_MIN_RATE}`}
                    domainLabelRight="$5.00"
                    step={50}
                    values={[this.state.rate]}
                    onUpdate={this.onChangeChargeRate}
                    unitPrefix="$"
                    divisor="100">
                  </RangeSlider>
                </div>
              </div>
            </div>
          {/* initial rates pricing config */}
          {
            (this.state.isInternalTestOnly) &&
            <div>
              <div className="row" style={{marginLeft: '0rem'}}>
                <h5>Initial Rate:</h5>
                {<label>
                  <input
                    type="checkbox"
                    style={{marginLeft: '0.33rem', marginTop: '0.5rem'}}
                    checked={this.state.initialRateEnabled}
                    onChange={this.onEnableInitialRate}/> Enable initial promo rate
                </label>}
              </div>
              {
                (this.state.initialRateEnabled) && (
                  <div className="row">
                    <div className="col-md-4">
                      {/* default hourly rates */}
                      <div className="form-group">
                        <label htmlFor="">Initial duration(min)</label>
                        <select
                          className="form-control"
                          value={this.state.initialDurationSelected}
                          onChange={this.onSelectInitialDuration}>
                          <option value="select">Select</option>
                          {
                            INITIAL_DURATION_OPTIONS.map((option, index) => (
                              <option
                                key={index}
                                value={index}>{option.text}</option>
                            ))
                          }
                        </select>
                        {
                          (this.state.initialDurationWarning) &&
                          <label className="clr-warning">* Duration must be equal or larger than min duration</label>
                        }
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="form-group">
                        <RangeSlider
                          title="Rates"
                          domain={[DEFAULT_INITIAL_RATE, 500]}
                          domainLabelLeft={`$0.${DEFAULT_INITIAL_RATE}`}
                          domainLabelRight="5.00"
                          step={this.state.initialDurationSelected !== 'select' ? 50 : 0}
                          values={[this.state.initialRate]}
                          onUpdate={this.onChangeInitialChargeRate}
                          unitPrefix="$"
                          divisor="100"
                          disabled={this.state.initialDurationSelected === 'select'}>
                        </RangeSlider>
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
          }

          {/* fixed rates and config */}
          {
            (this.state.isInternalTestOnly) && <div>
              <h5>Fixed Rate:</h5>
              {
                (
                  <div>
                    <div className="row">
                      <div className="col-md-12">
                        <label className="form-check-label" htmlFor="">Rate Plan</label>
                        <select
                          className="form-control"
                          value={this.state.fixedRatePlan}
                          onChange={this.onSelectFixedRatePlan}>
                          <option value="select">Select</option>
                          {
                            dataHelper.getFixedRatePlans().map((plan, index) => (
                              <option
                                key={index}
                                value={index}>{plan.text}</option>
                            ))
                          }
                        </select>
                      </div>
                    </div>
                    {/* day of week*/}
                    <div className="row">
                      <div className="col-md-12">
                        <label className="form-check-label" htmlFor="">Day of week</label>
                        <div>
                          {
                            dataHelper.getWeekDays().map((day, index) => (
                              <label key={day.sortId}>
                                <input
                                  id={day.sortId}
                                  value={day.sortId}
                                  name="days_of_week"
                                  type="checkbox"
                                  style={{marginLeft: '0.33rem', marginTop: '0.5rem'}}
                                  checked={this.state.fixedRateDaysOfWeek.indexOf(day.sortId) > -1}
                                  onChange={this.onCheckFixedOfferDay}/> {day.short}
                              </label>
                            ))
                          }
                        </div>
                        <div>
                          {
                            dataHelper.getWeekend().map((day, index) => (
                              <label key={day.sortId}>
                                <input
                                  id={day.sortId}
                                  value={day.sortId}
                                  name="days_of_week"
                                  type="checkbox"
                                  style={{marginLeft: '0.33rem', marginTop: '0.5rem'}}
                                  checked={this.state.fixedRateDaysOfWeek.indexOf( day.sortId) > -1}
                                  onChange={this.onCheckFixedOfferDay}/> {day.short}
                              </label>
                            ))
                          }
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <label className="form-check-label" htmlFor="">Park Start</label>
                        <select
                          className="form-control"
                          value={this.state.fixedRateStartSelected}
                          onChange={this.onSelectWindowStart}>
                          <option value="select">Select</option>
                          {
                            dataHelper.getWindowOptions().map((option, index) => (
                              <option
                                key={index}
                                value={index}>{option.text}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div className="col-md-4">
                        <label className="form-check-label" htmlFor="">Offer End</label>
                        <select
                          className="form-control"
                          value={this.state.fixedRateEndSelected}
                          onChange={this.onSelectWindowEnd}>
                          <option value="select">Select</option>
                          {
                            dataHelper.getWindowOptions().map((option, index) => (
                              <option
                                key={index}
                                value={index}>{option.text}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div className="col-md-4">
                        <label className="form-check-label" htmlFor="">Park End</label>
                        <select
                          className="form-control"
                          value={this.state.fixedRateOfferEndSelected}
                          onChange={this.onSelectWindowOfferEnd}>
                          <option value="select">Select</option>
                          {
                            dataHelper.getWindowOptions().map((option, index) => (
                              <option
                                key={index}
                                value={index}>{option.text}</option>
                            ))
                          }
                        </select>
                      </div>
                    </div>
                    <div className="row" style={{marginTop: '1rem'}}>
                      <div className="col-md-12">
                        <div className="form-group">
                          <RangeSlider
                            title="Rate (fixed)"
                            domain={[DEFAULT_MIN_RATE, 2400]}
                            domainLabelLeft={`$0.${DEFAULT_MIN_RATE}`}
                            domainLabelRight="$24.00"
                            step={50}
                            values={[this.state.fixedRatePrice]}
                            onUpdate={this.onChangeFixedRatePrice}
                            unitPrefix="$"
                            divisor="100">
                          </RangeSlider>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-10">
                        <div className="form-group">
                          {/* time slots*/}
                          {
                            this.state.fixedRateWindows.map((windowInfo, index) => (
                              <div key={index} className="app-item-underline-border pt-1 pb-1">
                                <div className="form-check">
                                  <label className="form-check-label" style={{minWidth: '6rem'}} htmlFor={`slot+${index}`}>{windowInfo.day}: </label>
                                  <label className="form-check-label" style={{minWidth: '8rem'}}htmlFor={`slot+${index}`}>{windowInfo.description}</label>
                                  <label className="form-check-label" htmlFor={`slot+${index}`}>${(windowInfo.price / 100).toFixed(2)}</label>
                                  <div className="float-right mr-1 pb-2" onClick={this.onClickRemoveFixedRateSlot.bind(this, index)}><FontAwesomeIcon icon="trash-alt" /></div>
                                  {
                                    <label className="form-check-label float-right pt-2 mr-3" htmlFor={`slot+${index}`}>{windowInfo.start} ({windowInfo.end}) ~ {windowInfo.offer_end} </label>
                                  }
                                </div>
                              </div>
                            ))
                          }
                        </div>
                      </div>
                      <div className="col-md-2">
                        <button className="btn-invert btn-default-small ml-2 pt-1" onClick={this.onClickAddFixedRateSlot}>
                          <FontAwesomeIcon icon="plus"></FontAwesomeIcon> Add
                        </button>
                      </div>
                    </div>
                  </div>

                )
              }
            </div>
          }

          {/* address with search*/}
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="">Address</label>
                <label className="marker-mandatory"> *</label>
                <AddressAutocomplete
                  value={this.state.address}
                  onPlaceChanged={this.onPlaceChanged}>
                </AddressAutocomplete>
              </div>
            </div>
          </div>
          {/* MAP */}
          <div className="row">
            <div className="col-md-8">
              <div className="form-group">
                <div style={{ height: '16rem', paddingLeft: '0.66rem', paddingRight: '0.66rem'}}>
                  <GoogleMap
                    marker={this.state.marker}
                    onClickMap={this.onClickMap}>
                  </GoogleMap>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              {/* lat/lng */}
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="">Latitude</label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.lat}
                      onChange={this.onChangeLatitude}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="">Longitude</label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.lng}
                      onChange={this.onChangeLongitude}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* description */}
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="">Description (Optional)</label>
                <textarea
                  name=""
                  id=""
                  className="form-control"
                  rows="3"
                  value={this.state.description}
                  onChange={this.onChangeDescription}
                />
              </div>
            </div>
          </div>
          {/* image url */}
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="">Site Logo Url (Optional)</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter logo url"
                  value={this.state.logoUrl}
                  onChange={this.onChangeLogoUrl}
                />
              </div>
            </div>
          </div>
          {/* image url preview */}
          <div className="row">
            {
              this.state.logoUrl && (
                <div className="ml-4">
                  <img
                    className="app-img__site--logo"
                    src={this.state.logoUrl}
                    alt="logo"
                  />
                </div>
              )
            }
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div>
            {!this.validate() && <div
              className="clr-warning"
              style={{
                marginBottom: '0.33rem',
                fontSize: '0.66em',
                fontWeight: 'bold'}}>Missing mandatory fields *</div>}

            <Button className={`
                  ${this.validate()?'btn':'btn__disabled'}
                  btn-default`}
                       onClick={this.onClickAdd}>{this.state.siteInfo ? 'Update' : 'Add'}
            </Button>
          </div>

        </Modal.Footer>
      </Modal>
    );
  }
  onSelectMinDuration = e => {
    const selected = e.target.value;
    // logger.d(LOG_TAG, 'onSelectMinDuration', selected);
    const newState = {
      minDurationSelected: selected,
      initialDurationWarning: false,
    }

    if (selected !== 'select') {
      // logger.d(LOG_TAG, 'onSelectMinDuration', MIN_DURATION_OPTIONS[selected].value);
      if (this.state.initialDurationSelected !== 'select') {
        if (INITIAL_DURATION_OPTIONS[this.state.initialDurationSelected].value < MIN_DURATION_OPTIONS[selected].value) {
          newState.initialDurationSelected = selected;
        }
      }
    }
    this.setState(newState);
  };
  onSelectMaxDuration = e => {
    const selected = e.target.value;
    // logger.d(LOG_TAG, 'onSelectMaxDuration', selected);
    if (selected !== 'select') {
      logger.d(LOG_TAG, 'onSelectMinDuration', MAX_DURATION_OPTIONS[selected].value);
    }
    this.setState({
      maxDurationSelected: selected,
    });
  };
  onSelectTowGracePeriod = e => {
    const selected = e.target.value;
    // logger.d(LOG_TAG, 'onSelectTowGracePeriod', selected);
    if (selected !== 'select') {
      logger.d(LOG_TAG, 'onSelectTowGracePeriod', TOW_GRACE_PERIOD_OPTIONS[selected].value);
    }
    this.setState({
      towGracePeriodSelected: selected,
    });
  };
  onSelectOrganization = e => {
    const selected = e.target.value;
    // logger.d(LOG_TAG, 'onSelectOrganization', selected);
    if (selected !== 'select') {
      logger.d(LOG_TAG, 'onSelectOrganization', this.state.groupOptions[selected]);
    }
    this.setState({
      groupOptionSelected: selected,
      groupNewName: '',
    });
  };
  onChangeOrganization = e => {
    const value = e.target.value;
    // logger.d(LOG_TAG, 'onChangeOrganization', value);
    this.setState({
      groupNewName: value,
    })
  };
  onChangeName = e => {
    const value = e.target.value;
    // logger.d(LOG_TAG, 'onChangeName', value);
    this.setState({
      name: value,
    })
  };
  onChangeChargeRate = value => {
    logger.d(LOG_TAG, 'onChangeChargeRate', value[0]);
    this.setState({
      rate: value[0],
    })
  };
  onEnableInitialRate = e => {
    logger.d(LOG_TAG, 'onChangeChargeRate', e);
    const newState = {
      initialRateEnabled: !this.state.initialRateEnabled
    }
    if (!newState.initialRateEnabled) {
      newState.initialDurationSelected = 'select';
      newState.initialRate = DEFAULT_INITIAL_RATE;
    }
    this.setState(newState);
  };
  onSelectInitialDuration = e => {
    const selected = e.target.value;
    // logger.d(LOG_TAG, 'onSelectInitialDuration', selected);
    const newState = {
      initialDurationSelected: selected,
      initialDurationWarning: false,
    }
    if (selected !== 'select') {
      // initial duration must be >= min parking duration
      if (this.state.minDurationSelected !== 'select') {
        if (INITIAL_DURATION_OPTIONS[selected].value < MIN_DURATION_OPTIONS[this.state.minDurationSelected].value) {
          newState.initialDurationSelected = this.state.minDurationSelected;
          newState.initialDurationWarning = true;
        }
      }
    } else {
      newState.initialRateEnabled = false;
    }
    this.setState(newState);
  };
  onChangeInitialChargeRate = value => {
    if (this.state.initialDurationSelected !== 'select') {
      logger.d(LOG_TAG, 'onChangeInitialChargeRate', value[0]);
      this.setState({
        initialRate: value[0],
      })
    }
  };
  onSelectFixedRatePlan = e => {
    const value = e.target.value;
    logger.d(LOG_TAG, 'onSelectFixedRatePlan', value);
    this.setState({
      fixedRatePlan: value
    });
  };
  onCheckFixedOfferDay = e => {
    const sortId = parseInt(e.target.value);
    const daysOfWeek = this.state.fixedRateDaysOfWeek;
    const index = daysOfWeek.indexOf(sortId);
    if (index > -1) {
      daysOfWeek.splice(index, 1);
    } else {
      daysOfWeek.push(sortId);
    }
    logger.d(LOG_TAG, 'onCheckFixedOfferDay', sortId, index > -1, daysOfWeek);
    this.setState({
      fixedRateDaysOfWeek: daysOfWeek
    })
  };
  onSelectWindowStart = e => {
    const value = e.target.value;
    logger.d(LOG_TAG, 'onSelectWindowStart', value);
    this.setState({
      fixedRateStartSelected: value,
    });
  };
  onSelectWindowEnd = e => {
    const value = e.target.value;
    logger.d(LOG_TAG, 'onSelectWindowEnd', value);
    this.setState({
      fixedRateEndSelected: value
    });
  };
  onSelectWindowOfferEnd = e => {
    const value = e.target.value;
    logger.d(LOG_TAG, 'onSelectWindowOfferEnd', value);
    this.setState({
      fixedRateOfferEndSelected: value
    });
  };
  onChangeFixedRatePrice = value => {
    this.setState({
      fixedRatePrice: value[0],
    })
  };
  onClickAddFixedRateSlot = e => {
    if (this.state.fixedRatePlan !== 'select' &&
      this.state.fixedRateDaysOfWeek.length > 0 &&
      this.state.fixedRateStartSelected !== 'select' &&
      this.state.fixedRateEndSelected !== 'select' &&
      this.state.fixedRateOfferEndSelected !== 'select'
    ) {
      logger.d(LOG_TAG, 'onClickAddFixedRateSlot');
      const windows = this.state.fixedRateWindows;
      this.state.fixedRateDaysOfWeek.forEach((dayOfWeekId) => {
        windows.push({
          sort_id: dayOfWeekId,
          code: dataHelper.getFixedRatePlans()[this.state.fixedRatePlan].value,
          description: dataHelper.getFixedRatePlans()[this.state.fixedRatePlan].text,
          day: dataHelper.getDaysOfWeek().filter((day) => day.sortId === dayOfWeekId)[0].text,
          start: dataHelper.getWindowOptions()[this.state.fixedRateStartSelected].value,
          end: dataHelper.getWindowOptions()[this.state.fixedRateEndSelected].value,
          offer_end: dataHelper.getWindowOptions()[this.state.fixedRateOfferEndSelected].value,
          price: this.state.fixedRatePrice,
        });
      });

      windows.sort((a, b) => (`${a.sort_id}${a.start}` > `${b.sort_id}${b.start}`) ? 1 : -1);
      this.setState({
        fixedRateWindows: windows,
      });
    }
  }
  onClickRemoveFixedRateSlot = e => {
    logger.d(LOG_TAG, 'onClickRemoveFixedRateSlot', e);
    const windows = this.state.fixedRateWindows;
    windows.splice(e, 1);
    this.setState({
      fixedRateWindows: windows,
    });
  }
  onChangeLatitude = e => {
    const value = e.target.value;
    // logger.d(LOG_TAG, 'onChangeLatitude', value);
    this.setState({
      lat: value,
    })
    this.onUpdateMarker(value, this.state.lng);
  };
  onChangeLongitude = e => {
    const value = e.target.value;
    // logger.d(LOG_TAG, 'onChangeLongitude', value);
    this.setState({
      lng: value,
    });
    this.onUpdateMarker(this.state.lat, value);
  };
  onUpdateMarker(lat, lng) {
    if (lat && lng) {
      this.setState({
        marker: {
          icon: imgLocationOn,
          position: {
            lat: parseFloat(lat),
            lng: parseFloat(lng),
          },
        },
      })
    }
  }
  onPlaceChanged = value => {
    logger.d(LOG_TAG, 'onPlaceChanged', value);
    this.setState({
      address: value.address,
      lat: value.position.lat,
      lng: value.position.lng,
      marker: {
        icon: imgLocationOn,
        position: {
          lat: value.position.lat,
          lng: value.position.lng,
        },
        animate: true,
      },
    })
  };
  onClickMap = value => {
    // logger.d(LOG_TAG, 'onClickMap', value);
    this.setState({
      lat: value.lat,
      lng: value.lng,
      marker: {
        icon: imgLocationOn,
        position: {
          lat: parseFloat(value.lat),
          lng: parseFloat(value.lng),
        },
        animate: true,
      },
    });
  };
  onChangeAddress = e => {
    const value = e.target.value;
    // logger.d(LOG_TAG, 'onChangeAddress', value);
    this.setState({
      address: value,
    })
  };
  onChangeDescription = e => {
    const value = e.target.value;
    // logger.d(LOG_TAG, 'onChangeDescription', value);
    this.setState({
      description: value,
    })
  };
  onChangeLogoUrl = e => {
    const value = e.target.value;
    logger.d(LOG_TAG, 'onChangeLogoUrl', value);
    this.setState({
      logoUrl: value,
    })
  };
  onClickHide = e => {
    logger.d(LOG_TAG, 'onClickHide');
    if (this.props.onHide) {
      this.resetState();
      this.props.onHide();
    }
  };
  validate() {
    let validated = false;
    if (
      this.state.name &&
      this.state.minDurationSelected !== 'select' &&
      this.state.maxDurationSelected !== 'select' &&
      this.state.towGracePeriodSelected !== 'select' &&
      this.state.lat &&
      this.state.lng) {
      if (this.state.groupOptionSelected !== 'select') {
        if (this.state.groupOptions[this.state.groupOptionSelected].value === 'create') {
          if (this.state.groupNewName) {
            validated = true;
          }
        } else {
          validated = true;
        }
      }
    }
    // logger.d(LOG_TAG, 'validate', validated, this.state);
    return validated;
  };
  onClickAdd = e => {
    const isValidated = this.validate();
    logger.d(LOG_TAG, 'onClickAdd', isValidated);
    if (isValidated) {

      this.props.setLoading(StateInfo.LoadingInfo.format(
        true,
        this.state.siteInfo ? 'Updating park site...' : 'Adding new park site...'));

      const group = this.state.groupOptions[this.state.groupOptionSelected];
      if (group.value === 'create') {
        const groupInfo = {
          name: this.state.groupNewName,
        };
        window.webapi.Admin.postGroup(
          groupInfo,
          (response) => {
            this.onGroupSuccess(response.data);
          },
          (error) => {
            this.onGroupError(error.data);
          }
        )
      } else {
        this.createNewSite(group.value);
      }
    }
  };
  onGroupSuccess(response) {
    logger.d(LOG_TAG, 'onGroupSuccess', response);
    setTimeout(() => {
      this.createNewSite(response.group_id);
    }, 1200);

  };
  onGroupError(error) {
    logger.d(LOG_TAG, 'onGroupError', error);
    const notify = dataHelper.formatError('Group', error);
    this.props.setLoading(StateInfo.LoadingInfo.format(false, ''));
    this.props.setNotification(
      StateInfo.NotificationInfo.format('danger', notify.title, notify.message)
    );
  };
  createNewSite(groupId) {
    logger.d(LOG_TAG, 'createNewSite', groupId, this.state.siteInfo);

    if (this.state.siteInfo) {
      const siteInfo = JSON.parse(JSON.stringify(this.state.siteInfo));
      siteInfo.group_id = groupId;
      siteInfo.name = this.state.name;
      siteInfo.min_duration = MIN_DURATION_OPTIONS[this.state.minDurationSelected].value;
      siteInfo.max_duration = MAX_DURATION_OPTIONS[this.state.maxDurationSelected].value;
      siteInfo.grace_period = TOW_GRACE_PERIOD_OPTIONS[this.state.towGracePeriodSelected].value;
      siteInfo.base_price = this.state.rate;
      siteInfo.gps_location.lat = this.state.lat.toString();
      siteInfo.gps_location.lon = this.state.lng.toString();
      siteInfo.address = this.state.address;
      siteInfo.description = this.state.description;
      siteInfo.logo_url = this.state.logoUrl;
      if (this.state.initialDurationSelected !== 'select') {
        siteInfo.initial_duration = INITIAL_DURATION_OPTIONS[this.state.initialDurationSelected].value;
        siteInfo.initial_rate = this.state.initialRate;
      } else {
        siteInfo.initial_duration = 0;
        siteInfo.initial_rate = 0;
      }
      // always reset && replace
      siteInfo.fixed_rates = [];
      if (this.state.fixedRateWindows && this.state.fixedRateWindows.length > 0) {
        this.state.fixedRateWindows.forEach((window) => {
          let rateInfo = siteInfo.fixed_rates.find((rateInfo) => rateInfo.day === window.day);
          if (!rateInfo) {
            rateInfo = StateInfo.SlotInfo().setDay(window.day);
            siteInfo.fixed_rates.push(rateInfo);
          }
          rateInfo.addWindow(StateInfo.WindowInfo().createRateWindow(window));
        });
      }
      logger.d(LOG_TAG, 'createNewSite', 'putParkSiteInfo', siteInfo);
      window.webapi.Group.putParkSiteInfo(
        groupId,
        this.state.siteInfo.site_id,
        siteInfo,
        (response) => {
          this.onSiteResponse(response.data);
        },
        (error) => {
          this.onSiteError(error.data);
        }
      );
    } else {
      const siteInfo = StateInfo.ParkSiteInfo
      .create(groupId, this.state.name)
      .setPricing(
        MIN_DURATION_OPTIONS[this.state.minDurationSelected].value,
        MAX_DURATION_OPTIONS[this.state.maxDurationSelected].value,
        this.state.rate)
      .setTowGracePeriod(TOW_GRACE_PERIOD_OPTIONS[this.state.towGracePeriodSelected].value)
      .setLocation(this.state.lat.toString(), this.state.lng.toString(), this.state.address)
      .setDescription(this.state.description)
      .setLogoUrl(this.state.logoUrl);
      if (this.state.initialDurationSelected !== 'select') {
        siteInfo.setInitialPromo(
          INITIAL_DURATION_OPTIONS[this.state.initialDurationSelected].value,
          this.state.initialRate
        );
      }
      if (this.state.fixedRateWindows && this.state.fixedRateWindows.length > 0) {
        this.state.fixedRateWindows.forEach((window) => {
          siteInfo.addFixedRates(window);
        });
      }
      logger.d(LOG_TAG, 'createNewSite', 'postGroupSite', siteInfo);
      window.webapi.Admin.postGroupSite(
        groupId,
        siteInfo,
        (response) => {
          this.onSiteResponse(response.data);
        },
        (error) => {
          this.onSiteError(error.data);
        }
      );
    }
  };
  onSiteResponse(response) {
    logger.d(LOG_TAG, 'onSiteResponse');
    setTimeout(() => {
      this.props.setLoading(StateInfo.LoadingInfo.format(false, ''));
      this.resetState();
      this.props.onHide(true);
    }, 1200);
  };
  onSiteError(error) {
    logger.d(LOG_TAG, 'onSiteError');
    const notify = dataHelper.formatError('Site', error);
    this.props.setLoading(StateInfo.LoadingInfo.format(false, ''));
    this.props.setNotification(
      StateInfo.NotificationInfo.format('danger', notify.title, notify.message)
    );
  };
}
export default connect(
  null,
  {
    setLoading,
    setNotification,
  }
)(DlgAddParkSite);

