import {combineReducers} from 'redux';

import TabInfo from '../states/tabInfo';
import LocationInfo from '../states/locationInfo'
import AuthInfo from '../states/authInfo';
import AuthUserInfo from '../states/authUserInfo';
import UserInfo from '../states/userInfo';
import LastSessionInfo from '../states/lastSessionInfo';
import LoadingInfo from '../states/loadingInfo';
import NotificationInfo from '../states/notificationInfo';
import OnNotifyStartParking from '../states/onNotifyStartParking';

import {ACTIONS} from '../actions';

function tabInfo(state = TabInfo, action) {
  switch (action.type) {
    case ACTIONS.SET_TAB:
      return action.tabInfo;
    default:
      return state;
  }
}
function locationInfo(state = LocationInfo, action) {
  switch (action.type) {
    case ACTIONS.SET_LOCATION:
      return action.locationInfo;
    default:
      return state;
  }
}
function authInfo(state = AuthInfo, action) {
  switch (action.type) {
    case ACTIONS.SET_AUTH:
      return action.authInfo;
    default:
      return state;
  }
}
function authUserInfo(state = AuthUserInfo, action) {
  switch (action.type) {
    case ACTIONS.SET_AUTH_USER:
      return action.userInfo;
    default:
      return state;
  }
}
function userInfo(state = UserInfo, action) {
  switch (action.type) {
    case ACTIONS.SET_USER:
      return action.userInfo;
    default:
      return state;
  }
}
function lastSessionInfo(state = LastSessionInfo, action) {
  switch (action.type) {
    case ACTIONS.SET_LAST_SESSION:
      return action.lastSessionInfo;
    default:
      return state;
  }
}
function loadingInfo(state = LoadingInfo, action) {
  switch (action.type) {
    case ACTIONS.SET_LOADING:
      return action.loadingInfo;
    default:
      return state;
  }
}
function notificationInfo(state = NotificationInfo, action) {
  switch (action.type) {
    case ACTIONS.SET_NOTIFICATION:
      return action.notificationInfo;
    default:
      return state;
  }
}
function notifyStartParking(state = OnNotifyStartParking, action) {
  switch (action.type) {
    case ACTIONS.SET_NOTIFY_START_PARKING:
      return action.notifyStartParking;
    default:
      return state;
  }
}
const reducers = combineReducers({
  tabInfo,
  locationInfo,
  authInfo,
  authUserInfo,
  userInfo,
  lastSessionInfo,
  loadingInfo,
  notificationInfo,
  notifyStartParking
});

export default reducers;
