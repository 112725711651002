import Auth from './auth';
import Admin from './admin';
import User from './user';
import Group from './group';
import Query from './query';
/* APIs go through binder first for redirect
 * */

export default {
  Auth,
  User,
  Group,
  Admin,
  Query,
};
