import React, {Component} from 'react';
import AnimPage from '../../../components/anim/animPage';
import connect from 'react-redux/es/connect/connect';
import {setAuth, setAuthUser, setLoading, setNotification} from '../../../store/actions';
import logger from '../../../utils/logger';
import localStorageHelper from '../../../utils/localStorageHelper';
import StateInfo from "../../../store/states";

const LOG_TAG = 'Account';

class Account extends Component {
  constructor(props) {
    super(props);
    // init and sync
    this.state = {
      authUserInfo: null,
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      groupOptions: [],
      password: '',
      isChanged: false,
    };
  }
  componentDidMount() {
    logger.d(LOG_TAG, 'componentDidMount');
    this.refreshAuthUser();
  }
  refreshAuthUser() {
    const authUserInfo = localStorageHelper.getAuthUserInfo();
    const authUserGroups = localStorageHelper.getAuthUserGroups();
    logger.d(LOG_TAG, 'refreshAuthUser', authUserInfo);
    const groups = [];
    authUserGroups.forEach((info) => {
      if (info) {
        groups.push(info);
      }
    });
    this.setState({
      authUserInfo: authUserInfo,
      firstName: authUserInfo.first_name,
      lastName: authUserInfo.last_name,
      email: authUserInfo.email,
      phone: authUserInfo.phone,
      groupOptions: groups
    });
  }
  componentWillUnmount() {
  }
  render() {
    return (
      <AnimPage>
        <div className="container-fluid p-0 mt-3">
          <div className="row app-main__content">
            {/* left */}
            <div className="col-md-6 app-main__content__card">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="">First Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter first name"
                          value={this.state.firstName}
                          onChange={this.onChangeFirstName}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="">Last Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter last name"
                          value={this.state.lastName}
                          onChange={this.onChangeLastName}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="">Email Address</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter email address"
                          value={this.state.email}
                          onChange={this.onChangeEmail}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="">Mobile Number</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter mobile number"
                          value={this.state.phone}
                          onChange={this.onChangePhone}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="">Organization</label>
                        <select className="form-control">
                          <option value="select">Organization list</option>
                          {
                            this.state.groupOptions.map((option, index) => (
                              <option
                                disabled
                                key={index}
                                value={index}>{option.name}</option>
                            ))
                          }
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="">Password</label>
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Enter Password"
                          onChange={this.onChangePassword}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <button
                          className={`
                        ${this.validate() ?'btn':'btn__disabled'}
                        btn-default float-right`}
                          onClick={this.onClickUpdate}>
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AnimPage>
    );
  }
  onChangeFirstName = e => {
    const value = e.target.value;
    logger.d(LOG_TAG, 'onChangeFirstName', value);
    this.setState({
      firstName: value,
      isChanged: true,
    });
  };
  onChangeLastName = e => {
    const value = e.target.value;
    logger.d(LOG_TAG, 'onChangeLastName', value);
    this.setState({
      lastName: value,
      isChanged: true,
    });
  };
  onChangeEmail = e => {
    const value = e.target.value;
    logger.d(LOG_TAG, 'onChangeEmail', value);
    this.setState({
      email: value,
      isChanged: true,
    });
  };
  onChangePhone = e => {
    const value = e.target.value;
    logger.d(LOG_TAG, 'onChangePhone', value);
    this.setState({
      phone: value,
      isChanged: true,
    });
  };
  onChangePassword = e => {
    const value = e.target.value;
    logger.d(LOG_TAG, 'onChangePassword', value)
    this.setState({
      password: value,
      isChanged: true,
    });
  };
  validate() {
    let validated = false;
    if (this.state.firstName &&
      this.state.lastName &&
      this.state.email &&
      this.state.phone &&
      this.state.isChanged) {
      validated = true;
    }
    logger.d(LOG_TAG, 'validate', validated, this.state);
    return validated;
  };
  onClickUpdate = e => {
    const isValidated = this.validate();
    logger.d(LOG_TAG, 'onClickUpdate', isValidated);
    if (isValidated) {
      const userInfo = {
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        email: this.state.email,
        phone: this.state.phone,
      };
      if (this.state.password) {
        userInfo.password = this.state.password;
        window.confirm(`Are you sure you want to update password?`) &&
        this.onUpdateAuthUser(userInfo);
      } else {
        this.onUpdateAuthUser(userInfo);
      }
    }
  };
  onUpdateAuthUser(userInfo) {
    this.props.setLoading(StateInfo.LoadingInfo.format(true, 'Updating user...'));
    window.webapi.User.put(
      this.state.authUserInfo.user_id,
      userInfo,
      (response) => {
        this.onUserResponse(response.data);
      },
      (error) => {
        this.onUserError(error.data);
      }
    );
  };
  onUserResponse(value) {
    setTimeout(() => {
      this.props.setAuthUser(value);
      this.props.setLoading(StateInfo.LoadingInfo.format(false, ''));
      this.props.setNotification(
        StateInfo.NotificationInfo.format('success', null, 'Update successfully')
      );
      this.setState({
        isChanged: false,
      });
    }, 800);
  };
  onUserError(value) {
    setTimeout(() => {
      this.props.setLoading(StateInfo.LoadingInfo.format(false, ''));
      this.props.setNotification(
        StateInfo.NotificationInfo.format('danger', null, 'Error updating user info')
      );
    }, 800);
  };
}
// listens to state change
const mapStateToProps = state => {
  const authInfo = state.authInfo;
  const loadingInfo = state.loadingInfo;
  const notificationInfo = state.notificationInfo;
  return {
    authInfo,
    loadingInfo,
    notificationInfo,
  };
};
export default connect(
  mapStateToProps,
  {
    setAuth,
    setAuthUser,
    setLoading,
    setNotification,
  }
)(Account);

