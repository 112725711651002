import React, {Component} from 'react';
import '../assets/css/row_.css';
import logger from '../utils/logger';

const LOG_TAG = 'RowTimeTable';
const FORMAT_DURATION = (start, end) => {
  return {
    start: start,
    end: end,
  }
};
class RowTimeTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timetable: [],
    };
  }
  componentDidMount() {
    logger.d(LOG_TAG, 'componentDidMount');
    this.resetState();
  };
  componentDidUpdate(oldProps) {
    const newProps = this.props;
    if (oldProps.timetable !== newProps.timetable) {
      logger.d(LOG_TAG, 'componentDidUpdate', newProps.timetable);
      this.resetState();
    }
  };
  resetState() {
    const timetable = this.props.timetable;
    logger.d(LOG_TAG, 'resetState', timetable);
    if (timetable && timetable.length > 0) {
      const timeTable = [];
      const mon = [],
        tue = [],
        wed = [],
        thu = [],
        fri = [],
        sat = [],
        sun = [];
      timetable.forEach((timeSlot, index) => {
        switch (timeSlot.day) {
          case 'Monday':
            if (timeSlot.all_day) {
              mon.push('All day');
            } else {
              timeSlot.windows.forEach((info) => {
                mon.push(FORMAT_DURATION(info.start, info.end));
              });
            }
            break;
          case 'Tuesday':
            if (timeSlot.all_day) {
              tue.push('All day');
            } else {
              timeSlot.windows.forEach((info) => {
                tue.push(FORMAT_DURATION(info.start, info.end));
              });
            }
            break;
          case 'Wednesday':
            if (timeSlot.all_day) {
              wed.push('All day');
            } else {
              timeSlot.windows.forEach((info) => {
                wed.push(FORMAT_DURATION(info.start, info.end));
              });
            }
            break;
          case 'Thursday':
            if (timeSlot.all_day) {
              thu.push('All day');
            } else {
              timeSlot.windows.forEach((info) => {
                thu.push(FORMAT_DURATION(info.start, info.end));
              });
            }
            break;
          case 'Friday':
            if (timeSlot.all_day) {
              fri.push('All day');
            } else {
              timeSlot.windows.forEach((info) => {
                fri.push(FORMAT_DURATION(info.start, info.end));
              });
            }
            break;
          case 'Saturday':
            if (timeSlot.all_day) {
              sat.push('All day');
            } else {
              timeSlot.windows.forEach((info) => {
                sat.push(FORMAT_DURATION(info.start, info.end));
              });
            }
            break;
          case 'Sunday':
            if (timeSlot.all_day) {
              sun.push('All day');
            } else {
              timeSlot.windows.forEach((info) => {
                sun.push(FORMAT_DURATION(info.start, info.end));
              });
            }
            break;
          default:
        }
      });
      if (mon.length > 0) {
        timeTable.push({
          name: 'Mon',
          slots: mon,
        });
      }
      if (tue.length > 0) {
        timeTable.push({
          name: 'Tue',
          slots: tue,
        });
      }
      if (wed.length > 0) {
        timeTable.push({
          name: 'Wed',
          slots: wed,
        });
      }
      if (thu.length > 0) {
        timeTable.push({
          name: 'Thu',
          slots: thu,
        });
      }
      if (fri.length > 0) {
        timeTable.push({
          name: 'Fri',
          slots: fri,
        });
      }
      if (sat.length > 0) {
        timeTable.push({
          name: 'Sat',
          slots: sat,
        });
      }
      if (sun.length > 0) {
        timeTable.push({
          name: 'Sun',
          slots: sun,
        });
      }
      this.setState({
        timetable: timeTable,
      });
    } else {
      this.setState({
        timetable: [],
      });
    }
  }
  render() {
    return (
      <div className="list-group-item-action flex-column align-items-start p-0">
        <div className="d-flex">
          {/* index column */}
          <div className="list-item-col-index-ext">
          </div>
          {/* content column */}
          <div className="list-item-col-content-ext pt-1">
            {this.state.timetable.map((timeSlot, index) => (
              <div
                key={index}
                className="list-item-7-col__index flex-column list-item-col-content__default">
                <div style={{ fontWeight: 'bold' }}>{timeSlot.name}</div>
                {timeSlot.slots.map((slot, index) => (
                  <div key={index}>
                    { slot.start && slot.end ?
                        slot.start + ' ~ ' + slot.end
                        :
                        slot
                    }
                    </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default RowTimeTable;
