import React, {Component} from 'react';
import logger from '../utils/logger';

const LOG_TAG = 'cardSearchUserByEmail';
class CardSearchUserByEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchEmail: '',
      searchError: '',
      hasValidSearch: false,
    };
  }
  componentDidMount() {
    const userInfo = this.props.userInfo;
    logger.d(LOG_TAG, 'componentDidMount', userInfo);
    this.resetState(userInfo);
  }
  componentDidUpdate(oldProps) {
    const newProps = this.props;
    if (oldProps.userInfo !== newProps.userInfo) {
      logger.d(LOG_TAG, 'componentDidUpdate', 'update');
      this.resetState(newProps.userInfo);
    }
  }
  resetState(userInfo) {
    logger.d(LOG_TAG, 'resetState', userInfo);
    if (userInfo) {
      this.setState({
        searchEmail: userInfo.email,
        hasValidSearch: true,
      })
    } else {
      this.setState({
        searchEmail: '',
        hasValidSearch: false,
      })
    }
  }
  render() {
    return (
      <div className="card">
        <div className="card-body">
          <div className="row">
            <form onSubmit={this.onClickSearch}
                  className="col-md-12">
              <div className="form-group">
                <label htmlFor="">Search by email</label>
                <div className="d-flex">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter email address"
                    value={this.state.searchEmail}
                    onChange={this.onChangeSearchEmail}
                    disabled={this.state.hasValidSearch ? true : false}/>
                  <button
                    className="btn ml-2">
                    { this.state.hasValidSearch ? 'Reset' : 'Search'}
                  </button>
                </div>
                {
                  this.state.searchError ?
                    <small className="form-text clr-warning">{this.state.searchError}
                    </small>
                    : null
                }
              </div>
            </form>
          </div>
        </div>
      </div>

    );
  }
  onChangeSearchEmail = value => {
    this.setState({
      searchEmail: value.target.value,
      searchError: '',
    });
  };
  onClickSearch = e => {
    e.preventDefault();
    if (this.state.hasValidSearch) {
      // reset
      this.setState({
        searchEmail: '',
        searchError: '',
        hasValidSearch: false,
      });
      this.props.onResult(null);
    } else {
      const email = this.state.searchEmail;
      logger.d(LOG_TAG, 'onClickSearch', email);

      if (email) {
        window.webapi.Admin.searchEmail(
          email,
          (response) => {
            const data = response.data;
            logger.d(LOG_TAG, 'onClickSearch', 'onResponse', data);

            this.setState({
              hasValidSearch: true,
            });
            this.props.onResult(data);
          },
          (error) => {
            logger.d(LOG_TAG, 'onClickSearch', 'onError', error);
            const data = error.data;
            this.setState({
              searchError: data.err_message,
            });
          }
        )
      } else {
        this.setState({
          searchError: 'Invalid email address'
        });
      }
    }
  };
}

export default CardSearchUserByEmail;

