import React, {Component} from 'react';
import {connect} from 'react-redux';
import {setLoading, setNotification} from '../store/actions';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import logger from '../utils/logger';
import localStorageHelper from '../utils/localStorageHelper';
import StateInfo from '../store/states';
import dataHelper from "../utils/dataHelper";

const LOG_TAG = 'DlgAddUser';
class DlgAddUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authUserInfo: null,
      firstName: '',
      firstNameChanged: false,
      lastName: '',
      lastNameChanged: false,
      email: '',
      emailChanged: false,
      phone: '',
      phoneChanged: false,
      groupOptionSelected: 'select',
      groupOptions: [],
      groupNewName: '',
      password: '',
      role: 'user', // user/manager

      userInfo: null,
    };
  }
  componentDidMount() {
    logger.d(LOG_TAG, 'componentDidMount');
    this.refreshState();
  }
  componentDidUpdate(oldProps) {
    const newProps = this.props;
    if (oldProps.userInfo !== newProps.userInfo) {
      logger.d(LOG_TAG, 'componentDidUpdate', newProps.userInfo);
      this.refreshState();
    }
  }
  refreshState() {

    const userInfo = this.props.userInfo;
    const authUserInfo = localStorageHelper.getAuthUserInfo();
    const authUserGroups = localStorageHelper.getAuthUserGroups();
    const groups = [];
    authUserGroups.forEach((info) => {
      if (info) {
        if (userInfo) {
          if (userInfo.groups && userInfo.groups.length > 0) {
            const matchGroup = userInfo.groups.find((groupId) => {
              return groupId === info.group_id;
            });
            if (matchGroup) {
              groups.push({
                text: info.name,
                value: info.group_id,
                object: info,
              });
            }
          }

        } else {
          groups.push({
            text: info.name,
            value: info.group_id,
            object: info,
          });
        }
      }
    });
    if (!userInfo) {
      groups.push({
        text: 'Create new',
        value: 'create',
        object: {},
      });
    }
    logger.d(LOG_TAG, 'refreshState', userInfo);
    const newState = {
      authUserInfo: authUserInfo,
      firstName: userInfo ? userInfo.first_name : '',
      firstNameChanged: false,
      lastName: userInfo ? userInfo.last_name : '',
      lastNameChanged: false,
      email: userInfo ? userInfo.email : '',
      emailChanged: false,
      phone: userInfo ? userInfo.phone : '',
      phoneChanged: false,
      groupOptionSelected: 'select',
      groupOptions: groups,
      groupNewName: '',
      password: '',
      role: 'user', // user/manager
      userInfo: userInfo,
    };
    this.setState(newState);
  }
  render() {
    return (
      <Modal
        show={this.props.show}
        backdrop={'static'}
        onHide={this.onClickHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{this.state.userInfo ? 'Update User' : 'Add User'} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="">First Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter first name"
                  value={this.state.firstName}
                  onChange={this.onChangeFirstName}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="">Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter last name"
                  value={this.state.lastName}
                  onChange={this.onChangeLastName}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="">Email</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter email"
                  value={this.state.email}
                  onChange={this.onChangeEmail}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="">Mobile Number</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter mobile number"
                  value={this.state.phone}
                  onChange={this.onChangePhone}
                />
              </div>
            </div>
          </div>
          {
            this.state.userInfo ?
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="">Organization</label>
                    <select
                      className="form-control">
                      <option value="select">Existing organizations</option>
                      {
                        this.state.groupOptions.map((option, index) => (
                          <option
                            key={index}
                            value={index}>{option.text}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>
              </div>
              :
              <div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="">Organization</label>
                      <select
                        className="form-control"
                        value={this.state.groupOptionSelected}
                        onChange={this.onSelectOrganization}>
                        <option value="select">Select organization</option>
                        {
                          this.state.groupOptions.map((option, index) => (
                            <option
                              key={index}
                              value={index}>{option.text}</option>
                          ))
                        }
                      </select>
                      {
                        this.state.groupOptionSelected !== 'select' && this.state.groupOptions[this.state.groupOptionSelected].value === 'create' ?
                          <input
                            type="text"
                            className="form-control mt-2"
                            placeholder="Enter organization name"
                            value={this.state.groupNewName}
                            onChange={this.onChangeOrganization}
                          />
                          : null
                      }
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="">Password</label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Enter password"
                        value={this.state.password}
                        onChange={this.onChangePassword}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="">Role</label>
                      <div>
                        <label htmlFor="role-reader">
                          <input id="role-reader" type="radio"
                                 value="user"
                                 checked={this.state.role === 'user'}
                                 onChange={this.onChangeRole}/>
                          &nbsp;User
                        </label>
                        <label className="ml-2" htmlFor="role-editor">
                          <input id="role-editor" type="radio"
                                 value="manager"
                                 checked={this.state.role === 'manager'}
                                 onChange={this.onChangeRole}/>
                          &nbsp;Manager
                        </label>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
          }

        </Modal.Body>
        <Modal.Footer>
          {
            <Button
              className={`
                        ${this.validate()?'btn':'btn__disabled'}
                        btn-default`}
              onClick={this.onClickAdd}>
              {this.state.userInfo ? 'Update' : 'Add'}
            </Button>
          }
        </Modal.Footer>
      </Modal>
    );
  }
  onChangeFirstName = e => {
    const value = e.target.value;
    logger.d(LOG_TAG, 'onChangeFirstName', value);
    this.setState({
      firstName: value,
      firstNameChanged: true,
    })
  };
  onChangeLastName = e => {
    const value = e.target.value;
    logger.d(LOG_TAG, 'onChangeLastName', value);
    this.setState({
      lastName: value,
      lastNameChanged: true,
    })
  };
  onChangeEmail = e => {
    const value = e.target.value;
    logger.d(LOG_TAG, 'onChangeEmail', value);
    this.setState({
      email: value,
      emailChanged: true,
    })
  };
  onChangePhone = e => {
    const value = e.target.value;
    logger.d(LOG_TAG, 'onChangePhone', value);
    this.setState({
      phone: value,
      phoneChanged: true,
    })
  };
  onSelectOrganization = e => {
    const selected = e.target.value;
    logger.d(LOG_TAG, 'onSelectOrganization', selected);
    if (selected !== 'select') {
      logger.d(LOG_TAG, 'onSelectOrganization', this.state.groupOptions[selected]);
    }
    this.setState({
      groupOptionSelected: selected,
      groupNewName: '',
    });
  };
  onChangeOrganization = e => {
    const value = e.target.value;
    logger.d(LOG_TAG, 'onChangeOrganization', value);
    this.setState({
      groupNewName: value,
    })
  };
  onChangePassword = e => {
    const value = e.target.value;
    logger.d(LOG_TAG, 'onChangePassword', value);
    this.setState({
      password: value,
    })
  };
  onChangeRole = e => {
    const value = e.target.value;
    logger.d(LOG_TAG, 'onChangeRole', value);
    this.setState({
      role: value,
    })
  };
  validate() {
    let validated = false;
    if (this.state.userInfo) {
      if (
        (this.state.firstName && this.state.firstNameChanged) ||
        (this.state.lastName && this.state.lastNameChanged) ||
        (this.state.email && this.state.emailChanged) ||
        (this.state.phone && this.state.phoneChanged)) {
        validated = true;
      }
    } else {
      if (this.state.firstName &&
        this.state.lastName &&
        this.state.email &&
        this.state.phone &&
        this.state.password) {
        if (this.state.groupOptionSelected !== 'select') {
          if (this.state.groupOptions[this.state.groupOptionSelected].value === 'create') {
            if (this.state.groupNewName) {
              validated = true;
            }
          } else {
            validated = true;
          }
        }
      }
    }

    logger.d(LOG_TAG, 'validate', validated);
    return validated;
  };
  onClickAdd = e => {
    const isValidated = this.validate();
    logger.d(LOG_TAG, 'onClickAdd', isValidated, this.state.userInfo);
    if (isValidated) {
      if (this.state.userInfo) {
        const userInfo = {};
        if (this.state.firstNameChanged) {
          userInfo.first_name = this.state.firstName;
        }
        if (this.state.lastNameChanged) {
          userInfo.last_name = this.state.lastName;
        }
        if (this.state.emailChanged) {
          userInfo.email = this.state.email;
        }
        if (this.state.phoneChanged) {
          userInfo.phone = this.state.phone;
        }
        this.props.setLoading(StateInfo.LoadingInfo.format(true, 'Update user...'));
        window.webapi.Admin.putUser(
          this.state.userInfo.user_id,
          userInfo,
          (response) => {
            setTimeout(() => {
              this.props.setLoading(StateInfo.LoadingInfo.format(false, ''));
              this.refreshState();
              this.props.onHide(true);
            }, 1200);
          },
          (error) => {
            this.onUserError(error.data);
          }
        );
      } else {
        // create user first
        // then create group with selected role
        const userInfo = {
          first_name: this.state.firstName,
          last_name: this.state.lastName,
          email: this.state.email,
          phone: this.state.phone,
          password: this.state.password,
        };
        this.props.setLoading(StateInfo.LoadingInfo.format(true, 'Adding new user...'));
        window.webapi.Admin.postUser(
          userInfo,
          (response) => {
            this.onUserResponse(response.data);
          },
          (error) => {
            this.onUserError(error.data);
          }
        );
      }
    }
  };
  onUserResponse(value) {
    const userId = value.user_id;
    const selectedGroup = this.state.groupOptions[this.state.groupOptionSelected];
    logger.d(LOG_TAG, 'onUserResponse', userId, selectedGroup);
    // create/update group
    if (selectedGroup.value === 'create') {
      // add auth user as manager by default
      const groupInfo = {
        name: this.state.groupNewName,
      };
      // add new user to selected role
      if (this.state.role === 'user') {
        groupInfo.users = [userId];
      } else if (this.state.role === 'manager') {
        groupInfo.managers = [userId];
      }
      logger.d(LOG_TAG, 'onUserResponse', 'postGroup');
      window.webapi.Admin.postGroup(
        groupInfo,
        (response) => {
          this.onGroupSuccess(response.data);
        },
        (error) => {
          this.onGroupError(error.data);
        }
      )
    } else {
      // add new user to selected role
      if (this.state.role === 'user') {
        selectedGroup.object.users.push(userId);
      } else if (this.state.role === 'manager') {
        selectedGroup.object.managers.push(userId);
      }
      logger.d(LOG_TAG, 'onUserResponse', 'putGroup');
      window.webapi.Admin.putGroup(
        selectedGroup.value,
          {
            name: selectedGroup.object.name,
            managers: selectedGroup.object.managers,
            users: selectedGroup.object.users,
          },
        (response) => {
            this.onGroupSuccess(response.data);
          },
        (error) => {
            this.onGroupError(error.data);
          }
      )
    }
  };
  onUserError(value) {
    logger.d(LOG_TAG, 'onUserError', value);

    const notify = dataHelper.formatError('User', value);
    this.props.setLoading(StateInfo.LoadingInfo.format(false, ''));
    this.props.setNotification(
        StateInfo.NotificationInfo.format('danger', notify.title, notify.message)
    );
  };
  onGroupSuccess(value) {
    logger.d(LOG_TAG, 'onGroupSuccess', value);
    setTimeout(() => {
      this.props.setLoading(StateInfo.LoadingInfo.format(false, ''));
      this.refreshState();
      this.props.onHide(true);
    }, 1200);

  };
  onGroupError(value) {
    logger.d(LOG_TAG, 'onGroupError', value);
    const notify = dataHelper.formatError('Group', value);
    this.props.setLoading(StateInfo.LoadingInfo.format(false, ''));
    this.props.setNotification(
        StateInfo.NotificationInfo.format('danger', notify.title, notify.message)
    );
  };
  onClickHide = e => {
    logger.d(LOG_TAG, 'onClickHide');
    if (this.props.onHide) {
      this.refreshState();
      this.props.onHide();
    }
  };
}
export default connect(
    null,
    {
      setLoading,
      setNotification,
    }
)(DlgAddUser);

