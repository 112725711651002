import TabInfo from './tabInfo';
import LocationInfo from './locationInfo';
import AuthInfo from './authInfo';
import AuthUserInfo from './authUserInfo';
import AuthUserGroups from './authUserGroups';
import UserInfo from './userInfo';
import ParkSiteInfo from './parkSiteInfo';
import ParkingHistoryInfo from './parkingHistoryInfo';
import ParkSlotInfo from './parkSlotInfo';
import ReservationInfo from './reservationInfo';
import ParkingInfo from './parkingInfo';
import AvailableTimeInfo from './availableTimeInfo';
import SensorInfo from './sensorInfo';
import LoadingInfo from './loadingInfo';
import NotificationInfo from './notificationInfo';
import OnNotifyStartParking from './onNotifyStartParking';
import LastSessionInfo from './lastSessionInfo';
import SlotInfo from './slotInfo';
import WindowInfo from './windowInfo';

export default {
  TabInfo,
  LocationInfo,
  AuthInfo,
  AuthUserInfo,
  AuthUserGroups,
  UserInfo,
  ParkSiteInfo,
  ParkSlotInfo,
  ReservationInfo,
  ParkingInfo,
  ParkingHistoryInfo,
  AvailableTimeInfo,
  SensorInfo,
  LoadingInfo,
  NotificationInfo,
  OnNotifyStartParking,
  LastSessionInfo,
  SlotInfo,
  WindowInfo,
};
