import localStorageHelper from '../../utils/localStorageHelper';
import Api from '../api/index';
import ApiMock from '../api-mock';
import Auth from './auth';
import Admin from './admin';
import User from './user';
import Group from './group';
import Query from './query';

export default {
  WebApi() {
    const isMock = localStorageHelper.getIsMock();
    if (isMock) {
      return ApiMock;
    }
    return Api;
  },
  WebApiMock() {
    return ApiMock;
  },
  Auth,
  Admin,
  User,
  Group,
  Query,
};
