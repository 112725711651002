const LOG_TAG = 'logger';
export default {
  LOG_TAG: 'logger',
  d(...params) {
    if (process.env.NODE_ENV !== 'production') {
      console.log(LOG_TAG, params);
    }
  },
  isEmpty(str) {
    return !(str && str.trim().length);
  },
};
